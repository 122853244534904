import {
  InfiniteData,
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query"
import { api } from "api"
import { queryClient } from "app/queryClient"
import { assoc } from "ramda"
import { Notification, NotificationListResponse } from "./notificationTypes"

export const NOTIFICATION_QK: QueryKey = ["notification"]

export function useFetchNotifications() {
  const { data, ...rest } = useInfiniteQuery(
    NOTIFICATION_QK,
    ({ pageParam }) => api.notification.list(pageParam, 20),
    {
      getNextPageParam: last => (last.has_next_page ? last.oldest_id : undefined),
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(p => p.notifications) : [] }
}

export function addNotification(notification: Notification) {
  queryClient.setQueryData<InfiniteData<NotificationListResponse>>(NOTIFICATION_QK, data => {
    if (!data) {
      return undefined
    }

    const firstPage = data.pages[0]
    const newFirstPage: NotificationListResponse = firstPage
      ? {
          notifications: [notification, ...firstPage.notifications],
          has_next_page: firstPage.has_next_page,
          oldest_id: firstPage.oldest_id,
          result_count: firstPage.result_count + 1,
        }
      : {
          notifications: [notification],
          has_next_page: false,
          oldest_id: notification.id,
          result_count: 1,
        }

    return {
      ...data,
      pages: [newFirstPage, ...data.pages.slice(1)],
    }
  })
}

export function useMarkAllAsRead() {
  const queryClient = useQueryClient()

  return useMutation(api.notification.markAsRead, {
    onSuccess() {
      queryClient.setQueryData<InfiniteData<NotificationListResponse>>(NOTIFICATION_QK, data => {
        if (!data) {
          return undefined
        }

        // TODO: when ramda is updated to latest version, use `modifyPath`
        return {
          ...data,
          pages: data.pages.map(page => ({
            ...page,
            notifications: page.notifications.map<Notification>(assoc("read", true)),
          })),
        }
      })
    },
  })
}

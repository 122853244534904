import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./StatusElement.scss"

const StatusElement = ({ status }) => {
  let icon
  switch (status) {
    case "waiting":
      icon = <FontAwesomeIcon data-testid="hourglass" icon={["fas", "hourglass"]} />
      break
    case "warning":
      icon = <FontAwesomeIcon data-testid="exclamation" icon={["fas", "exclamation"]} />
      break
    case "running":
      icon = <FontAwesomeIcon data-testid="play" icon={["fas", "play"]} />
      break
    case "error":
      icon = <FontAwesomeIcon data-testid="minus" icon={["fas", "minus"]} />
      break
    case "finished":
      icon = <FontAwesomeIcon data-testid="check" icon={["fas", "check"]} />
      break
    case "canceled":
      icon = <FontAwesomeIcon data-testid="times" icon={["fas", "times"]} />
      break
    default:
      icon = <FontAwesomeIcon data-testid="hourglass" icon={["fas", "hourglass"]} />
      break
  }

  return (
    <div className={`status-element ${status}`}>
      <div className="col left-icon">{icon}</div>
      <div className="col">
        <span className="status-text">{status}</span>
      </div>
    </div>
  )
}

StatusElement.propTypes = {
  status: PropTypes.string.isRequired,
}

export default StatusElement

import requestFactory from "api/request"
import { Segment } from "resources/segment/segment/segmentTypes"
import { Email, EmailActivation, EmailActivations } from "../emailTypes"

const activationEndpoints = {
  activate(emailId: Email["id"], data: { segment_ids: Array<Segment["id"]> }): Promise<void> {
    return requestFactory("post", `/emails/${emailId}/manual_activation`, data)
  },
  test({
    customerId,
    emailId,
    emails,
  }: {
    customerId: string
    emailId: Email["id"]
    emails: Array<string>
  }) {
    return requestFactory("post", `/emails/${emailId}/activations`, {
      emails,
      customer_id: customerId,
    })
  },
  list({
    id,
    offset,
    limit = 20,
  }: {
    id: Email["id"]
    limit: EmailActivations["selection_settings"]["limit"]
    offset: EmailActivations["selection_settings"]["offset"]
  }): Promise<EmailActivations> {
    return requestFactory("get", `/emails/${id}/exports`, { limit, offset })
  },
  retrieve(
    emailId: Email["id"],
    activationId: EmailActivation["id"],
  ): Promise<{ email_export: EmailActivation }> {
    return requestFactory("get", `/emails/${emailId}/exports/${activationId}`)
  },
}

export default activationEndpoints

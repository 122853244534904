import React, { ComponentPropsWithoutRef } from "react"
import { Redirect, Route } from "react-router-dom"
import { getRoutePath } from "routes"

type AuthorizedRouteProps = {
  hasAccess: boolean
} & ComponentPropsWithoutRef<typeof Route>

export default function AuthorizedRoute({ hasAccess, ...props }: AuthorizedRouteProps) {
  if (hasAccess) {
    return <Route {...props} />
  }

  const { component, render, ...rest } = props

  return <Route {...rest} render={() => <Redirect to={getRoutePath("not-authorized")} />} />
}

import { Template } from "components/UI/components/TemplateGallery/TemplateGalleryItem/TemplateGalleryItem"
import imgSrc from "./countdown.jpg"

const countdown: Template = {
  id: "countdown",
  name: "Countdown",
  description: "Grab customers' attention with time-limited sales campaigns.",
  tags: ["Personalized content", "Pop-up web banner"],
  imgSrc,
  width: 300,
  highlight: {
    startRow: 114,
    endRow: 115,
  },
  docLink:
    "https://docs.meiro.io/books/meiro-business-explorer/page/web-banners-use-cases-library-html-for-countdown-black-friday",
  detailPage: (
    <>
      <h2>Usage</h2>
      <p>
        You can show the customer a product within time-limited sales campaigns. For example, web
        banners will deliver the customers discounted goods during a specific time and create a
        sense of urgency by displaying a countdown until the promotion ends.
      </p>
      <h2>How to set it up</h2>
      <p>
        You need to limit the appearance of the web banner after the sales campaign is finished. In
        this case, add a time condition to prevent showing web banners to customers after the chosen
        date. Additionally, set variables in the JavaScript code with a deadline for the countdown
        and an URL where the user will be redirected after clicking on the web banner.
      </p>
    </>
  ),
  html: `<!DOCTYPE html>
  <html lang="en">
    <head>
      <meta charset="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <style>
        body {
          margin: 0;
          font-family: sans-serif;
        }

        .banner {
          display: flex;
          flex-direction: column;
          align-items: center;
          box-sizing: border-box;
          width: 100%;
          cursor: pointer;
          border-radius: 8px;
          padding: 15px;
          border: 1px solid #444444;
          background-color: white;
        }

        .main-img {
          width: 200px;
        }

        .text {
          margin: 15px 0;
          font-size: 18px;
          font-weight: 600;
        }

        .em {
          color: #cc1624;
        }

        .counters {
          display: flex;
        }

        .counter {
          margin: 0 5px;
        }

        .counter-window {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 50px;
          width: 50px;
          border-radius: 4px;
          background-color: #222222;
          color: white;
          font-size: 24px;
          font-weight: 700;
        }

        .counter-label {
          text-align: center;
          font-size: 12px;
          color: #222222;
        }

        .cta-button {
          height: 40px;
          width: 100%;
          border-radius: 4px;
          cursor: pointer;
          border: none;
          margin-top: 10px;
          background-color: #cc1624;
          color: white;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: 0.1em;
          transition: all 0.15s ease;
        }

        .cta-button:hover {
          background-color: #af1424;
        }
      </style>
    </head>
    <body>
      <div class="banner" onclick="goToUrl()">
        <img class="main-img" src="https://www.meiro.io/pdf/web-layers/black-friday.png" />
        <div class="text">
          Enjoy discounts up to
          <span class="em">80 %</span>!
        </div>
        <div class="counters">
          <div class="counter">
            <div class="counter-window" id="days"></div>
            <div class="counter-label">days</div>
          </div>
          <div class="counter">
            <div class="counter-window" id="hours"></div>
            <div class="counter-label">hours</div>
          </div>
          <div class="counter">
            <div class="counter-window" id="minutes"></div>
            <div class="counter-label">mins</div>
          </div>
          <div class="counter">
            <div class="counter-window" id="seconds"></div>
            <div class="counter-label">secs</div>
          </div>
        </div>
        <button class="cta-button">See offers</button>
      </div>
      <script>
        var URL = "https://www.example.com"
        var targetDate = Date.parse("oct 31, 2021 23:59:59 UTC+00:00")

        function goToUrl() {
          if (window.parent && window.parent.MeiroEvents) {
            window.parent.MeiroEvents.goToWebBannerUrl(URL)
          }
        }

        function padNumber(n) {
          return n < 10 ? "0" + n : n.toString()
        }

        function tick() {
          var timeLeft = targetDate - Date.now()

          var days = Math.floor(timeLeft / (1000 * 60 * 60 * 24))
          var hours = Math.floor(timeLeft / (1000 * 60 * 60))
          var mins = Math.floor(timeLeft / (1000 * 60))
          var secs = Math.floor(timeLeft / 1000)

          secs -= mins * 60
          mins -= hours * 60
          hours -= days * 24
          days = Math.max(days, 0)

          document.getElementById("days").textContent = days
          document.getElementById("hours").textContent = padNumber(hours)
          document.getElementById("minutes").textContent = padNumber(mins)
          document.getElementById("seconds").textContent = padNumber(secs)
        }

        setInterval(tick, 1000)
      </script>
    </body>
  </html>
  `,
}

export default countdown

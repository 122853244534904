import React, { ReactNode } from "react"

import Modal from "../../elements/Modal"
import Button from "../../elements/Button/Button"
import { MODAL } from "sharedConstants"

import styles from "./ConfirmModal.module.scss"

type ConfirmModalProps = {
  open: boolean
  type: "success" | "delete" | "cancel"
  handleClose: () => void
  handleConfirm: () => void
  title: string
  text?: ReactNode
  action?: string
  what?: string
  item?: string
  isLoading?: boolean
  customButtonText?: string
  className?: string
  additional?: ReactNode
}
const ConfirmModal = ({
  open,
  type,
  handleClose,
  handleConfirm,
  title,
  text,
  action,
  what,
  item,
  additional,
  isLoading = false,
  customButtonText = "",
  className = "",
}: ConfirmModalProps) => (
  <Modal open={open} handleClose={handleClose} title={title} className={className}>
    <div className={styles.confirmModal}>
      {additional}
      {text && <p>{text}</p>}
      {item && !text && (
        <p>
          {`Do you really want to ${action} ${what}: `}
          <strong>{item}</strong>?
        </p>
      )}
      <div className={styles.actionButtons}>
        <Button
          color="grey"
          size="md"
          variant="text"
          onClick={handleClose}
          data-testid="confirm-modal-cancel-button"
        >
          Cancel
        </Button>
        {type === MODAL.TYPE.DELETE && (
          <Button
            color="red"
            loading={isLoading}
            size="md"
            onClick={open === true ? handleConfirm : undefined}
            data-testid="confirm-modal-delete-button"
          >
            {customButtonText !== "" ? customButtonText : "Delete"}
          </Button>
        )}
        {(type === "success" || type === "cancel") && (
          <Button
            color={type === "success" ? "primary" : "red"}
            loading={isLoading}
            size="md"
            onClick={open === true ? handleConfirm : undefined}
            data-testid="confirm-modal-confirm-button"
          >
            {type === "success" || type === "cancel" ? "confirm" : type}
          </Button>
        )}
      </div>
    </div>
  </Modal>
)

export default ConfirmModal

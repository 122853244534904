import React, { useState, useRef, useEffect } from "react"
import classNames from "classnames"
import { CSSTransition } from "react-transition-group"

import styles from "./DelayedTooltip.module.scss"

type Props = {
  content: string
  children?: React.ReactNode
  className?: string
  tooltipClassName?: string
  disabled?: boolean
}

export default ({ children, className, tooltipClassName, content, disabled }: Props) => {
  const [active, setActive] = useState(false)
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const showTip = () => {
    if (isMounted.current) {
      setActive(true)
    }
  }

  const hideTip = () => {
    if (isMounted.current) {
      setActive(false)
    }
  }

  if (!content) return <>{children}</>

  return (
    <div
      className={classNames(styles.tooltipWrapper, className, { [styles.disabled]: disabled })}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}
    >
      {children}
      <CSSTransition
        in={active}
        timeout={250}
        classNames={{
          enter: styles.delayedFadeEnter,
          enterActive: styles.delayedFadeEnterActive,
          exitActive: styles.delayedFadeExitActive,
          exitDone: styles.delayedFadeExitDone,
        }}
        unmountOnExit
      >
        <div className={classNames(styles.tooltipTip, tooltipClassName)}>{content}</div>
      </CSSTransition>
    </div>
  )
}

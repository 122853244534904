import requestFactory from "api/request"
import { EmailTemplate, EmailTemplatePayload } from "./emailTemplateTypes"

const template = {
  list(): Promise<{ email_templates: EmailTemplate[] }> {
    return requestFactory("get", "/email_templates")
  },
  create(data: EmailTemplatePayload): Promise<{ email_template: EmailTemplate }> {
    return requestFactory("post", "/email_templates", data)
  },
  modify(
    id: EmailTemplate["id"],
    data: EmailTemplatePayload,
  ): Promise<{ email_template: EmailTemplate }> {
    return requestFactory("patch", `/email_templates/${id}`, data)
  },
  delete(id: EmailTemplate["id"]): Promise<void> {
    return requestFactory("delete", `/email_templates/${id}`)
  },
}

export default template

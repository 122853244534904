import React from "react"
import classNames from "classnames"
import styles from "./HelpDropdown.module.scss"
import useClickOutHandler from "hooks/useClickOutHandler"
import { links } from "./links"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getRoutePath } from "routes"
import Tippy from "@tippyjs/react"

function getSection() {
  const { pathname } = window.location

  if (pathname.startsWith(getRoutePath("profiles"))) {
    return { name: "Profiles", links: links.profiles }
  }
  if (pathname.startsWith(getRoutePath("segments"))) {
    return { name: "Segments", links: links.segments }
  }
  if (pathname.startsWith(getRoutePath("data"))) {
    return { name: "Data library", links: links.data }
  }
  if (pathname.startsWith(getRoutePath("analytics"))) {
    return { name: "Analytics", links: links.analytics }
  }
  if (pathname.startsWith(getRoutePath("channels"))) {
    return { name: "Channels", links: links.channels }
  }
  if (pathname.startsWith(getRoutePath("administration"))) {
    return { name: "Administration", links: links.administration }
  }
  if (pathname.includes("trash")) {
    return { name: "Trash", links: links.trash }
  }
  if (pathname.startsWith(getRoutePath("user-settings"))) {
    return { name: "User settings", links: links.userSettings }
  }
  if (pathname === getRoutePath("home")) {
    return { name: "Home page", links: links.homepage }
  }
  return null
}

export default function HelpDropdown({ isAltPosition }: { isAltPosition?: boolean }) {
  const {
    isOpen: isDropdownOpen,
    toggle: toggleDropdown,
    close: closeDropdown,
    ref: dropdownRef,
    buttonRef,
  } = useClickOutHandler()

  const section = getSection()

  return (
    section && (
      <div className={classNames(styles.container, { [styles.altPosition]: isAltPosition })}>
        <Tippy content="Knowledge base" placement={isAltPosition ? "right" : "bottom"}>
          <button
            className={classNames(styles.button, { [styles.isOpen]: isDropdownOpen })}
            onClick={toggleDropdown}
            ref={buttonRef}
          >
            <FontAwesomeIcon
              className={styles.icon}
              icon={[isDropdownOpen ? "fas" : "far", "info-circle"]}
            />
          </button>
        </Tippy>
        {isDropdownOpen && (
          <div className={styles.dropdown} ref={dropdownRef}>
            <div className={styles.topSection}>
              <div className={styles.heading}>
                Useful documentation links for <strong>{section.name}</strong>:
              </div>
              {section.links.map(({ title, url }) => (
                <a
                  onClick={closeDropdown}
                  key={url}
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.docLink}
                >
                  {title}
                  <FontAwesomeIcon icon={["far", "external-link"]} className={styles.openIcon} />
                </a>
              ))}
            </div>
            <div className={styles.bottomSection}>
              <a
                onClick={closeDropdown}
                href={"https://docs.meiro.io/books/meiro-business-explorer"}
                target="_blank"
                rel="noreferrer"
                className={styles.docLink}
              >
                <FontAwesomeIcon icon={["fas", "life-ring"]} className={styles.icon} />
                User documentation
                <FontAwesomeIcon icon={["far", "external-link"]} className={styles.openIcon} />
              </a>
              <a
                onClick={closeDropdown}
                href={"https://docs.meiro.io/books/releases"}
                target="_blank"
                rel="noreferrer"
                className={styles.docLink}
              >
                <FontAwesomeIcon icon={["fas", "sparkles"]} className={styles.icon} />
                Release notes
                <FontAwesomeIcon icon={["far", "external-link"]} className={styles.openIcon} />
              </a>
              <a
                onClick={closeDropdown}
                href={"https://meiro.io/general-terms/"}
                target="_blank"
                rel="noreferrer"
                className={styles.docLink}
              >
                <FontAwesomeIcon icon={["fas", "file-contract"]} className={styles.icon} />
                Terms & Conditions
                <FontAwesomeIcon icon={["far", "external-link"]} className={styles.openIcon} />
              </a>
            </div>
          </div>
        )}
      </div>
    )
  )
}

import { uniq } from "ramda"
import { AttributesMapById } from "resources/attribute/attributeTypes"
import {
  getCompoundAttributeSubAttribute,
  isAttributeCompound,
} from "resources/attribute/compoundAttributeUtils"

export const getAttributeNamesUsedInString = (
  text: string,
  attributesMapById: AttributesMapById,
): string[] => {
  const matches = text.match(/\{\{([^}]+)\}\}/gm)?.map(match => match.replaceAll(/\{*\}*/g, ""))
  const usedAttributeNames: string[] = []
  if (Array.isArray(matches)) {
    matches.forEach(attributeId => {
      if (attributeId.includes(".")) {
        const [compoundAttributeId, compoundAttributeDimension, ...rest] = attributeId.split(".")
        if (
          rest.length === 0 &&
          attributesMapById[compoundAttributeId] &&
          isAttributeCompound(attributesMapById[compoundAttributeId].data_type)
        ) {
          const dimension = getCompoundAttributeSubAttribute(
            compoundAttributeDimension,
            attributesMapById[compoundAttributeId].data_type,
          )
          if (dimension) {
            usedAttributeNames.push(
              `${attributesMapById[compoundAttributeId].name}: ${dimension.name}`,
            )
          }
        }
      } else if (
        attributesMapById[attributeId] &&
        !isAttributeCompound(attributesMapById[attributeId].data_type)
      ) {
        usedAttributeNames.push(attributesMapById[attributeId].name)
      }
    })
  }
  return uniq(usedAttributeNames)
}

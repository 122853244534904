import React, { useState } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Modal from "components/UI/elements/Modal"
import Adform from "./icons/Adform.svg"
import Criteo from "./icons/Criteo.svg"
import GoogleAds from "./icons/GoogleAds.svg"
import HuaweiAds from "./icons/HuaweiAds.svg"
import InMobi from "./icons/InMobi.svg"
import LinkedIn from "./icons/LinkedIn.svg"
import Microsoft from "./icons/Microsoft.svg"
import Reddit from "./icons/Reddit.svg"
import Sklik from "./icons/Sklik.svg"
import TikTok from "./icons/TikTok.svg"
import Twitter from "./icons/Twitter.svg"
import Xandr from "./icons/Xandr.svg"

import styles from "./PromotionBar.module.scss"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"
import { useModifyUser } from "resources/user/userQueries"

const icons = [
  { name: "Criteo", src: Criteo },
  { name: "Adform", src: Adform },
  { name: "Twitter", src: Twitter },
  { name: "InMobi", src: InMobi },
  { name: "Huawei Ads", src: HuaweiAds },
  { name: "Sklik", src: Sklik },
  { name: "Google Ads", src: GoogleAds },
  { name: "LinkedIn", src: LinkedIn },
  { name: "Reddit", src: Reddit },
  { name: "TikTok", src: TikTok },
  { name: "Xandr", src: Xandr },
  { name: "Microsoft", src: Microsoft },
]

const PromotionBar = () => {
  const { data: currentUser } = useFetchCurrentUser()
  const { mutate: modifyUser } = useModifyUser()
  const { frontend_settings } = currentUser!

  const [modalOpen, setModalOpen] = useState(false)
  const [barHidden, setBarHidden] = useState(frontend_settings?.hidePromotionBar)

  const hidePromotionBar = (hide: boolean) => {
    setBarHidden(hide)
    modifyUser({
      id: currentUser!.id,
      data: {
        frontend_settings: {
          ...(frontend_settings ?? {}),
          hidePromotionBar: hide,
        },
      },
    })
  }

  if (barHidden)
    return (
      <div
        className={classNames(styles.promotionBar, styles.hidden)}
        onClick={() => hidePromotionBar(false)}
      >
        <FontAwesomeIcon icon={["fas", "chevron-down"]} className={styles.icon} />
      </div>
    )

  return (
    <div className={styles.promotionBar}>
      <div className={styles.titleWrapper}>
        <span className={styles.title}>Want to activate your audience in another ad network?</span>
        <button className={styles.button} onClick={() => hidePromotionBar(true)}>
          <FontAwesomeIcon icon={["fas", "times"]} />
        </button>
      </div>
      <div className={styles.icons}>
        {icons.map(({ name, src }) => (
          <img
            key={name}
            src={src}
            alt={name}
            onClick={() => setModalOpen(true)}
            className={styles.icon}
          />
        ))}
      </div>
      <Modal
        open={modalOpen}
        title="Looking for another advertising channel?"
        handleClose={() => setModalOpen(false)}
      >
        <span className={styles.text}>
          Additional touchpoint activations are available upon request. Contact directly{" "}
          <a
            href="mailto:support@meiro.io"
            target="_blank"
            rel="noreferrer"
            className={styles.anchor}
          >
            support@meiro.io
          </a>{" "}
          or your CDP admin.
        </span>
      </Modal>
    </div>
  )
}

export default PromotionBar

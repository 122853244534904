import React from "react"
import PropTypes from "prop-types"

import "./Badge.scss"

const Badge = ({ text, className }) => (
  <span className={`badge ${text} ${className ? className : ""}`}>{text}</span>
)

Badge.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default Badge

import React from "react"
import { useHistory } from "react-router"
import { getRoutePath } from "routes"

import { useCreatePopupWB } from "resources/webBanner/popupWebBanner/popupWBQueries"
import { formDataToCreatePayload } from "../utilities/formDataToCreatePayload"
import { PopupWebBannersFormData } from "../popupWBComponentTypes"
import PopupWebBannersForm from "../components/PopupWebBannersForm/PopupWebBannersForm"
import Page from "components/UI/Page/Page"
import ChatGPT from "components/ChatGPT/ChatGPT"

export default function PopupWebBannersCreate() {
  const history = useHistory()

  const { isLoading, mutateAsync } = useCreatePopupWB()

  const createBanner = (formData: PopupWebBannersFormData) =>
    mutateAsync(
      { data: formDataToCreatePayload(formData) },
      {
        onSuccess: ({ web_banner: { id } }) => {
          history.push(getRoutePath("channels.popup-banners.detail", { id }))
        },
      },
    )

  return (
    <Page
      title="Create pop-up banner"
      backRouteFallback={getRoutePath("channels.popup-banners")}
      headerContent={<ChatGPT />}
    >
      <PopupWebBannersForm submitting={isLoading} onSubmit={createBanner} />
    </Page>
  )
}

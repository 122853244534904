import React, { ReactNode } from "react"
import { useSortable } from "@dnd-kit/sortable"
import { DraggableSyntheticListeners } from "@dnd-kit/core"
import classNames from "classnames"
import styles from "./SortableItem.module.scss"

type SortableProps = {
  id: string
  render: (renderProps: { listeners: DraggableSyntheticListeners }) => ReactNode
}

export default function Sortable({ id, render }: SortableProps) {
  const { listeners, setNodeRef, isDragging } = useSortable({ id })

  return (
    <div
      ref={setNodeRef}
      className={classNames(styles.container, {
        [styles.isDragging]: isDragging,
      })}
    >
      {render({ listeners })}
    </div>
  )
}

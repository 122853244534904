import {
  PWBAnimation,
  PWBDisplayOn,
  PWBFrequencyCapType,
  PWBPosition,
  WBMinimizedHideCondition,
} from "resources/webBanner/popupWebBanner/popupWBTypes"
import { SelectOption } from "types/util"

export const FREQUENCY_CAP_TYPE_OPTIONS: SelectOption<PWBFrequencyCapType>[] = [
  { label: "session", value: "session" },
  { label: "hours", value: "hours" },
  { label: "days", value: "days" },
]

export const POSITION_OPTIONS: SelectOption<PWBPosition>[] = [
  { label: "Top left", value: "top_left" },
  { label: "Top middle", value: "top" },
  { label: "Top right", value: "top_right" },
  { label: "Middle left", value: "left" },
  { label: "Middle", value: "middle" },
  { label: "Middle right", value: "right" },
  { label: "Bottom left", value: "bottom_left" },
  { label: "Bottom middle", value: "bottom" },
  { label: "Bottom right", value: "bottom_right" },
]

export const ANIMATION_OPTIONS: SelectOption<PWBAnimation>[] = [
  { label: "None", value: "none" },
  { label: "Fade In", value: "fade_in" },
  { label: "Slide In", value: "slide_in" },
]

export const PRIORITY_OPTIONS: SelectOption<number>[] = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
]

export const HIDE_OPERATOR_OPTIONS: SelectOption<WBMinimizedHideCondition["operator"]>[] = [
  { value: "equals_any", label: "equals any" },
  { value: "not_equal_any", label: "doesn't equal any" },
  { value: "contains_any", label: "contains any" },
  { value: "not_contain_any", label: "doesn't contain any" },
]

export const DISPLAY_CONDITION_OPTIONS: SelectOption<PWBDisplayOn>[] = [
  { value: "page_load", label: "Page load" },
  { value: "scroll", label: "Scroll" },
  { value: "exit", label: "Exit" },
]

import React, { useRef, useState } from "react"
import classNames from "classnames"
import { Link } from "react-router-dom"
import SimpleBar from "simplebar-react"
import Tippy from "@tippyjs/react"
import { ascend, descend, prop, sort } from "ramda"

import { abbreviateNumber } from "helpers/number.helper"
import { getRoutePath } from "routes"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper.js"
import { OrderDir } from "types/util"
import useCalcTextWidth from "hooks/useCalcTextWidth"

import styles from "./SuspiciousCustomerEntities.module.scss"

const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100

type Props = {
  data: Array<{ label: string; value: number }>
  total: number
  expanded?: boolean
  order?: OrderDir
}

const SuspiciousCustomerEntities = ({ data, total, expanded = false, order = "DESC" }: Props) => {
  const [isBottom, setIsBottom] = useState(false)
  const scrollableRef = useRef<HTMLElement>(null)
  const calcTextWidth = useCalcTextWidth()

  const sortedData = sort(
    order.toUpperCase() === "DESC" ? descend(prop("value")) : ascend(prop("value")),
    data,
  )

  const maxValue =
    order.toUpperCase() === "DESC" ? sortedData[0].value : sortedData[sortedData.length - 1].value

  return (
    <>
      <SimpleBar
        scrollableNodeProps={{ ref: scrollableRef }}
        onScrollCapture={() => {
          if (scrollableRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = scrollableRef.current
            if (Math.round(scrollTop + clientHeight) === scrollHeight) setIsBottom(true)
            else setIsBottom(false)
          }
        }}
        className={classNames(styles.chart, {
          [styles.expanded]: expanded,
          [styles.notBottom]: !isBottom,
        })}
      >
        <div className={styles.data}>
          {sortedData.map(({ label, value }) => {
            const barWidth = roundToTwoDecimals((value / maxValue) * 154)
            const preciseValue = getUserFriendlyValueFormat(value, "int")
            const resultValue = abbreviateNumber(value)
            const textWidth = calcTextWidth({ text: resultValue }) + 4

            return (
              <div className={styles.row} key={label}>
                <Link to={getRoutePath("profiles.detail", { id: label })} className={styles.label}>
                  {label}
                </Link>
                <Tippy content={<span>Exact value: {preciseValue}</span>}>
                  <div
                    className={styles.bar}
                    style={{
                      width: ((expanded ? 80 : 50) / 154) * barWidth + "%",
                    }}
                  >
                    <span
                      className={classNames({ [styles.nextTo]: textWidth > barWidth })}
                      style={{ right: `-${textWidth}px` }}
                    >
                      {resultValue}
                    </span>
                  </div>
                </Tippy>
              </div>
            )
          })}
        </div>
      </SimpleBar>
      <hr className={styles.line} />
      <div className={styles.total}>
        <span>Total</span>
        <span>{total}</span>
      </div>
    </>
  )
}

export default SuspiciousCustomerEntities

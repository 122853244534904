import Modal from "components/UI/elements/Modal"
import React from "react"
import styles from "./CopyModal.module.scss"
import Button from "components/UI/elements/Button/Button"
import { SegmentType } from "resources/segment/segment/segmentTypes"
import { useHasAccess } from "resources/user/currentUserQueries"

type CopyModalProps = {
  open: boolean
  handleClose: () => void
  onCopyToFeaturedClick: () => void
  onCopyClick: () => void
  type: SegmentType
  copyingToFeatured: boolean
  copying: boolean
}

export default function CopyModal({
  open,
  handleClose,
  onCopyToFeaturedClick,
  copyingToFeatured,
  onCopyClick,
  copying,
  type,
}: CopyModalProps) {
  const hasAccess = useHasAccess()

  return (
    <Modal open={open} handleClose={handleClose} title="Copy segment" className={styles.modal}>
      <p>Do you really want to copy current segment?</p>
      <div className={styles.actionButtons}>
        <Button color="grey" size="md" variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <div className={styles.right}>
          <Button
            color="grey"
            disabled={!hasAccess.segments.featured.edit || !["regular", "featured"].includes(type)}
            loading={copyingToFeatured}
            size="md"
            variant="outlined"
            onClick={onCopyToFeaturedClick}
            className={styles.secondaryActionButton}
          >
            Copy to featured segments
          </Button>
          <Button
            disabled={!hasAccess.segments.create}
            loading={copying}
            size="md"
            onClick={onCopyClick}
            className={styles.primaryActionButton}
          >
            Copy to custom segments
          </Button>
        </div>
      </div>
    </Modal>
  )
}

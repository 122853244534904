import requestFactory from "api/request"
import { IdentityGraphResponse } from "./identityGraphTypes"

const identityGraph = {
  retrieve(customer_entity_id: string): Promise<IdentityGraphResponse> {
    return requestFactory("get", `/customers/${customer_entity_id}/stitching_graph`, {}, true)
  },
}

export default identityGraph

import { Color, DataType, InsightType } from "./types"
import { OPTION_GROUP_COLORS } from "sharedConstants"

export const getNumerator = (percentage: number, denominator: number) =>
  Math.round(denominator * (percentage / 100))

export const getInsightType = (func: string, dataType: DataType): InsightType => {
  // BE returns EARLIEST as MIN and LATEST as MAX
  if (["date", "datetime"].includes(dataType)) {
    if (func.toUpperCase() === "MIN") return "EARLIEST"
    else if (func.toUpperCase() === "MAX") return "LATEST"
  }

  return func.toUpperCase() as InsightType
}

export const isColor = (c: any): c is Color => OPTION_GROUP_COLORS.includes(c)

import React from "react"
import { TemplateTag } from "../tags"
import styles from "./TemplateGalleryItem.module.scss"

type TemplateGalleryItemProps = {
  template: Template
  onClick: () => void
}

export type HighlightState = {
  startRow: number
  endRow: number
}

export type Template = {
  id: string
  html: string
  width: number
  highlight: HighlightState | null
  imgSrc: string
  name: string
  description: string
  docLink: string
  detailPage?: JSX.Element
  tags?: TemplateTag[]
}

const TemplateGalleryItem = ({ template, onClick }: TemplateGalleryItemProps) => {
  return (
    <div className={styles.galleryItem} onClick={onClick} data-testid="html-templates-gallery-item">
      <div className={styles.previewImageWrapper}>
        {template.imgSrc && <img src={template.imgSrc} alt="" />}
      </div>
      <div className={styles.previewText}>
        <h4 className={styles.name}>{template.name}</h4>
        {template.description && <p className={styles.description}>{template.description}</p>}
      </div>
    </div>
  )
}

export default TemplateGalleryItem

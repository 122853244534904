import React from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import styles from "./EmailTypeBadge.module.scss"

type EmailTypeBadgeProps = {
  contentType: "html" | "json"
}

export default function EmailTypeBadge({ contentType }: EmailTypeBadgeProps) {
  return (
    <div
      className={classNames(styles.contentTypeWrapper, {
        [styles.gap]: contentType === "html",
      })}
    >
      <FontAwesomeIcon
        icon={[
          contentType === "html" ? "fas" : "far",
          contentType === "html" ? "code" : "arrow-pointer",
        ]}
        className={styles.icon}
      />
      <span className={styles.contentType}>{contentType === "html" ? "HTML" : "Visual"}</span>
    </div>
  )
}

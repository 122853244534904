import requestFactory from "api/request"
import { CustomerSegmentListResponse } from "./customerSegmentTypes"

const customerSegment = {
  list({
    id,
    limit,
    offset,
  }: {
    id: string
    limit: CustomerSegmentListResponse["selection_settings"]["limit"]
    offset: CustomerSegmentListResponse["selection_settings"]["offset"]
  }): Promise<CustomerSegmentListResponse> {
    return requestFactory("get", `/customers/${id}/segments`, { limit, offset })
  },
}

export default customerSegment

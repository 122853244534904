import React from "react"
import PropTypes from "prop-types"
import { Line } from "react-chartjs-2"
import { COLOR, DATE_FMT } from "sharedConstants"
import { hexToRgba } from "helpers/chartjs.helper"
import { abbreviateNumber } from "helpers/number.helper"
import { useFetchDataSourcesMap } from "resources/dataSource/dataSourceQueries"
import { isEmpty } from "ramda"
import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  addYears,
  format,
  isAfter,
  isBefore,
  isEqual,
} from "date-fns"
import { toLocalDate } from "utilities/date"

const options = {
  maintainAspectRatio: false,
  legend: false,
  layout: {
    padding: {
      top: 10,
      right: 3,
      bottom: 3,
      left: 3,
    },
  },
  tooltips: {
    enabled: true,
    mode: "index",
    callbacks: {
      label: function (tooltipItems, data) {
        if (Number.isFinite(tooltipItems.yLabel)) {
          return `${
            data?.datasets[tooltipItems.datasetIndex]?.label ?? "Count"
          }: ${abbreviateNumber(tooltipItems.yLabel)}`
        }
        return tooltipItems.yLabel
      },
    },
  },
  scales: {
    xAxes: [
      {
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          maxTicksLimit: 3,
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
        },
        gridLines: {
          display: false,
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          fontSize: 11,
          fontStyle: "bold",
          fontColor: "#777777",
          callback: function (value) {
            return abbreviateNumber(value)
          },
        },
      },
    ],
  },
}

const EventsOverTimeChart = ({ rawData, sourcesMap, dataResolution, className, startDate }) => {
  let chartStart = new Date()
  let chartEnd = toLocalDate(startDate)

  rawData.forEach(sourceData => {
    if (!isEmpty(sourceData.events_per_date)) {
      const firstEvent = sourceData.events_per_date[0]
      const lastEvent = sourceData.events_per_date[sourceData.events_per_date.length - 1]
      if (isBefore(toLocalDate(firstEvent.date), chartStart)) {
        chartStart = toLocalDate(firstEvent.date)
      }
      if (isAfter(toLocalDate(lastEvent.date), chartEnd)) {
        chartEnd = toLocalDate(lastEvent.date)
      }
    }
  })

  const labels = []
  const normalizedData = rawData.map((sourceData, index) => {
    const eventsPerDate = []
    let dateIt = chartStart
    let arrayIterator = 0
    while (isBefore(dateIt, chartEnd) || isEqual(dateIt, chartEnd)) {
      if (index === 0) {
        // fill labels
        switch (dataResolution) {
          case "day":
            labels.push(format(dateIt, DATE_FMT.DATE))
            break
          case "week":
            labels.push(`week from ${format(dateIt, DATE_FMT.DATE)}`)
            break
          case "month":
            labels.push(format(dateIt, DATE_FMT.MONTH))
            break
          case "year":
            labels.push(format(dateIt, DATE_FMT.YEAR))
            break
          default:
            // hour
            labels.push(format(dateIt, DATE_FMT.DATETIME))
        }
      }

      if (!isEmpty(sourceData.events_per_date)) {
        const entry = { ...sourceData.events_per_date[arrayIterator] }
        entry.date = toLocalDate(entry.date)

        if (isEqual(entry.date, dateIt)) {
          eventsPerDate.push(entry)
          arrayIterator =
            arrayIterator < sourceData.events_per_date.length - 1
              ? arrayIterator + 1
              : arrayIterator
        } else {
          eventsPerDate.push({
            date: dateIt,
            count: 0,
          })
        }
      }
      switch (dataResolution) {
        case "day":
          dateIt = addDays(dateIt, 1)
          break
        case "week":
          dateIt = addWeeks(dateIt, 1)
          break
        case "month":
          dateIt = addMonths(dateIt, 1)
          break
        case "year":
          dateIt = addYears(dateIt, 1)
          break
        default:
          // hour
          dateIt = addHours(dateIt, 1)
      }
    }
    return {
      source_id: sourceData.source_id,
      events_per_date: eventsPerDate,
    }
  })

  const data = {
    labels,
    datasets: normalizedData.map(dataset => {
      const source = sourcesMap[dataset.source_id]
      const color = COLOR[source?.frontend_settings?.color ?? "primary"]

      return {
        label: source ? source.name : "Undefined source",
        backgroundColor: hexToRgba(color, 0.5),
        borderColor: color,
        borderWidth: 1,
        fill: true,
        pointRadius: 2,
        pointBackgroundColor: color,
        data: dataset.events_per_date.map(obj => obj.count),
      }
    }),
  }

  return (
    <div className={`customer-events-per-date-chart ${className}`}>
      <Line data={data} options={options} />
    </div>
  )
}

EventsOverTimeChart.propTypes = {
  rawData: PropTypes.array.isRequired,
  dataResolution: PropTypes.string,
  className: PropTypes.string,
  startDate: PropTypes.string.isRequired,
}

export default props => {
  const { data = {} } = useFetchDataSourcesMap()

  return <EventsOverTimeChart {...props} sourcesMap={data} />
}

import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query"
import { SocketContext } from "context/socket"
import { useContext } from "react"
import { OpenAiSuccess, OpenAiError, OpenAiResponse } from "./openaiTypes"

export function useFetchOpenAiResponse(
  query?: string,
  config?: UseQueryOptions<OpenAiResponse, OpenAiError, OpenAiSuccess, QueryKey>,
) {
  const socket = useContext(SocketContext)

  return useQuery(
    ["openaiResponse", query] as QueryKey,
    () => {
      return new Promise<OpenAiResponse>((resolve, reject) => {
        socket.on("chatbot_gpt_answer", (res: OpenAiResponse) => {
          if (res.hasOwnProperty("error")) {
            reject(res)
          }
          resolve(res)
        })

        socket.emit("chatbot_gpt_question", {
          question: query,
        })
      })
    },
    config,
  )
}

import React from "react"
import styles from "./LoadingIndicator.module.scss"
import classNames from "classnames"

type LoadingIndicatorProps = {
  size?: "xs" | "sm" | "md" | "lg"
  fixedWidth?: boolean
  className?: string
  color?: "primary" | "white"
}

export default function LoadingIndicator({
  size = "md",
  fixedWidth = false,
  className,
  color = "primary",
}: LoadingIndicatorProps) {
  return (
    <div
      className={classNames(
        styles.container,
        styles[size],
        styles[color],
        { [styles.fixedWidth]: fixedWidth },
        className,
      )}
    >
      <div className={styles.spinner} />
    </div>
  )
}

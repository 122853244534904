import React from "react"
import Tippy from "@tippyjs/react"
import classNames from "classnames"

import { ChannelType } from "resources/channel/channelTypes"

import styles from "./ActivationButton.module.scss"

type ActivationButtonProps = {
  active: boolean
  channel: ChannelType
  onClick: () => void
  disabled?: boolean
  isLoading?: boolean
}

export default function ActivationButton({
  active,
  channel,
  onClick,
  disabled = false,
  isLoading = false,
}: ActivationButtonProps) {
  const button = (
    <button
      onClick={_ => onClick()}
      className={classNames(styles.button, {
        [styles.active]: active,
        [styles.loading]: isLoading,
      })}
      disabled={disabled}
    >
      Activation
    </button>
  )

  if (disabled)
    return (
      <Tippy
        content={`You don't have the permission to activate ${
          channel === "emails" ? "email" : "mobile push"
        } template.`}
      >
        <div>{button}</div>
      </Tippy>
    )
  else return button
}

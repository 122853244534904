import {
  CompoundSegmentCondition,
  DimensionBranch,
  DimensionConditionObject,
  DimensionTree,
  MultiValueDimensionBranch,
  MultiValueDimensionTree,
  MultiValueSegmentCondition,
  MultiValueSimpleSegmentCondition,
  MultiValueSubattributeBranch,
  SegmentCondition,
  SimpleConditionObject,
  SimpleSegmentCondition,
} from "../segmentConditionsTypes"

export function isSimpleSegmentCondition(
  condition: SegmentCondition | MultiValueSegmentCondition,
): condition is SimpleSegmentCondition {
  return (
    (condition as any).operands === undefined &&
    (condition as any).condition.sub_attribute_id === undefined
  )
}

export function isMultiValueSimpleSegmentCondition(
  condition: SegmentCondition | MultiValueSegmentCondition,
): condition is MultiValueSimpleSegmentCondition {
  return (
    (condition as MultiValueSimpleSegmentCondition).operands?.[0] !== undefined &&
    (condition as any).operands[0].condition &&
    (condition as any).operands[0].condition.sub_attribute_id === undefined
  )
}

export function isMultiValueSubattributeBranch(
  tree: MultiValueDimensionTree,
): tree is MultiValueSubattributeBranch {
  // All operands have the same sub_attribute_id
  return (
    (tree as any).operands !== undefined &&
    (tree as MultiValueSubattributeBranch).operands.every(
      (operand, _, operands) =>
        operand.condition &&
        operand.condition.sub_attribute_id === operands[0].condition.sub_attribute_id,
    )
  )
}

export function isMultiValueDimensionBranch(
  tree: MultiValueDimensionTree,
): tree is MultiValueDimensionBranch {
  return (tree as any).operands !== undefined && !isMultiValueSubattributeBranch(tree)
}

export function isDimensionConditionObject(
  obj: SimpleConditionObject | DimensionConditionObject,
): obj is DimensionConditionObject {
  return (obj as DimensionConditionObject).condition.sub_attribute_id !== undefined
}

export function isCompoundSegmentCondition(
  condition: SegmentCondition,
): condition is CompoundSegmentCondition {
  return (
    // Branch compound condition
    Array.isArray((condition as DimensionBranch).operands) ||
    // Leaf compound condition
    isDimensionConditionObject(condition as any)
  )
}

export function isDimensionBranch(condition: DimensionTree): condition is DimensionBranch {
  return Array.isArray((condition as DimensionBranch).operands)
}

import DragAndDrop from "components/UI/components/DragAndDrop/DragAndDrop"
import { ComponentProps } from "react"
import styles from "./FileUploadDnD.module.scss"
import folderImgSrc from "./folder.png"

type FileUploadDnDProps = {
  onFileDrop: (files: FileList) => void
  label: string
} & ComponentProps<"div">

export default function FileUploadDnD({ onFileDrop, children, label }: FileUploadDnDProps) {
  return (
    <div className={styles.fileUpload}>
      <span className={styles.label}>{label}</span>
      <DragAndDrop onFileDrop={onFileDrop}>
        <div className={styles.droppableContent}>
          <img src={folderImgSrc} alt="" className={styles.folderImg} />
          {children}
        </div>
      </DragAndDrop>
    </div>
  )
}

import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import { Report, ReportCreatePayload, ReportModifyPayload, ReportMovePayload } from "./reportTypes"

const report = {
  listAll(): Promise<Report[]> {
    return fetchAll({
      fetchFn: (offset, limit) => requestFactory("get", "/reports", { offset, limit }),
      key: "reports",
    })
  },
  create(data: ReportCreatePayload): Promise<{ report: Report }> {
    return requestFactory("post", "/reports", data)
  },
  modify(id: Report["id"], data: ReportModifyPayload): Promise<{ report: Report }> {
    return requestFactory("patch", `/reports/${id}`, data)
  },
  delete(id: Report["id"]): Promise<void> {
    return requestFactory("delete", `/reports/${id}`)
  },
  move(id: Report["id"], data: ReportMovePayload): Promise<void> {
    return requestFactory("patch", `/reports/${id}/move`, data)
  },
}

export default report

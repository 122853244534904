import React, { useCallback } from "react"
import EmbeddedWebBannersForm from "../components/EmbeddedWebBannersForm/EmbeddedWebBannersForm"
import { useHistory } from "react-router"
import { getRoutePath } from "routes"
import { useCreateEmbeddedWB } from "resources/webBanner/embeddedWebBanner/embeddedWBQueries"
import { EmbeddedWebBannerCreatePayload } from "resources/webBanner/embeddedWebBanner/embeddedWBTypes"
import { EmbeddedWebBannersFormData } from "../embeddedWBComponentTypes"
import Page from "components/UI/Page/Page"
import ChatGPT from "components/ChatGPT/ChatGPT"

export default function EmbeddedWebBannersCreate() {
  const history = useHistory()

  const { isLoading, mutateAsync } = useCreateEmbeddedWB()

  const createBanner = (formData: EmbeddedWebBannersFormData) =>
    mutateAsync(
      { data: formDataToCreatePayload(formData) },
      {
        onSuccess: ({ web_banner: { id } }) => {
          history.push(getRoutePath("channels.native-banners.detail", { id }))
        },
      },
    )

  const formDataToCreatePayload = useCallback(
    ({
      name,
      element_id,
      condition,
      frequency_cap,
      priority,
      image_local,
      image_name,
      image_remote,
      html,
      destination_url,
      width,
      preview_width,
      disabled,
      tracking_disabled,
    }: EmbeddedWebBannersFormData): EmbeddedWebBannerCreatePayload => ({
      name,
      element_id,
      settings: {
        condition,
        priority,
        frequency_cap,
        tracking_disabled,
        content: image_local
          ? {
              destination_url: destination_url === "" ? null : destination_url,
              data: image_local,
              image_name: image_name!,
              type: "local_image",
            }
          : image_remote
          ? {
              destination_url: destination_url === "" ? null : destination_url,
              image_url: image_remote,
              type: "remote_image",
            }
          : { html: html!, type: "html" },
        fe_settings: {
          width,
          preview_width,
        },
      },
      disabled,
    }),
    [],
  )

  return (
    <Page
      title={"Create native banner"}
      backRouteFallback={getRoutePath("channels.native-banners")}
      headerContent={<ChatGPT />}
    >
      <EmbeddedWebBannersForm submitting={isLoading} onSubmit={createBanner} />
    </Page>
  )
}

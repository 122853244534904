import React, { useEffect, useState } from "react"
import classNames from "classnames"
import { Controller, useFieldArray, useForm } from "react-hook-form"
import { Prompt } from "react-router-dom"

import Button from "components/UI/elements/Button/Button"
import EditableValue from "components/UI/components/EditableValue/EditableValue"
import ExpandableAceEditor from "components/UI/components/ExpandableAceEditor/ExpandableAceEditor"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Paper from "components/UI/elements/Paper"
import SelectField from "components/UI/elements/SelectField"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { maxLength, required } from "helpers/validators.helper"
import { EventFull } from "resources/event/eventTypes"
import { useModifyStitchingCategory } from "resources/stitchingCategory/stitchingCategoryQueries"
import {
  StitchingCategory,
  StitchingCategoryRule,
} from "resources/stitchingCategory/stitchingCategoryTypes"

import styles from "./IdentityStitchingForm.module.scss"
import { RegexExamplesModal } from "./RegexExamplesModal"

export type IdentityStitchingFormValues = {
  rules: Array<StitchingCategoryRule>
}

type IdentityStitchingFormProps = {
  areEventsFulfilled: boolean
  eventOptions: Array<{
    label: string
    value: EventFull["id"]
    hidden: boolean
    source_id: EventFull["source"]["id"]
  }>
  category: StitchingCategory
  onSubmit: (
    data: IdentityStitchingFormValues & {
      regex_filter_pattern?: string
    },
  ) => Promise<void>
  onFormDirty: (isDirty: boolean) => void
} & IdentityStitchingFormValues

export default function IdentityStitchingForm({
  areEventsFulfilled,
  category,
  eventOptions,
  rules,
  onSubmit,
  onFormDirty,
}: IdentityStitchingFormProps) {
  const [openModal, setOpenModal] = useState(false)
  const [regexPattern] = useState(category.regex_filter_pattern)
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isSubmitted, isSubmitting },
    reset,
  } = useForm<IdentityStitchingFormValues>({
    defaultValues: { rules },
  })

  const { fields, append, remove } = useFieldArray({ control, name: "rules" })

  const modifyMutation = useModifyStitchingCategory()
  const modifyStitchingCategoryName = async (name: string) => {
    await modifyMutation.mutateAsync({ id: category.id, data: { name } })
  }

  const submitForm: React.FormEventHandler<HTMLFormElement> = handleSubmit(async data => {
    await onSubmit({ rules: data.rules, regex_filter_pattern: regexPattern ?? undefined })
    reset({ rules: data.rules })
  })

  useEffect(() => {
    onFormDirty(isDirty || (regexPattern || null) !== category.regex_filter_pattern)
  }, [category.regex_filter_pattern, isDirty, onFormDirty, regexPattern])

  return (
    <>
      <Prompt
        when={isDirty && !isSubmitting && !isSubmitted}
        message="Changes you made will not be saved."
      />
      <Paper className={styles.formHeader} noPadding>
        <div className={styles.headerCategory}>
          <div className={styles.editableName}>
            <EditableValue
              initValue={category.name}
              label="Name"
              maxLength={100}
              onChange={modifyStitchingCategoryName}
              validate={{ required, maxLength: maxLength(100) }}
              inputClassName={styles.editableNameInput}
            />
          </div>
          <TextInput
            data-testid="category-id"
            disabled
            label="Category ID"
            labelPosition="left"
            value={category.id}
            className={styles.disabledId}
          />
        </div>
        {/* <div className={styles.regexContainer}> */}
        {/*   <div className={styles.regexTitle}> */}
        {/*     <h1>Regular expression filtering</h1> */}
        {/*     <p> */}
        {/*       Only identifiers matching the provided regular expression will be considered for */}
        {/*       stitching. Identifiers that do not match will be ignored. If the event has only 1 */}
        {/*       identifier, it won't be stitched to any entity.{" "} */}
        {/*       <a */}
        {/*         href="https://docs.meiro.io/books/meiro-business-explorer/page/identity-stitching-setup#bkmrk-stitching-category-c" */}
        {/*         target="_blank" */}
        {/*         rel="noreferrer" */}
        {/*       > */}
        {/*         Learn more */}
        {/*       </a> */}
        {/*     </p> */}
        {/*   </div> */}
        {/*   <div className={styles.regexContent}> */}
        {/*     <TextInput */}
        {/*       label="Regular Expression filtering" */}
        {/*       placeholder="Optional" */}
        {/*       value={regexPattern ?? ""} */}
        {/*       onChange={e => setRegexPattern(e.target.value)} */}
        {/*     /> */}
        {/*     <p> */}
        {/*       See <span onClick={() => setOpenModal(true)}>examples</span> */}
        {/*     </p> */}
        {/*   </div> */}
        {/* </div> */}
      </Paper>
      <Paper className={styles.rulesForm}>
        <form id="identity-stitching-form" onSubmit={submitForm}>
          <div>
            {fields.map((field, index) => {
              const isSystemRule = field.is_system === 1

              return (
                <div
                  key={field.id}
                  data-testid="event-wrapper"
                  className={`${styles.eventItemWrapper} ${
                    index === fields.length - 1 ? "last" : ""
                  }`}
                >
                  <div data-testid="event-box" className={styles.box}>
                    <Controller
                      control={control}
                      name={`rules.${index}.event_id`}
                      rules={{
                        validate: {
                          required,
                        },
                      }}
                      render={({ field: { value, onChange } }) => (
                        <SelectField
                          disabled={isSystemRule}
                          isLoading={!areEventsFulfilled}
                          isSimpleValue
                          input={{ value, onChange }}
                          label="Event"
                          meta={{
                            touched: true,
                            error: errors.rules?.[index]?.event_id?.message,
                          }}
                          options={eventOptions}
                          placeholder="Event"
                          className={styles.eventId}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name={`rules.${index}.value_expression`}
                      rules={{ validate: required }}
                      render={({ field }) => (
                        <ExpandableAceEditor
                          disabled={isSystemRule}
                          expandModalTitle="Value"
                          input={field}
                          label="Value"
                          meta={{
                            touched: true,
                            error: errors.rules?.[index]?.value_expression?.message,
                          }}
                          width="776px"
                          className={classNames("ace-editor-wrapper-with-margin", {
                            error: isSubmitted && errors.rules?.[index]?.value_expression,
                          })}
                        />
                      )}
                    />
                  </div>
                  {fields.length > 1 && (
                    <IconButton
                      data-testid="delete-button"
                      disabled={isSystemRule}
                      size="xs"
                      color="red"
                      onClick={() => remove(index)}
                      icon="trash-alt"
                      tooltip={
                        field.is_system === 1 ? "Cannot delete system stitching rule" : "Delete"
                      }
                      variant="outlined"
                    />
                  )}
                </div>
              )
            })}
            <Button
              size="md"
              onClick={() => append({ event_id: "", value_expression: "", is_system: 0 })}
            >
              + Add rule
            </Button>
          </div>
        </form>
      </Paper>
      <RegexExamplesModal open={openModal} handleClose={() => setOpenModal(false)} />
    </>
  )
}

import React from "react"
import { User } from "resources/user/userTypes"
import { useFetchAllUsersMap } from "resources/user/userQueries"

type UsernameProps = {
  userId: User["id"]
}

export default function Username({ userId }: UsernameProps) {
  const { data: usersMap = {} } = useFetchAllUsersMap()

  return <span>{usersMap[userId]?.name}</span>
}

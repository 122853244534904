import requestFactory from "api/request"
import { trimWebBannerValue } from "../utils"
import {
  EmbeddedWebBanner,
  EmbeddedWebBannerCreatePayload,
  EmbeddedWebBannerListResponse,
  EmbeddedWebBannerModifyPayload,
  EmbeddedWebBannerListDeletedParams,
  EmbeddedWebBannerListDeletedResponse,
} from "./embeddedWBTypes"

const embeddedWebBanner = {
  list(offset = 0, limit = 50): Promise<EmbeddedWebBannerListResponse> {
    return requestFactory("get", "/embedded_web_banners", { offset, limit })
  },
  retrieve(id: EmbeddedWebBanner["id"]): Promise<{ web_banner: EmbeddedWebBanner }> {
    return requestFactory("get", `/embedded_web_banners/${id}`)
  },
  create(data: EmbeddedWebBannerCreatePayload): Promise<{ web_banner: EmbeddedWebBanner }> {
    return requestFactory("post", "/embedded_web_banners", trimWebBannerValue(data))
  },
  copy(id: EmbeddedWebBanner["id"]): Promise<{ web_banner: EmbeddedWebBanner }> {
    return requestFactory("post", `/embedded_web_banners/${id}`)
  },
  modify(
    id: EmbeddedWebBanner["id"],
    data: EmbeddedWebBannerModifyPayload,
  ): Promise<{ web_banner: EmbeddedWebBanner }> {
    return requestFactory("patch", `/embedded_web_banners/${id}`, trimWebBannerValue(data))
  },
  delete(id: EmbeddedWebBanner["id"]): Promise<void> {
    return requestFactory("delete", `/embedded_web_banners/${id}`)
  },
  listDeleted({
    offset = 0,
    limit = 20,
    searched_text = "",
  }: EmbeddedWebBannerListDeletedParams = {}): Promise<EmbeddedWebBannerListDeletedResponse> {
    return requestFactory("get", "/embedded_web_banners/trash", { offset, limit, searched_text })
  },
  restoreDeleted(id: EmbeddedWebBanner["id"]): Promise<{ embedded_web_banner: EmbeddedWebBanner }> {
    return requestFactory("patch", `/embedded_web_banners/trash/${id}`)
  },
}

export default embeddedWebBanner

export const OPERATION = {
  STRING_EQUALS: "equals",
  STRING_NOT_EQUAL: "not_equal",
  CONTAINS: "contains",
  NOT_CONTAIN: "not_contain",
  STRING_IN: "in",
  STRING_NOT_IN: "not_in",
  CONTAINS_ANY_OF: "contains_any_of",
  NOT_CONTAIN_ANY_OF: "not_contain_any_of",

  NUMBER_EQUALS: "number_equals",
  NUMBER_NOT_EQUAL: "number_not_equal",
  NUMBER_LOWER_THAN: "number_lower_than",
  NUMBER_GREATER_THAN: "number_greater_than",
  NUMBER_BETWEEN: "number_between",
  NUMBER_IN: "number_in",
  NUMBER_NOT_IN: "number_not_in",

  DATE_EQUALS: "date_equals",
  DATE_LOWER_THAN: "date_lower_than",
  DATE_GREATER_THAN: "date_greater_than",
  DATE_BETWEEN: "date_between",
  DATE_MATCHES_CURRENT_DAY: "date_matches_current_day",
  DATE_MATCHES_CURRENT_MONTH: "date_matches_current_month",
  DATE_MATCHES_CURRENT_YEAR: "date_matches_current_year",

  IS_TRUE: "is_true",
  IS_FALSE: "is_false",
  IS_SET: "is_set",
  IS_NOT_SET: "is_not_set",
} as const

export const OPERATION_CONTEXT_TYPE = {
  STRING: "string",
  NUMBER: "number",
  DATE: "date",
  OTHER: "other",
} as const

export const OPERATION_CONTEXT_MAPPER = {
  [OPERATION.STRING_EQUALS]: OPERATION_CONTEXT_TYPE.STRING,
  [OPERATION.STRING_NOT_EQUAL]: OPERATION_CONTEXT_TYPE.STRING,
  [OPERATION.CONTAINS]: OPERATION_CONTEXT_TYPE.STRING,
  [OPERATION.NOT_CONTAIN]: OPERATION_CONTEXT_TYPE.STRING,
  [OPERATION.STRING_IN]: OPERATION_CONTEXT_TYPE.STRING,
  [OPERATION.STRING_NOT_IN]: OPERATION_CONTEXT_TYPE.STRING,
  [OPERATION.CONTAINS_ANY_OF]: OPERATION_CONTEXT_TYPE.STRING,
  [OPERATION.NOT_CONTAIN_ANY_OF]: OPERATION_CONTEXT_TYPE.STRING,

  [OPERATION.NUMBER_EQUALS]: OPERATION_CONTEXT_TYPE.NUMBER,
  [OPERATION.NUMBER_NOT_EQUAL]: OPERATION_CONTEXT_TYPE.NUMBER,
  [OPERATION.NUMBER_LOWER_THAN]: OPERATION_CONTEXT_TYPE.NUMBER,
  [OPERATION.NUMBER_GREATER_THAN]: OPERATION_CONTEXT_TYPE.NUMBER,
  [OPERATION.NUMBER_BETWEEN]: OPERATION_CONTEXT_TYPE.NUMBER,
  [OPERATION.NUMBER_IN]: OPERATION_CONTEXT_TYPE.NUMBER,
  [OPERATION.NUMBER_NOT_IN]: OPERATION_CONTEXT_TYPE.NUMBER,

  [OPERATION.DATE_EQUALS]: OPERATION_CONTEXT_TYPE.DATE,
  [OPERATION.DATE_LOWER_THAN]: OPERATION_CONTEXT_TYPE.DATE,
  [OPERATION.DATE_GREATER_THAN]: OPERATION_CONTEXT_TYPE.DATE,
  [OPERATION.DATE_BETWEEN]: OPERATION_CONTEXT_TYPE.DATE,
  [OPERATION.DATE_MATCHES_CURRENT_DAY]: OPERATION_CONTEXT_TYPE.DATE,
  [OPERATION.DATE_MATCHES_CURRENT_MONTH]: OPERATION_CONTEXT_TYPE.DATE,
  [OPERATION.DATE_MATCHES_CURRENT_YEAR]: OPERATION_CONTEXT_TYPE.DATE,

  [OPERATION.IS_TRUE]: OPERATION_CONTEXT_TYPE.OTHER,
  [OPERATION.IS_FALSE]: OPERATION_CONTEXT_TYPE.OTHER,
  [OPERATION.IS_SET]: OPERATION_CONTEXT_TYPE.OTHER,
  [OPERATION.IS_NOT_SET]: OPERATION_CONTEXT_TYPE.OTHER,
} as const

export const OPERATION_HAS_VALUE = {
  [OPERATION.STRING_EQUALS]: 1,
  [OPERATION.STRING_NOT_EQUAL]: 1,
  [OPERATION.CONTAINS]: 1,
  [OPERATION.NOT_CONTAIN]: 1,
  [OPERATION.STRING_IN]: -1,
  [OPERATION.STRING_NOT_IN]: -1,
  [OPERATION.CONTAINS_ANY_OF]: -1,
  [OPERATION.NOT_CONTAIN_ANY_OF]: -1,

  [OPERATION.NUMBER_EQUALS]: 1,
  [OPERATION.NUMBER_NOT_EQUAL]: 1,
  [OPERATION.NUMBER_LOWER_THAN]: 1,
  [OPERATION.NUMBER_GREATER_THAN]: 1,
  [OPERATION.NUMBER_BETWEEN]: 2,
  [OPERATION.NUMBER_IN]: -1,
  [OPERATION.NUMBER_NOT_IN]: -1,

  [OPERATION.DATE_EQUALS]: 1,
  [OPERATION.DATE_LOWER_THAN]: 1,
  [OPERATION.DATE_GREATER_THAN]: 1,
  [OPERATION.DATE_BETWEEN]: 2,
  [OPERATION.DATE_MATCHES_CURRENT_DAY]: 0,
  [OPERATION.DATE_MATCHES_CURRENT_MONTH]: 0,
  [OPERATION.DATE_MATCHES_CURRENT_YEAR]: 0,

  [OPERATION.IS_TRUE]: 0,
  [OPERATION.IS_FALSE]: 0,
  [OPERATION.IS_SET]: 0,
  [OPERATION.IS_NOT_SET]: 0,
} as const

export const OPERATION_HAS_WHISPERER = {
  [OPERATION.STRING_EQUALS]: true,
  [OPERATION.STRING_NOT_EQUAL]: true,
  [OPERATION.CONTAINS]: true,
  [OPERATION.NOT_CONTAIN]: true,
  [OPERATION.STRING_IN]: false,
  [OPERATION.STRING_NOT_IN]: false,
  [OPERATION.CONTAINS_ANY_OF]: false,
  [OPERATION.NOT_CONTAIN_ANY_OF]: false,

  [OPERATION.NUMBER_EQUALS]: false,
  [OPERATION.NUMBER_NOT_EQUAL]: false,
  [OPERATION.NUMBER_LOWER_THAN]: false,
  [OPERATION.NUMBER_GREATER_THAN]: false,
  [OPERATION.NUMBER_BETWEEN]: false,
  [OPERATION.NUMBER_IN]: false,
  [OPERATION.NUMBER_NOT_IN]: false,

  [OPERATION.DATE_EQUALS]: false,
  [OPERATION.DATE_LOWER_THAN]: false,
  [OPERATION.DATE_GREATER_THAN]: false,
  [OPERATION.DATE_BETWEEN]: false,
  [OPERATION.DATE_MATCHES_CURRENT_DAY]: false,
  [OPERATION.DATE_MATCHES_CURRENT_MONTH]: false,
  [OPERATION.DATE_MATCHES_CURRENT_YEAR]: false,

  [OPERATION.IS_TRUE]: false,
  [OPERATION.IS_FALSE]: false,
  [OPERATION.IS_SET]: false,
  [OPERATION.IS_NOT_SET]: false,
} as const

export const OPERATION_LABEL_MAPPER = {
  [OPERATION.STRING_EQUALS]: "equals",
  [OPERATION.STRING_NOT_EQUAL]: "not equal",
  [OPERATION.CONTAINS]: "contains",
  [OPERATION.NOT_CONTAIN]: "not contain",
  [OPERATION.STRING_IN]: "equals any of",
  [OPERATION.STRING_NOT_IN]: "doesn't equal any of",
  [OPERATION.CONTAINS_ANY_OF]: "contains any of",
  [OPERATION.NOT_CONTAIN_ANY_OF]: "doesn't contain any of",

  [OPERATION.NUMBER_EQUALS]: "equals",
  [OPERATION.NUMBER_NOT_EQUAL]: "not equal",
  [OPERATION.NUMBER_LOWER_THAN]: "lower than",
  [OPERATION.NUMBER_GREATER_THAN]: "greater than",
  [OPERATION.NUMBER_BETWEEN]: "between",
  [OPERATION.NUMBER_IN]: "equals any of",
  [OPERATION.NUMBER_NOT_IN]: "doesn't equal any of",

  [OPERATION.DATE_EQUALS]: "exactly",
  [OPERATION.DATE_LOWER_THAN]: "until",
  [OPERATION.DATE_GREATER_THAN]: "since",
  [OPERATION.DATE_BETWEEN]: "since/until",
  [OPERATION.DATE_MATCHES_CURRENT_DAY]: "matches current day",
  [OPERATION.DATE_MATCHES_CURRENT_MONTH]: "matches current month",
  [OPERATION.DATE_MATCHES_CURRENT_YEAR]: "matches current year",

  [OPERATION.IS_TRUE]: "yes",
  [OPERATION.IS_FALSE]: "no",
  [OPERATION.IS_SET]: "is known",
  [OPERATION.IS_NOT_SET]: "is unknown",
} as const

export const OPERATION_LABEL_NL_MAPPER = {
  [OPERATION.STRING_EQUALS]: "equals",
  [OPERATION.STRING_NOT_EQUAL]: "doesn't equal",
  [OPERATION.CONTAINS]: "contains",
  [OPERATION.NOT_CONTAIN]: "doesn't contain",
  [OPERATION.STRING_IN]: "equals any of",
  [OPERATION.STRING_NOT_IN]: "doesn't equal any of",
  [OPERATION.CONTAINS_ANY_OF]: "contains any of",
  [OPERATION.NOT_CONTAIN_ANY_OF]: "doesn't contain any of",

  [OPERATION.NUMBER_EQUALS]: "equals",
  [OPERATION.NUMBER_NOT_EQUAL]: "doesn't equal",
  [OPERATION.NUMBER_LOWER_THAN]: "is lower than",
  [OPERATION.NUMBER_GREATER_THAN]: "is greater than",
  [OPERATION.NUMBER_BETWEEN]: "is between",
  [OPERATION.NUMBER_IN]: "equals any of",
  [OPERATION.NUMBER_NOT_IN]: "doesn't equal any of",

  [OPERATION.DATE_EQUALS]: "is exactly",
  [OPERATION.DATE_LOWER_THAN]: "is lower than",
  [OPERATION.DATE_GREATER_THAN]: "is greater than",
  [OPERATION.DATE_BETWEEN]: "is since/until",
  [OPERATION.DATE_MATCHES_CURRENT_DAY]: "matches current day",
  [OPERATION.DATE_MATCHES_CURRENT_MONTH]: "matches current month",
  [OPERATION.DATE_MATCHES_CURRENT_YEAR]: "matches current year",

  [OPERATION.IS_TRUE]: "is yes",
  [OPERATION.IS_FALSE]: "is no",
  [OPERATION.IS_SET]: "is known",
  [OPERATION.IS_NOT_SET]: "is unknown",
} as const

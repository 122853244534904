import React from "react"

// ui components
import Paper from "components/UI/elements/Paper"

import accessImageUrl from "./403.svg"
import "./NotAuthorized.scss"
import { Link } from "react-router-dom"
import { getRoutePath } from "routes"
import Page from "components/UI/Page/Page"

const NotAuthorizedPage = () => (
  <Page title="Not authorized">
    <Paper className="not-authorized">
      <img src={accessImageUrl} alt="Not Authorized" className="left-side" />
      <div className="right-side">
        <h1>You don't have permission to view this page.</h1>
        <h2>Contact your administrator.</h2>
        <h2>
          <Link to={getRoutePath("home")}>Go to home page.</Link>
        </h2>
      </div>
    </Paper>
  </Page>
)

export default NotAuthorizedPage

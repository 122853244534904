import React, { lazy, Suspense, useState } from "react"
import { Redirect, useHistory, useParams } from "react-router-dom"
import {
  useDeleteEmailTemplate,
  useFetchEmailTemplateById,
  useModifyEmailTemplate,
} from "resources/email/emailTemplate/emailTemplateQueries"
import Page from "components/UI/Page/Page"
import { getRoutePath } from "routes"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { EmailTemplatePayload } from "resources/email/emailTemplate/emailTemplateTypes"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { MODAL } from "sharedConstants"
const EmailTemplateForm = lazy(() => import("../components/EmailTemplateForm/EmailTemplateForm"))

export default function EmailTemplateDetail() {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { data: emailTemplate, isLoading } = useFetchEmailTemplateById(id)
  const modifyMutation = useModifyEmailTemplate()
  const modifyEmailTemplate = (data: EmailTemplatePayload) =>
    modifyMutation.mutateAsync({ id, data })
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const deleteMutation = useDeleteEmailTemplate()
  const deleteEmailTemplate = () =>
    deleteMutation.mutateAsync(
      { id: emailTemplate!.id },
      {
        onSuccess() {
          history.push(getRoutePath("channels.emails.templates"))
        },
      },
    )

  const saveMutation = useModifyEmailTemplate()
  const [templateToCopy, setTemplateToCopy] = useState<EmailTemplatePayload | null>(null)
  const [templateToUse, setTemplateToUse] = useState<EmailTemplatePayload | null>(null)

  function copyTemplate() {
    saveMutation.mutate(
      { id, data: templateToCopy! },
      {
        onSuccess(_, { data }) {
          history.push({
            pathname: getRoutePath("channels.emails.templates.create"),
            state: { emailTemplate: { ...data, name: "copy of " + data.name } },
          })
        },
      },
    )
  }

  function useTemplate() {
    saveMutation.mutate(
      { id, data: templateToUse! },
      {
        onSuccess(_, { data }) {
          history.push({
            pathname: getRoutePath("channels.emails.create"),
            state: { emailTemplate: data },
          })
        },
      },
    )
  }

  if (emailTemplate === null) {
    return <Redirect to={getRoutePath("not-found")} />
  }

  return (
    <Page title="Edit email template" backRouteFallback={getRoutePath("channels.emails.templates")}>
      <ConfirmModal
        isLoading={deleteMutation.isLoading}
        open={isDeleteModalOpen}
        action="delete"
        item={emailTemplate?.name}
        title="Are you sure?"
        type="delete"
        what="email template"
        handleClose={() => setIsDeleteModalOpen(false)}
        handleConfirm={deleteEmailTemplate}
      />
      <ConfirmModal
        open={!!templateToCopy}
        type={MODAL.TYPE.SUCCESS}
        handleClose={() => setTemplateToCopy(null)}
        handleConfirm={copyTemplate}
        title="Save template"
        text="We will save the existing template before proceeding."
        isLoading={saveMutation.isLoading}
      />
      <ConfirmModal
        open={!!templateToUse}
        type={MODAL.TYPE.SUCCESS}
        handleClose={() => setTemplateToUse(null)}
        handleConfirm={useTemplate}
        title="Save template"
        text="We will save the existing template before proceeding."
        isLoading={saveMutation.isLoading}
      />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <Suspense fallback={<LoadingIndicator />}>
          <EmailTemplateForm
            defaultValues={emailTemplate}
            onSubmit={modifyEmailTemplate}
            onDelete={() => setIsDeleteModalOpen(true)}
            onCopy={setTemplateToCopy}
            onUseTemplate={setTemplateToUse}
          />
        </Suspense>
      )}
    </Page>
  )
}

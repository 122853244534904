import { EmbeddedWebBanner } from "resources/webBanner/embeddedWebBanner/embeddedWBTypes"
import { EmbeddedWebBannersFormValues } from "../embeddedWBComponentTypes"
import { PRIORITY_OPTIONS, FREQUENCY_CAP_TYPE_OPTIONS } from "./dropdownOptions"
import { getImgSrcFromHtml } from "resources/webBanner/embeddedWebBanner/getImgSrcFromHtml"

export function embeddedWBToFormValues(
  webBanner?: EmbeddedWebBanner,
): EmbeddedWebBannersFormValues | undefined {
  if (!webBanner) {
    return
  }

  return {
    element_id: webBanner.element_id,
    name: webBanner.name,
    condition: webBanner.settings.condition,
    priority: PRIORITY_OPTIONS.find(prio => prio.value === webBanner.settings.priority)!,
    frequency_cap_window_max_count:
      webBanner.settings.frequency_cap?.per_user.window?.max_display_count ?? null,
    frequency_cap_window_type: FREQUENCY_CAP_TYPE_OPTIONS.find(
      type => type.value === webBanner.settings.frequency_cap?.per_user.window?.type,
    ) ?? { label: "session", value: "session" },
    frequency_cap_window_size:
      webBanner.settings.frequency_cap?.per_user.window?.type === "session"
        ? null
        : webBanner.settings.frequency_cap?.per_user.window?.size ?? null,
    frequency_cap_user_max_count:
      webBanner.settings.frequency_cap?.per_user.max_display_count ?? null,
    destination_url: webBanner.settings.content.destination_url,
    content_type: webBanner.settings.content.type,
    html: webBanner?.settings.content.type !== "html" ? "" : webBanner?.settings.content.html,
    image_url: getImgSrcFromHtml(webBanner),
    width: webBanner.settings.fe_settings?.width,
    preview_width: webBanner.settings.fe_settings?.preview_width,
    disabled: webBanner.disabled,
    tracking_disabled: webBanner.settings?.tracking_disabled,
  }
}

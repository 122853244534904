export const links = {
  homepage: [
    {
      title: "Home page & notification center tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-homepage-notification-center",
    },
    {
      title: "I do not have access to some tabs/ features",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/i-do-not-have-access-to-some-tabs-features",
    },
  ],
  profiles: [
    {
      title: "Profiles tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-customers",
    },
    {
      title: "What can I understand through the single customer profile",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/what-can-i-understand-through-the-single-customer-profile",
    },
    {
      title: "Identity stitching and how customer profile's identity appears",
      url: "https://docs.meiro.io/books/meiro-knowledge-base/page/identity-stitching-and-how-customer-identity-appears",
    },
    {
      title: "Customer profiles: how can I save customer profiles",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/customer-profiles-how-can-i-save-customer-profiles",
    },
  ],
  segments: [
    {
      title: "Segments tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-segments",
    },
    {
      title: "Segment detail tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-segment-detail",
    },
    {
      title: "How to build segments of customers",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/how-to-build-segments-of-customers",
    },
    {
      title: "Segment builder: tutorials & tips",
      url: "https://docs.meiro.io/books/meiro-business-explorer/chapter/segment-builder-tutorials-tips",
    },
    {
      title: "Insights what are they for and how do they work",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/insights-what-are-they-for-and-how-do-they-work",
    },
  ],
  data: [
    {
      title: "Data library tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-data",
    },
  ],
  analytics: [
    {
      title: "Analytics tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-reporting",
    },
    {
      title: "Reporting in Meiro Business Explorer",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/reporting-in-meiro-business-explorer",
    },
    {
      title: "Reporting dashboard templates",
      url: "https://docs.meiro.io/books/meiro-business-explorer/chapter/reporting-dashboard",
    },
    {
      title: "Understand the bigger picture of data available in Meiro",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/understand-the-bigger-picture-of-data-available-in-meiro",
    },
    {
      title: "Insights: what are they for and how do they work",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/insights-what-are-they-for-and-how-do-they-work",
    },
  ],
  channels: [
    {
      title: "Web: Pop-up and Native Banner Campaign",
      url: "https://docs.meiro.io/books/meiro-business-explorer/chapter/web-pop-up-and-native-banner-campaign",
    },
    {
      title: "Native banners tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-personalization-embedded-web-banners",
    },
    {
      title: "Pop-up banners tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-personalization-pop-up-web-banners",
    },
    {
      title: "Mobile push: tutorials & tips",
      url: "https://docs.meiro.io/books/meiro-business-explorer/chapter/mobile-push-tutorials-tips",
    },
    {
      title: "Emails: tutorials & tips",
      url: "https://docs.meiro.io/books/meiro-business-explorer/chapter/email-campaigns",
    },
    {
      title: "Promo code tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-personalization-promo-codes",
    },
  ],
  administration: [
    {
      title: "Administration tab: Configuration",
      url: "https://docs.meiro.io/books/meiro-business-explorer/chapter/administration-tab-configurations",
    },
    {
      title: "Administration tab: Entities",
      url: "https://docs.meiro.io/books/meiro-business-explorer/chapter/administration-tab-entities",
    },
    {
      title: "Administration tab: User setup",
      url: "https://docs.meiro.io/books/meiro-business-explorer/chapter/administration-tab-user-setup",
    },
  ],
  trash: [
    {
      title: "Trash tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-trash",
    },
    {
      title: "How to restore deleted users, destinations and segments",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/how-to-restore-deleted-users-destinations-and-segments",
    },
  ],
  userSettings: [
    {
      title: "User Settings tab in details",
      url: "https://docs.meiro.io/books/meiro-business-explorer/page/tab-user-settings",
    },
  ],
}

import React from "react"
import PropTypes from "prop-types"

import avatar from "./images/avatar.svg"

const CustomerAvatar = ({ className = "" }) => {
  return <img className={className} src={avatar} alt="" />
}

CustomerAvatar.propTypes = {
  className: PropTypes.string,
}

export default CustomerAvatar

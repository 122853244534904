import requestFactory from "api/request"
import { trimWebBannerValue } from "../utils"
import {
  PopupWebBanner,
  PopupWebBannerCreatePayload,
  PopupWebBannerListDeletedParams,
  PopupWebBannerListDeletedResponse,
  PopupWebBannerListResponse,
  PopupWebBannerModifyPayload,
  PopupWebBannerSettingsModifyPayload,
  PopupWebBannerSettingsResponse,
} from "./popupWBTypes"

const popupWebBanner = {
  list(offset = 0, limit = 50): Promise<PopupWebBannerListResponse> {
    return requestFactory("get", "/web_banners", { offset, limit })
  },
  retrieve(id: PopupWebBanner["id"]): Promise<{ web_banner: PopupWebBanner }> {
    return requestFactory("get", `/web_banners/${id}`)
  },
  create(data: PopupWebBannerCreatePayload): Promise<{ web_banner: PopupWebBanner }> {
    return requestFactory("post", "/web_banners", trimWebBannerValue(data))
  },
  copy(id: PopupWebBanner["id"]): Promise<{ web_banner: PopupWebBanner }> {
    return requestFactory("post", `/web_banners/${id}`)
  },
  modify(
    id: PopupWebBanner["id"],
    data: PopupWebBannerModifyPayload,
  ): Promise<{ web_banner: PopupWebBanner }> {
    return requestFactory("patch", `/web_banners/${id}`, trimWebBannerValue(data))
  },
  delete(id: PopupWebBanner["id"]): Promise<void> {
    return requestFactory("delete", `/web_banners/${id}`)
  },
  listDeleted({
    offset = 0,
    limit = 20,
    searched_text = "",
  }: PopupWebBannerListDeletedParams = {}): Promise<PopupWebBannerListDeletedResponse> {
    return requestFactory("get", "/web_banners/trash", { offset, limit, searched_text })
  },
  restoreDeleted(id: PopupWebBanner["id"]): Promise<{ popup_web_banner: PopupWebBanner }> {
    return requestFactory("patch", `/web_banners/trash/${id}`)
  },

  settings: {
    retrieve(): Promise<PopupWebBannerSettingsResponse> {
      return requestFactory("get", "/web_banners/settings")
    },

    modify(data: PopupWebBannerSettingsModifyPayload): Promise<PopupWebBannerSettingsResponse> {
      return requestFactory("put", "/web_banners/settings", data)
    },
  },
}

export default popupWebBanner

import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Paper from "components/UI/elements/Paper"
import Table, {
  RowMessage,
  TbodySortable,
  Td,
  Th,
  Thead,
  TrSortable,
} from "components/UI/elements/Table"
import ToggleButton from "components/UI/elements/ToggleButton/ToggleButton"
import React, { useCallback, useState } from "react"
import IconButton from "components/UI/elements/IconButton/IconButton"

import styles from "./ReportsList.module.scss"
import { useHistory } from "react-router"
import { getRoutePath } from "routes"
import { Report } from "resources/report/reportTypes"
import ConfirmModal from "components/UI/components/ConfirmModal"
import { DragEndEvent } from "@dnd-kit/core"
import { useMetabaseLogin } from "resources/user/userQueries"
import Page from "components/UI/Page/Page"
import {
  useDeleteReport,
  useFetchAllReports,
  useModifyReport,
  useMoveReport,
} from "resources/report/reportQueries"

export default function () {
  const history = useHistory()
  const { data: reports, isLoading: isLoadingReports } = useFetchAllReports()
  const modifyMutation = useModifyReport()
  const deleteMutation = useDeleteReport()
  const moveMutation = useMoveReport()
  const [deleteModal, setDeleteModal] = useState<{ open: boolean; report: any | null }>({
    open: false,
    report: null,
  })

  const closeDeleteReportModal = useCallback(() => {
    setDeleteModal({
      ...deleteModal,
      open: false,
    })
  }, [deleteModal])

  const onDragEnd = useCallback(
    (event: DragEndEvent) => {
      if (event.over?.data.current) {
        const toIndex = event.over.data.current.sortable.index
        const fromIndex = event.active.data.current?.sortable.index
        if (!isNaN(fromIndex) && toIndex !== fromIndex) {
          moveMutation.mutate({ id: event.active.id as Report["id"], fromIndex, toIndex })
        }
      }
    },
    [moveMutation],
  )

  const metabaseLoginMutation = useMetabaseLogin()

  return (
    <Page
      title="Reporting"
      headerContent={
        <div>
          <Button
            color="grey"
            loading={metabaseLoginMutation.isLoading}
            variant="outlined"
            className={styles.headerButtonMargin}
            onClick={_ => metabaseLoginMutation.mutate()}
          >
            Metabase
          </Button>
          <Button
            onClick={() => {
              history.push(getRoutePath("administration.reports.create"))
            }}
          >
            + Create
          </Button>
        </div>
      }
    >
      {isLoadingReports && <LoadingIndicator />}
      {!isLoadingReports && reports && (
        <Paper>
          {reports.length === 0 ? (
            <RowMessage>No reports found.</RowMessage>
          ) : (
            <Table>
              <Thead>
                <Th>#</Th>
                <Th>Name</Th>
                <Th className={styles.tableDashboardIdCol}>Dashboard ID</Th>
                <Th textAlignRight className={styles.tableEnabledCol}>
                  Show in Reporting tab
                </Th>
                <Th className={styles.tableActionsCol}>&nbsp;</Th>
              </Thead>
              <TbodySortable items={reports} onDragEnd={onDragEnd}>
                {reports.map(report => (
                  <TrSortable key={report.id} id={report.id}>
                    <Td textBlack textBold textBigger>
                      {report.name}
                    </Td>
                    <Td className={styles.tableDashboardIdCol}>{report.dashboard_id}</Td>
                    <Td textAlignRight className={styles.tableEnabledCol}>
                      <ToggleButton
                        value={!report.disabled}
                        handleToggle={() =>
                          modifyMutation.mutateAsync({
                            id: report.id,
                            data: { disabled: !report.disabled },
                          })
                        }
                      />
                    </Td>
                    <Td textAlignRight className={styles.tableActionsCol}>
                      <IconButton
                        color="black"
                        size="xs"
                        onClick={() => {
                          history.push(
                            getRoutePath("administration.reports.detail", {
                              id: report.id,
                            }),
                          )
                        }}
                        icon="pencil-alt"
                        tooltip="Edit"
                        variant="outlined"
                      />
                      <IconButton
                        color="red"
                        size="xs"
                        onClick={() => {
                          setDeleteModal({
                            open: true,
                            report,
                          })
                        }}
                        icon="trash-alt"
                        tooltip="Delete"
                        variant="outlined"
                        className={styles.actionButtonMargin}
                      />
                    </Td>
                  </TrSortable>
                ))}
              </TbodySortable>
            </Table>
          )}
        </Paper>
      )}
      <ConfirmModal
        title="Are you sure?"
        open={deleteModal.open}
        type="delete"
        text={`Do you really want to delete report: ${deleteModal.report?.name}`}
        handleClose={closeDeleteReportModal}
        handleConfirm={() => {
          deleteMutation.mutate(
            { id: deleteModal.report.id },
            {
              onSuccess() {
                closeDeleteReportModal()
              },
            },
          )
        }}
        isLoading={deleteMutation.isLoading}
      />
    </Page>
  )
}

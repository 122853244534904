import React, { useCallback, useState } from "react"
import classNames from "classnames"
import styles from "./EditableValue.module.scss"
import { Validate, useForm } from "react-hook-form"
import TextInput from "components/UI/elements/TextInput/TextInput"
import useToggle from "hooks/useToggle"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"

export type EditableValueProps = {
  initValue: string
  onChange: (value: string) => void | Promise<void>
  validate?: Validate<string, unknown> | Record<string, Validate<string, unknown>> | undefined
  className?: string
  inputClassName?: string
  label?: string
  maxLength?: number
  minLength?: number
}

export default function EditableValue({
  className,
  initValue,
  inputClassName,
  label,
  maxLength,
  minLength,
  onChange,
  validate,
}: EditableValueProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onSubmit" })
  const [isEditing, toggleIsEditing] = useToggle(false)
  const [isSaving, setIsSaving] = useState(false)

  const save = useCallback(
    async ({ value }) => {
      if (value !== initValue) {
        setIsSaving(true)
        await onChange(value)
        setIsSaving(false)
      }
      toggleIsEditing()
    },
    [initValue, onChange, toggleIsEditing],
  )

  const escListener = useCallback(
    e => {
      if (e.key === "Escape") toggleIsEditing()
    },
    [toggleIsEditing],
  )

  return isEditing ? (
    <form onSubmit={handleSubmit(save)}>
      <div className={styles.edit}>
        <TextInput
          defaultValue={initValue}
          error={errors.value?.message as string | undefined}
          className={inputClassName}
          onKeyUp={escListener}
          autoFocus
          maxLength={maxLength}
          minLength={minLength}
          label={label}
          labelPosition="left"
          data-testid="editable-value-input"
          {...register("value", { validate })}
        />
        <button
          className={styles.saveButton}
          type="submit"
          disabled={isSaving}
          data-testid="save-button"
        >
          {isSaving ? <LoadingIndicator /> : <FontAwesomeIcon icon={["fas", "check"]} />}
        </button>
        <button
          className={styles.button}
          type="button"
          disabled={isSaving}
          onClick={toggleIsEditing}
          data-testid="cancel-button"
        >
          <FontAwesomeIcon icon={["fas", "times"]} />
        </button>
      </div>
    </form>
  ) : (
    <div className={styles.view}>
      {label && <label className={styles.label}>{label}</label>}
      <div className={classNames(styles.value, className)} data-testid="editable-value">
        {initValue}
      </div>
      <button
        className={styles.button}
        type="button"
        onClick={toggleIsEditing}
        data-testid="edit-button"
      >
        <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
      </button>
    </div>
  )
}

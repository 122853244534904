import { DimensionTree } from "resources/segment/segment/segmentConditionsTypes"
import { isDimensionBranch } from "resources/segment/segment/utilities/segmentConditionTypeChecks"

export function countDimensionsUsed(tree: DimensionTree): number {
  if (isDimensionBranch(tree)) {
    return tree.operands.reduce((prev, curr) => prev + countDimensionsUsed(curr), 0)
  } else {
    return 1
  }
}

export function getSubattributesUsed(tree: DimensionTree): string[] {
  if (isDimensionBranch(tree)) {
    return tree.operands.reduce(
      (prev, curr) => prev.concat(getSubattributesUsed(curr)),
      [] as string[],
    )
  } else {
    const id = tree.condition.sub_attribute_id
    return id ? [id] : []
  }
}

import requestFactory from "api/request"
import { SystemInfo } from "./systemInfoTypes"

const systemInfo = {
  get(): Promise<SystemInfo> {
    return requestFactory("get", "/system_info")
  },
}

export default systemInfo

import { KeyboardEvent, useCallback, useEffect } from "react"

export default function useKeyListener(key: string, callback: (e: KeyboardEvent) => void) {
  const handleKey = useCallback(
    e => {
      if (e.key === key) {
        callback(e)
      }
    },
    [callback, key],
  )

  useEffect(() => {
    window.addEventListener("keyup", handleKey, false)

    return () => window.removeEventListener("keyup", handleKey, false)
  }, [handleKey])
}

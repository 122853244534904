import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import SegmentDetail from "pages/Segments/components/SegmentDetail/SegmentDetail"
import SegmentExportLog from "pages/Segments/components/SegmentExportLog"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import { useHasAccess } from "resources/user/currentUserQueries"

export default function SmartSegmentDetail() {
  const hasAccess = useHasAccess()

  return (
    <Switch>
      <Route
        path={getRoutePath("segments.smart.detail")}
        exact
        render={() =>
          hasAccess.setup.smartSegments ? (
            <SegmentDetail view="settings" type="smart" isSmartSetup />
          ) : (
            <SegmentDetail view="builder" type="smart" />
          )
        }
      />
      <Route
        path={getRoutePath("segments.smart.detail.builder")}
        exact
        render={({
          match: {
            params: { id },
          },
        }) =>
          hasAccess.setup.smartSegments ? (
            <SegmentDetail view="builder" type="smart" isSmartSetup />
          ) : (
            <Redirect to={getRoutePath("segments.smart.detail", { id })} />
          )
        }
      />
      <Route
        path={getRoutePath("segments.smart.detail.insights")}
        render={() => (
          <SegmentDetail
            view="insights"
            type="smart"
            isSmartSetup={hasAccess.setup.smartSegments}
          />
        )}
      />
      <Route
        path={getRoutePath("segments.smart.detail.exports")}
        exact
        render={() => (
          <SegmentDetail view="exports" type="smart" isSmartSetup={hasAccess.setup.smartSegments} />
        )}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.segments.export}
        path={getRoutePath("segments.smart.export.detail")}
        component={SegmentExportLog}
      />
    </Switch>
  )
}

function sanitizeTime(max: number) {
  return function (input: string): string | null {
    if (!/^\d*$/.test(input)) {
      return null
    }

    if (parseInt(input, 10) > max) {
      return null
    }

    if (input.length === 0) {
      return "00"
    }

    if (input.length === 1) {
      return "0" + input
    }

    if (input.length === 2) {
      return input
    }

    if (input[0] === "0") {
      return input.slice(1)
    }

    return null
  }
}

export const sanitizeHour = sanitizeTime(23)
export const sanitizeMinute = sanitizeTime(59)

import { isAndOrCondition } from "components/ConditionBuilder/utils"
import { ConditionTree } from "types/conditionTree"
import { DimensionTree, SegmentCondition } from "../segmentConditionsTypes"
import { Segment } from "../segmentTypes"
import { migrateMultiValueCondition } from "./segmentConditionsMigration"
import { isCompoundSegmentCondition, isDimensionBranch } from "./segmentConditionTypeChecks"

// Incoming transformations

export function segmentConditionFromAPIFormat(conditionTree: any): ConditionTree<SegmentCondition> {
  if (conditionTree === null) return conditionTree

  if (conditionTree.attribute_id !== undefined) {
    // A leaf condition
    return migrateMultiValueCondition(conditionTree)
  }

  return {
    // Rename 'operation' to 'operator'
    operator: conditionTree.operation,
    operands: conditionTree.operands.map(segmentConditionFromAPIFormat),
  }
}

export function segmentFromAPIFormat(segment: any): Segment {
  if (!segment.settings?.conditions_operation) return segment

  return {
    ...segment,
    settings: {
      ...segment.settings,
      conditions_operation: segmentConditionFromAPIFormat(segment.settings.conditions_operation),
    },
  }
}

// Outgoing transformations

const trimValue = (value: any) => {
  if (!value) return value

  if (typeof value === "string") return value.trim()
  if (Array.isArray(value) && value.every(v => typeof v === "string"))
    return value.map((v: string) => v.trim())

  return value
}

const trimDimensionTree = (dimensionTree: DimensionTree): DimensionTree => {
  if (isDimensionBranch(dimensionTree))
    return { ...dimensionTree, operands: dimensionTree.operands.map(trimDimensionTree) }

  return {
    ...dimensionTree,
    condition: { ...dimensionTree.condition, value: trimValue(dimensionTree.condition.value) },
  }
}

const trimSegmentCondition = (segmentCondition: SegmentCondition) => {
  if (isCompoundSegmentCondition(segmentCondition)) return trimDimensionTree(segmentCondition)

  return {
    ...segmentCondition,
    condition: {
      ...segmentCondition.condition,
      value: trimValue(segmentCondition.condition.value),
    },
  }
}

function segmentConditionToAPIFormat(conditionTree: ConditionTree<SegmentCondition>): any {
  if (isAndOrCondition(conditionTree)) {
    return {
      operation: conditionTree.operator,
      operands: conditionTree.operands.map(segmentConditionToAPIFormat),
    }
  }

  return trimSegmentCondition(conditionTree)
}

export function segmentToAPIFormat(segment: Partial<Segment>) {
  if (!segment.settings?.conditions_operation) return segment

  return {
    ...segment,
    settings: {
      ...segment.settings,
      conditions_operation: segmentConditionToAPIFormat(segment.settings.conditions_operation),
    },
  }
}

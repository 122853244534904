import React, { useState } from "react"
import Modal from "components/UI/elements/Modal"
import ConfirmModal from "../ConfirmModal"
import TemplateGalleryItem, { Template } from "./TemplateGalleryItem/TemplateGalleryItem"
import Button from "components/UI/elements/Button/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styles from "./TemplateGallery.module.scss"
import { prop, sort } from "ramda"
import { ascend } from "utilities/comparators"
import { templateTagColors } from "./tags"

type TemplateGalleryProps = {
  templates: Template[]
  isTextAreaEmpty: boolean
  insertTemplate: (template: Template) => void
}

const TemplateGallery = ({ insertTemplate, templates, isTextAreaEmpty }: TemplateGalleryProps) => {
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState<boolean>(false)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null)

  const openGalleryModal = () => {
    setIsGalleryModalOpen(true)
  }

  const closeGalleryModal = () => {
    setIsGalleryModalOpen(false)
    setSelectedTemplate(null)
  }

  const confirmSelection = () => {
    if (selectedTemplate === null) {
      return
    }

    if (isTextAreaEmpty) {
      insertTemplate(selectedTemplate)
      closeGalleryModal()
    } else {
      setIsGalleryModalOpen(false)
      setIsConfirmModalOpen(true)
    }
  }

  const confirmOverwrite = () => {
    if (selectedTemplate === null) {
      return
    }

    insertTemplate(selectedTemplate)
    setIsConfirmModalOpen(false)
    setSelectedTemplate(null)
  }

  const cancelOverwrite = () => {
    setIsConfirmModalOpen(false)
    openGalleryModal()
  }

  const goBack = () => {
    setSelectedTemplate(null)
  }

  return (
    <>
      <Button onClick={openGalleryModal} className={styles.addButton}>
        <FontAwesomeIcon icon={["fas", "folder"]} /> template gallery
      </Button>
      <ConfirmModal
        open={isConfirmModalOpen}
        type="success"
        handleClose={cancelOverwrite}
        handleConfirm={confirmOverwrite}
        title="Replace banner HTML"
        text="Do you want to replace banner HTML?"
      />
      <Modal
        className={styles.galleryModal}
        title={
          selectedTemplate?.name
            ? `${selectedTemplate.name} template`
            : "HTML banners template gallery"
        }
        open={isGalleryModalOpen}
        handleClose={closeGalleryModal}
      >
        <>
          <div className={styles.galleryModalContent}>
            {selectedTemplate === null && (
              <div className={styles.templatesGrid}>
                {sort(ascend(prop("name")), templates).map(template => {
                  return (
                    <TemplateGalleryItem
                      key={template.id}
                      onClick={() => setSelectedTemplate(template)}
                      template={template}
                    />
                  )
                })}
              </div>
            )}

            {selectedTemplate !== null && (
              <div className={styles.templateDetail}>
                <div className={styles.detailText}>{selectedTemplate.detailPage}</div>
                <div className={styles.rightPanel}>
                  <img className={styles.detailImg} src={selectedTemplate.imgSrc} alt="" />
                  <div className={styles.tagsWrapper}>
                    {selectedTemplate.tags?.map(tag => (
                      <div
                        className={styles.tag}
                        key={tag}
                        style={{ backgroundColor: templateTagColors[tag] }}
                      >
                        {tag}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className={styles.actionButtons}>
            <Button
              color="grey"
              size="md"
              variant="text"
              onClick={selectedTemplate ? goBack : closeGalleryModal}
            >
              {selectedTemplate ? "Go back" : "Cancel"}
            </Button>
            {selectedTemplate && (
              <Button size="md" onClick={confirmSelection}>
                Select
              </Button>
            )}
          </div>
        </>
      </Modal>
    </>
  )
}

export default TemplateGallery

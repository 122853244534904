import { EWBFrequencyCapType } from "resources/webBanner/embeddedWebBanner/embeddedWBTypes"
import { SelectOption } from "types/util"

export const PRIORITY_OPTIONS: SelectOption<number>[] = [
  { label: "0", value: 0 },
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
  { label: "10", value: 10 },
]

export const FREQUENCY_CAP_TYPE_OPTIONS: SelectOption<EWBFrequencyCapType>[] = [
  { label: "session", value: "session" },
  { label: "hours", value: "hours" },
  { label: "days", value: "days" },
]

import { cancelable } from "api/request"

export default class PendingPromise {
  pendingPromises = []

  create(promise, cancelableUrl = "") {
    let hasCanceled_ = false

    const wrappedPromise = new Promise((resolve, reject) => {
      promise.then(
        val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
        error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)),
      )
    })

    const cancelablePromise = {
      promise: wrappedPromise,
      cancel() {
        if (cancelableUrl && cancelable[cancelableUrl]) {
          cancelable[cancelableUrl].cancel()
        }
        hasCanceled_ = true
      },
    }

    this._appendPendingPromise(cancelablePromise)

    return cancelablePromise
  }

  _appendPendingPromise(promise) {
    this.pendingPromises = [...this.pendingPromises, promise]
  }

  remove(promise) {
    this.pendingPromises = this.pendingPromises.filter(p => p !== promise)
  }

  cancelAll() {
    this.pendingPromises.map(p => p.cancel())
  }
}

import { ascend as _ascend, descend as _descend, flip } from "ramda"

const ascStringComparator = new Intl.Collator("en", { caseFirst: "upper" }).compare
const descStringComparator = flip(ascStringComparator)

export const ascend = <T>(f: (obj: T) => any) => {
  const ascStandardComparator = _ascend(f)

  return (a: T, b: T) => {
    const fa = f(a)
    const fb = f(b)

    return typeof fa === "string" && typeof fb === "string"
      ? ascStringComparator(fa, fb)
      : ascStandardComparator(a, b)
  }
}

export const descend = <T>(f: (obj: T) => any) => {
  const descStandardComparator = _descend(f)

  return (a: T, b: T) => {
    const fa = f(a)
    const fb = f(b)

    return typeof fa === "string" && typeof fb === "string"
      ? descStringComparator(fa, fb)
      : descStandardComparator(a, b)
  }
}

import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import "./InfoTooltip.scss"
import Tippy from "@tippyjs/react"

const InfoTooltip = ({ children, placement = "top", className = "", ...otherProps }) => {
  return (
    <Tippy content={children} placement={placement} {...otherProps}>
      <span className={`info-tooltip ${className}`}>
        <FontAwesomeIcon icon={["fas", "info-circle"]} className="labels-tooltip-icon" />
      </span>
    </Tippy>
  )
}

InfoTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
  className: PropTypes.string,
}

export default InfoTooltip

export const ITEMS_PER_PAGE = 100

export default async function fetchAll<
  V,
  R extends { [key in K]: V[] },
  K extends string,
  I extends boolean = false,
>(config: {
  fetchFn: (offset: number, limit: number) => Promise<R>
  key: K
  includeAll?: I
  limit?: number
}): Promise<I extends true ? R : R[K]> {
  let result: V[] = []
  let offset = 0
  const limit = config.limit ?? ITEMS_PER_PAGE
  while (true) {
    const response = await config.fetchFn(offset, limit)
    const data = response[config.key]
    result = result.concat(data)
    offset += limit
    if (data.length < limit) {
      // @ts-ignore
      return config.includeAll ? { ...response, [config.key]: result } : result
    }
  }
}

import requestFactory from "api/request"
import { TrashItemTypesCountsResponse } from "./trashTypes"

const trash = {
  itemTypesCounts(): Promise<TrashItemTypesCountsResponse> {
    return requestFactory("get", "/trash/counts")
  },
}

export default trash

import { ChartTooltipModel } from "chart.js"
import { RefObject } from "react"
import { Bar } from "react-chartjs-2"

const caretSize = 6
const zIndex = "6"
const bgColor = "rgba(0,0,0,0.8)"

export default function makeCustomTooltip(chartRef: RefObject<Bar>) {
  return function (tooltipModel: ChartTooltipModel) {
    let tooltipEl = document.getElementById("chartjs-tooltip")

    // Create element on first render
    if (!tooltipEl) {
      tooltipEl = document.createElement("div")
      tooltipEl.id = "chartjs-tooltip"
      tooltipEl.style.position = "absolute"
      tooltipEl.style.backgroundColor = bgColor
      tooltipEl.style.borderRadius = "6px"
      tooltipEl.style.color = "#ffffff"
      tooltipEl.style.zIndex = zIndex
      tooltipEl.innerHTML = "<table></table>"
      document.body.appendChild(tooltipEl)

      const caretEl = document.createElement("div")
      caretEl.id = "chartjs-tooltip-caret"
      caretEl.style.position = "absolute"
      caretEl.style.zIndex = zIndex
      caretEl.style.borderWidth = caretSize + "px"
      caretEl.style.borderColor = "transparent"
      caretEl.style.borderStyle = "solid"
      caretEl.style.display = "inline-block"
      tooltipEl.appendChild(caretEl)
    }

    // Hide if no tooltip
    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = "0"
      return
    }

    const {
      title: [title],
      beforeBody,
      body,
      afterBody,
    } = tooltipModel

    let innerHtml = `
      <thead>
        <tr>
          <th style='text-align:left;'>${title}</th>
        </tr>
      </thead>
      `

    innerHtml += "<tbody>"

    if (beforeBody) {
      innerHtml += `
      <tr>
        <td>${beforeBody}</td>
      </tr>`
    }

    // The value has been set aside in the `afterLabel` callback in order to filter here
    const nonZeroItems = body.filter(({ after: [after] }) => after !== "0")
    // Only trim items with value = 0 if there is more than 1 of them
    const shouldTrimItems = body.length - nonZeroItems.length > 1
    const itemsToRender = shouldTrimItems ? nonZeroItems : body

    itemsToRender.forEach(({ lines }, i) => {
      // mistyped in library
      // @ts-ignore
      const { backgroundColor, borderColor } = tooltipModel.labelColors[i]

      const colorSquareStyle = `
        background-color: ${backgroundColor};
        border-color: ${borderColor};
        border-width: 2px;
        width: 10px;
        height: 10px;
        display: inline-block;
        margin-right: 4px;
        `

      innerHtml += `
        <tr>
          <td>
            <span style="${colorSquareStyle}"></span>
            ${lines}
          </td>
        </tr>
        `
    })

    if (afterBody && shouldTrimItems) {
      innerHtml += `
        <tr>
          <td>${afterBody}</td>
        </tr>`
    }

    innerHtml += "</tbody>"

    const tableRoot = tooltipEl.querySelector("table")
    tableRoot!.innerHTML = innerHtml

    if (chartRef.current) {
      tooltipEl.style.opacity = "1"
      tooltipEl.style.position = "absolute"

      tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
      tooltipEl.style.fontSize = tooltipModel.bodyFontSize + "px"
      tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
      tooltipEl.style.padding = tooltipModel.yPadding + "px " + tooltipModel.xPadding + "px"
      tooltipEl.style.pointerEvents = "none"

      const chartRect = chartRef.current.chartInstance.canvas!.getBoundingClientRect()
      const caretEl = document.getElementById("chartjs-tooltip-caret")!

      const xOrigin = chartRect.left + window.pageXOffset + tooltipModel.caretX
      const yOrigin = chartRect.top + window.pageYOffset + tooltipModel.caretY

      const caretXOffset = 15
      const margin = 10

      const tooltipRect = tooltipEl.getBoundingClientRect()

      const fitsDown = yOrigin + caretSize + tooltipRect.height + margin <= window.innerHeight
      const fitsUp = yOrigin - caretSize - tooltipRect.height - margin >= 0
      const fitsRight = xOrigin - caretXOffset + tooltipRect.width + margin <= window.innerWidth
      const fitsLeft = xOrigin + caretXOffset - tooltipRect.width - margin >= 0

      if (fitsDown || (!fitsUp && !fitsDown)) {
        tooltipEl.style.top = yOrigin + caretSize + "px"
        tooltipEl.style.bottom = ""
        caretEl.style.bottom = "100%"
        caretEl.style.top = ""
        caretEl.style.borderBottomColor = "rgba(0,0,0,0.8)"
        caretEl.style.borderTopColor = "transparent"
      } else {
        tooltipEl.style.bottom = window.innerHeight - yOrigin + caretSize + "px"
        tooltipEl.style.top = ""
        caretEl.style.top = "100%"
        caretEl.style.bottom = ""
        caretEl.style.borderTopColor = "rgba(0,0,0,0.8)"
        caretEl.style.borderBottomColor = "transparent"
      }

      if (fitsRight || (!fitsLeft && !fitsRight)) {
        tooltipEl.style.left = xOrigin - caretXOffset - caretSize + "px"
        tooltipEl.style.right = ""
        caretEl.style.left = caretXOffset + "px"
        caretEl.style.right = ""
      } else {
        tooltipEl.style.right = window.innerWidth - xOrigin - caretXOffset - caretSize + "px"
        tooltipEl.style.left = ""
        caretEl.style.right = caretXOffset + "px"
        caretEl.style.left = ""
      }
    }
  }
}

import React from "react"
import Tippy, { TippyProps } from "@tippyjs/react"

import "./ErrorTippy.scss"

export type ErrorTippyProps = TippyProps

export default function ErrorTippy(props: ErrorTippyProps) {
  return <Tippy offset={[0, 3]} placement="bottom" theme="error" {...props} />
}

import React from "react"
import classNames from "classnames"
import { SegmentExportDestination } from "resources/exportDestination/exportDestinationTypes"
import { Source } from "resources/dataSource/dataSourceTypes"
import { iconsMapping } from "./iconsMapping"

type SrcDstIconProps = {
  white?: boolean
  className?: string
} & (
  | {
      iconName: string
    }
  | {
      source: Source
    }
  | {
      destination: SegmentExportDestination
    }
)

export function getIconSrc(props: SrcDstIconProps) {
  let candidateNames: string[] = []

  if ("iconName" in props) {
    candidateNames = [props.iconName]
  }
  if ("source" in props) {
    candidateNames = [
      props.source.frontend_settings?.icon,
      props.source.type.toLowerCase(),
      props.source.frontend_settings?.alt_icon,
    ]
  }
  if ("destination" in props) {
    candidateNames = [
      props.destination.icon.toLowerCase(),
      props.destination.frontend_settings?.alt_icon,
    ]
  }

  return candidateNames
    .filter(Boolean)
    .map(name => name.replace(/\.png$/, ""))
    .concat("dummy")
    .map(name => (props.white ? `white_${name}` : name))
    .map(name => iconsMapping[name as keyof typeof iconsMapping])
    .find(Boolean)
}

export default function SrcDstIcon(props: SrcDstIconProps) {
  return <img src={getIconSrc(props)} alt="icon" className={classNames(props.className)} />
}

export type TemplateTag =
  | "Personalized content"
  | "Target audience"
  | "Pop-up web banner"
  | "Promo code list"
  | "Promo code API"
  | "Custom attributes"
  | "Meiro API"

export const templateTagColors: Partial<Record<TemplateTag, string>> = {
  "Personalized content": "#5FC4BE",
  "Pop-up web banner": "#739943",
  "Target audience": "#B85888",
  "Custom attributes": "#E05252",
  "Meiro API": "#E88C30",
  "Promo code list": "#556CDB",
  "Promo code API": "#CCAC00",
}

import { PopupWebBanner } from "./popupWBTypes"

export const getImgSrcFromHtml = (
  webBanner?: PopupWebBanner,
  type: "regular" | "minimized" = "regular",
) => {
  if (
    !webBanner ||
    (type === "regular" && webBanner.settings.content.type === "html") ||
    (type === "minimized" && webBanner.settings.minimized_content.type === "html")
  ) {
    return ""
  }

  const regex = /<img.*?src='(.*?)'/
  if (type === "minimized") {
    const matches = regex.exec(webBanner.settings.minimized_content.html ?? "")
    if (matches && matches.length > 0) {
      return matches[1]
    }
  } else {
    const matches = regex.exec(webBanner.settings.content.html)
    if (matches && matches.length > 0) {
      return matches[1]
    }
  }
  return ""
}

import requestFactory from "api/request"
import {
  SenderCreatePayload,
  SenderModifyPayload,
  SenderResponse,
  SendersListResponse,
} from "./emailSendersTypes"

const senders = {
  list(): Promise<SendersListResponse> {
    return requestFactory("GET", "/channels/emails/email_addresses")
  },
  create(data: SenderCreatePayload): Promise<SenderResponse> {
    return requestFactory("POST", "/channels/emails/email_addresses", data)
  },
  modify(email: string, data: SenderModifyPayload): Promise<SenderResponse> {
    return requestFactory("PATCH", `/channels/emails/email_addresses/${email}`, data)
  },
  delete(email: string): Promise<void> {
    return requestFactory("DELETE", `/channels/emails/email_addresses/${email}`)
  },
  resendVerification(email: string): Promise<void> {
    return requestFactory("POST", "/channels/emails/email_addresses/verify", { email })
  },
}

export default senders

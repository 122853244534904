import { isAndOrCondition } from "components/ConditionBuilder/utils"
import {
  isStringArrayCondition,
  isStringSingleValueCondition,
} from "components/UI/components/WebBannerCondition/utils"
import { LeafCondition, WBConditionObject } from "resources/webBanner/webBannerConditionTypes"

const replaceConditionInConditionTree = (
  tree: WBConditionObject,
  f: (condition: LeafCondition) => LeafCondition,
): WBConditionObject =>
  isAndOrCondition(tree)
    ? {
        operator: tree.operator,
        operands: tree.operands.map(operand => replaceConditionInConditionTree(operand, f)),
      }
    : tree.operator === "negation"
    ? { operator: tree.operator, operand: f(tree.operand) }
    : f(tree)

const replaceConditionInWebBanner = <
  T extends { settings?: { condition: WBConditionObject | null } },
>(
  webBanner: T,
  f: (condition: LeafCondition) => LeafCondition,
) => {
  if (!webBanner.settings?.condition) return webBanner

  return {
    ...webBanner,
    settings: {
      ...webBanner.settings,
      condition: replaceConditionInConditionTree(webBanner.settings.condition, f),
    },
  }
}

const trimStringCondition = (condition: LeafCondition): LeafCondition => {
  if (isStringSingleValueCondition(condition))
    return { ...condition, value: condition.value?.trim() ?? null }

  if (isStringArrayCondition(condition)) {
    return { ...condition, values: condition.values?.map(value => value.trim()) ?? null }
  }

  return condition
}

export const trimWebBannerValue = <
  T extends { settings?: { condition: WBConditionObject | null } },
>(
  webBanner: T,
) => replaceConditionInWebBanner(webBanner, trimStringCondition)

import React from "react"
import Button from "components/UI/elements/Button/Button"
import { getRoutePath } from "routes"
import EventForm from "../components/EventForm/EventForm"
import styles from "./EventCreate.module.scss"
import Page from "components/UI/Page/Page"
import { useCreateEvent } from "resources/event/eventQueries"
import { EventCreatePayload, EventFull, EventPayload } from "resources/event/eventTypes"
import { useHistory, useLocation } from "react-router-dom"

export default function EventCreate() {
  const history = useHistory()
  const location = useLocation<{ event: EventFull }>()
  const createMutation = useCreateEvent()
  const createEvent = (data: EventPayload) =>
    createMutation.mutate(
      { data: data as EventCreatePayload },
      {
        onSuccess() {
          history.push(getRoutePath("administration.events"))
        },
      },
    )

  return (
    <Page
      title="Create event"
      headerContent={
        <div className={styles.headerButtons}>
          <Button
            color="grey"
            variant="outlined"
            onClick={() => {
              history.push(getRoutePath("administration.events"))
            }}
          >
            Cancel
          </Button>
          <Button loading={createMutation.isLoading} type="submit" form="event-form">
            Save
          </Button>
        </div>
      }
    >
      <EventForm onSubmit={createEvent} isCreate event={location.state?.event} />
    </Page>
  )
}

import React from "react"
import classNames from "classnames"

import { toStrPercentage } from "../formatters"
import { Color } from "../types"
import { abbreviateNumber } from "helpers/number.helper"

import styles from "./ExpandedInsightRow.module.scss"

type ExpandedInsightRowProps = {
  color: Color
  known: number
  name: string
  percentage: number
  title: string
  total: number
  primary?: boolean
}

export default function ExpandedInsightRow({
  color,
  known,
  name,
  percentage,
  title,
  total,
  primary = true,
}: ExpandedInsightRowProps) {
  return (
    <div
      className={classNames(
        styles.row,
        { [styles.primary]: primary, [styles.secondary]: !primary },
        styles[color],
      )}
    >
      <div
        className={classNames(
          styles.nameWrapper,
          {
            [styles.primary]: primary,
            [styles.secondary]: !primary,
          },
          styles[color],
        )}
      >
        <div
          className={classNames(
            styles.dot,
            { [styles.primary]: primary, [styles.secondary]: !primary },
            styles[color],
          )}
        />
        <span className={styles.name}>
          <b>{name}</b>
        </span>
      </div>
      <span>
        <b>{title}</b> known for{" "}
        <b>{`${abbreviateNumber(known)} (${toStrPercentage(percentage)})`}</b> out of{" "}
        <b>{abbreviateNumber(total)}</b>
      </span>
    </div>
  )
}

import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import { PromoCodesList, PromoCodesListRequestPayload } from "./promoCodesListTypes"

const promoCodesList = {
  listAll(): Promise<PromoCodesList[]> {
    return fetchAll({
      fetchFn: (offset, limit) => requestFactory("get", "/promo_codes_lists", { offset, limit }),
      key: "promo_codes_lists",
    })
  },
  create(data: PromoCodesListRequestPayload): Promise<{ promo_codes_list: PromoCodesList }> {
    return requestFactory("post", "/promo_codes_lists", data)
  },
  modify(
    id: PromoCodesList["id"],
    data: PromoCodesListRequestPayload,
  ): Promise<{ promo_codes_list: PromoCodesList }> {
    return requestFactory("patch", `/promo_codes_lists/${id}`, data)
  },
  delete(id: PromoCodesList["id"]): Promise<void> {
    return requestFactory("delete", `/promo_codes_lists/${id}`)
  },
  uploadFile(
    id: PromoCodesList["id"],
    formData: FormData,
  ): Promise<{ promo_codes_list: PromoCodesList }> {
    return requestFactory(
      "post",
      `/promo_codes_lists/${id}`,
      formData,
      false,
      true,
      "",
      false,
      false,
      { "Content-Type": "multipart/form-data" },
    )
  },
}

export default promoCodesList

import { ConditionTree } from "../../types/conditionTree"
import { isAndOrCondition } from "./utils"

function areAllLeafsNull<U>(conditionTree: ConditionTree<U>): boolean {
  return isAndOrCondition(conditionTree)
    ? conditionTree.operands.every(areAllLeafsNull)
    : conditionTree === null
}

export function makeConditionTreeValidator<T, U>(
  validateCondition: (condition: T) => U | null,
): (conditionTree: ConditionTree<T> | null) => ConditionTree<U | null> {
  function validateConditionTree(conditionTree: ConditionTree<T>): ConditionTree<U | null> {
    return isAndOrCondition(conditionTree)
      ? {
          operator: conditionTree.operator,
          operands: conditionTree.operands.map(validateConditionTree),
        }
      : (validateCondition(conditionTree) as ConditionTree<U | null>)
  }

  return function (conditionTree: ConditionTree<T> | null) {
    if (!conditionTree) {
      return null
    }

    const validationResult = validateConditionTree(conditionTree)

    return areAllLeafsNull(validationResult) ? null : validationResult
  }
}

import Tag from "components/UI/elements/Tag"
import { AttributesMapById } from "resources/attribute/attributeTypes"
import { getAttributeNamesUsedInString } from "./utils"
import styles from "./UsedAttributesList.module.scss"
import classNames from "classnames"

type UsedAttributesListProps = {
  text: string
  attributesMapById: AttributesMapById
  className?: string
}

export default function UsedAttributesList({
  text,
  attributesMapById,
  className,
}: UsedAttributesListProps) {
  if (!text) return null

  const usedAttributeNames = getAttributeNamesUsedInString(text, attributesMapById)
  if (usedAttributeNames.length === 0) return null

  return (
    <div className={classNames(styles.usedAttributesList, className)}>
      <div className={styles.label}>Used attributes:</div>
      <div>
        {usedAttributeNames.map(attributeName => (
          <Tag className={styles.attributeName} key={attributeName} color="white">
            {attributeName}
          </Tag>
        ))}
      </div>
    </div>
  )
}

import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import { Source, SourceCreatePayload, SourceModifyPayload } from "./dataSourceTypes"

const dataSource = {
  listAll(): Promise<Source[]> {
    return fetchAll({
      fetchFn: (offset, limit) =>
        requestFactory("get", "/customers/sources", {
          offset,
          limit,
          order_by: "name",
          order_dir: "ASC",
          show_hidden: 1,
        }),
      key: "sources",
    })
  },
  create(data: SourceCreatePayload): Promise<{ source: Source }> {
    return requestFactory("post", "/customers/sources", data)
  },
  modify(id: Source["id"], data: SourceModifyPayload): Promise<{ source: Source }> {
    return requestFactory("patch", `/customers/sources/${id}`, data)
  },
}

export default dataSource

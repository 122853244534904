import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import { Label, LabelPayload } from "./attributeLabelTypes"

const label = {
  listAll(): Promise<Label[]> {
    return fetchAll({
      fetchFn: (offset, limit) =>
        requestFactory("get", "/cdm/tags", { offset, limit, order_by: "name", order_dir: "ASC" }),
      key: "tags",
    })
  },
  create(data: LabelPayload): Promise<{ tag: Label }> {
    return requestFactory("post", "/cdm/tags", data)
  },
  modify(id: Label["id"], data: LabelPayload): Promise<{ tag: Label }> {
    return requestFactory("patch", `/cdm/tags/${id}`, data)
  },
  delete(id: Label["id"]): Promise<void> {
    return requestFactory("delete", `/cdm/tags/${id}`)
  },
}

export default label

import notification from "./notification/notificationEndpoints"
import embeddedWebBanner from "./webBanner/embeddedWebBanner/embeddedWBEndpoints"
import popupWebBanner from "./webBanner/popupWebBanner/popupWBEndpoints"
import promoCodesList from "./promoCodesList/promoCodesListEndpoints"
import dataSource from "./dataSource/dataSourceEndpoints"
import tag from "./tag/tagEndpoints"
import report from "./report/reportEndpoints"
import exportDestination from "./exportDestination/exportDestinationEndpoints"
import {
  segment,
  featuredSegment,
  smartSegment,
  lookalikeSegment,
} from "./segment/segment/segmentEndpoints"
import exportEndpoints from "./segment/segmentExport/segmentExportEndpoints"
import note from "./segment/segmentNote/segmentNoteEndpoints"
import trash from "./trash/trashEndpoints"
import userRole from "./userRole/userRoleEndpoints"
import user from "./user/userEndpoints"
import attribute from "./attribute/attributeEndpoints"
import event from "./event/eventEndpoints"
import customerDestination from "./customer/destination/customerDestinationEndpoints"
import customerEvent from "./customer/event/customerEventEndpoints"
import customerSegment from "./customer/segment/customerSegmentEndpoints"
import globalSettings from "./globalSettings/globalSettingsEndpoints"
import mobilePushNotification from "./mobilePushNotification/mobilePushNotificationEndpoints"
import systemInfo from "./systemInfo/systemInfoEndpoints"
import channel from "./channel/channelEndpoints"
import email from "./email/emailEndpoints"
import { customersPerAttribute } from "./stats/customersPerAttribute"
import image from "./image/imageEndpoints"
import identityGraph from "./customer/identityGraph/identityGraphEndpoints"
import customerAttribute from "./customer/attribute/customerAttributeEndpoints"
import segmentPermission from "./segmentPermission/segmentPermissionEndpoints"
import label from "./attributeLabel/attributeLabelEndpoints"
import stitchingCategory from "./stitchingCategory/stitchingCategoryEndpoints"
import workspace from "./workspace/workspaceEndpoints"
import customers from "./customer/search/customerSearchEndpoints"
import attributeAggregation from "./attributeAggregation/attributeAggregationEndpoints"
import lookalike from "./lookalike/lookalikeEndpoints"
import { usersActivity } from "./usersActivity/usersActivityEndpoints"
import { tools } from "./tools/tools"
import { attributeValueCounts } from "./stats/attributeValueCounts"
import funnelGroup from "./funnelGroup/funnelGroupEndpoints"
import funnelGroupPermission from "./funnelGroupPermission/funnelGroupPermissionEndpoints"
import funnelChart from "./funnelChart/funnelChartEndpoints"

const api = {
  segmentPermission,
  attribute,
  event,
  customers,
  customerAttribute,
  customerDestination,
  customerEvent,
  customerSegment,
  notification,
  embeddedWebBanner,
  popupWebBanner,
  promoCodesList,
  dataSource,
  tag,
  report,
  exportDestination,
  segment: {
    ...segment,
    export: exportEndpoints,
    note,
  },
  featuredSegment,
  smartSegment,
  lookalikeSegment,
  trash,
  userRole,
  user,
  globalSettings,
  mobilePushNotification,
  systemInfo,
  channel,
  email,
  customersPerAttribute,
  image,
  identityGraph,
  label,
  stitchingCategory,
  workspace,
  attributeAggregation,
  lookalike,
  usersActivity,
  tools,
  attributeValueCounts,
  funnelGroup,
  funnelGroupPermission,
  funnelChart,
}

export default api

import Button from "components/UI/elements/Button/Button"
import { useState } from "react"
import styles from "./ChatGPT.module.scss"
import ChatModal from "./ChatModal/ChatModal"

export default function ChatGPT() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Button
        color="primary"
        icon={isOpen ? "times" : "wand-magic-sparkles"}
        onClick={() => setIsOpen(prevIsOpen => !prevIsOpen)}
        className={styles.toggleButton}
      >
        {isOpen ? "Close" : "Meiro AI Assistant"}
      </Button>
      <ChatModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
    </>
  )
}

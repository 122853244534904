import AuthorizedRoute from "components/AuthorizedRoute/AuthorizedRoute"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import Attributes from "./Attributes/Attributes"
import Events from "./Events/Events"
import Sources from "./Sources/Sources"
import { useHasAccess } from "resources/user/currentUserQueries"
import Destinations from "./Destinations/Destinations"

export default function Data() {
  const hasAccess = useHasAccess()

  function redirectToAllowedDataTab() {
    if (hasAccess.data.attributes) {
      return <Redirect to={getRoutePath("data.attributes")} />
    }

    if (hasAccess.data.events) {
      return <Redirect to={getRoutePath("data.events")} />
    }

    if (hasAccess.data.sourcesAndDestinations) {
      return <Redirect to={getRoutePath("data.sources")} />
    }
  }

  return (
    <Switch>
      <Route path={getRoutePath("data")} exact render={redirectToAllowedDataTab} />

      <AuthorizedRoute
        hasAccess={hasAccess.data.attributes}
        path={getRoutePath("data.attributes")}
        component={Attributes}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.data.events}
        path={getRoutePath("data.events")}
        component={Events}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.data.sourcesAndDestinations}
        path={getRoutePath("data.sources")}
        component={Sources}
      />
      <AuthorizedRoute
        hasAccess={hasAccess.data.sourcesAndDestinations}
        path={getRoutePath("data.destinations")}
        component={Destinations}
      />
    </Switch>
  )
}

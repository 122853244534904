import { useQuery } from "@tanstack/react-query"
import { api } from "api"
import { isNil, map } from "ramda"
import { useFetchGlobalSettings } from "resources/globalSettings/globalSettingsQueries"
import { SelectOption } from "types/util"
import { MIWorkspace } from "./workspaceTypes"

export function useFetchWorkspaceOptions() {
  const { data: globalSettings } = useFetchGlobalSettings()
  const isMiApiSet = !isNil(globalSettings?.["mi_api"]?.value)

  return useQuery(["workspace", "all"], api.workspace.listAll, {
    select: map<MIWorkspace, SelectOption<MIWorkspace["id"]>>(({ id, name }) => ({
      label: name,
      value: id,
    })),
    enabled: isMiApiSet,
  })
}

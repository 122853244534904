import React from "react"

import Modal from "components/UI/elements/Modal"
import Button from "components/UI/elements/Button/Button"

import styles from "./IgnoreFrequencyCapModal.module.scss"

type IgnoreFrequencyCapModalProps = {
  entity: string
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export default function IgnoreFrequencyCapModal({
  entity,
  open,
  onClose,
  onConfirm,
}: IgnoreFrequencyCapModalProps) {
  return (
    <Modal open={open} title="Ignore global frequency cap" handleClose={onClose}>
      <p className={styles.text}>
        {`This ${entity} will now ignore global settings for frequency cap and will be displayed over its
        limit. Are you sure you want to proceed?`}
      </p>
      <div className={styles.buttons}>
        <Button color="grey" size="md" variant="text" onClick={_ => onClose()}>
          Cancel
        </Button>
        <Button color="green" size="md" onClick={_ => onConfirm()}>
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

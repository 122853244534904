import Chart from "chart.js"
import { hexToRgba } from "helpers/chartjs.helper"

function getBoxWidth(labelOpts, fontSize) {
  return labelOpts.usePointStyle ? fontSize * Math.SQRT2 : labelOpts.boxWidth
}

Chart.NewLegend = Chart.Legend.extend({
  draw: function () {
    var me = this
    var opts = me.options
    var labelOpts = opts.labels
    var globalDefault = Chart.defaults.global
    var lineDefault = globalDefault.elements.line
    var legendWidth = me.width
    var lineWidths = me.lineWidths

    if (opts.display) {
      var ctx = me.ctx
      var valueOrDefault = Chart.helpers.valueOrDefault
      var fontColor = valueOrDefault(labelOpts.fontColor, globalDefault.defaultFontColor)
      var fontSize = valueOrDefault(labelOpts.fontSize, globalDefault.defaultFontSize)
      var fontStyle = valueOrDefault(labelOpts.fontStyle, globalDefault.defaultFontStyle)
      var fontFamily = valueOrDefault(labelOpts.fontFamily, globalDefault.defaultFontFamily)
      var labelFont = Chart.helpers.fontString(fontSize, fontStyle, fontFamily)
      var cursor

      // Canvas setup
      ctx.textAlign = "left"
      ctx.textBaseline = "middle"
      ctx.lineWidth = 0.5
      ctx.strokeStyle = fontColor // for strikethrough effect
      ctx.fillStyle = fontColor // render in correct colour
      ctx.font = labelFont

      var boxWidth = getBoxWidth(labelOpts, fontSize)
      var hitboxes = me.legendHitBoxes

      // current position
      var drawLegendBox = function (x, y, legendItem) {
        if (isNaN(boxWidth) || boxWidth <= 0) {
          return
        }

        // Set the ctx for the box
        ctx.save()

        ctx.fillStyle = valueOrDefault(legendItem.fillStyle, globalDefault.defaultColor)
        ctx.lineCap = valueOrDefault(legendItem.lineCap, lineDefault.borderCapStyle)
        ctx.lineDashOffset = valueOrDefault(legendItem.lineDashOffset, lineDefault.borderDashOffset)
        ctx.lineJoin = valueOrDefault(legendItem.lineJoin, lineDefault.borderJoinStyle)
        ctx.lineWidth = valueOrDefault(legendItem.lineWidth, lineDefault.borderWidth)
        ctx.strokeStyle = valueOrDefault(legendItem.strokeStyle, globalDefault.defaultColor)
        var isLineWidthZero = valueOrDefault(legendItem.lineWidth, lineDefault.borderWidth) === 0

        if (ctx.setLineDash) {
          // IE 9 and 10 do not support line dash
          ctx.setLineDash(valueOrDefault(legendItem.lineDash, lineDefault.borderDash))
        }

        if (opts.labels && opts.labels.usePointStyle) {
          // Recalculate x and y for drawPoint() because its expecting
          // x and y to be center of figure (instead of top left)
          var radius = (fontSize * Math.SQRT2) / 2
          var offSet = radius / Math.SQRT2
          var centerX = x + offSet
          var centerY = y + offSet

          var strokeStyle = legendItem.hidden
            ? "#dddddd"
            : ctx.strokeStyle !== "#ffffff"
            ? ctx.strokeStyle
            : ctx.fillStyle
          var fillStyle = legendItem.hidden ? "#dddddd" : ctx.fillStyle

          var tmpLineWidth = ctx.lineWidth
          var tmpFillStyle = ctx.fillStyle
          var tmpStrokeStyle = ctx.strokeStyle

          ctx.fillStyle = hexToRgba(fillStyle, 0.5)
          ctx.strokeStyle = strokeStyle
          ctx.lineWidth = 2.0
          // Draw pointStyle as legend symbol
          Chart.helpers.canvas.drawPoint(ctx, legendItem.pointStyle, radius, centerX, centerY)
          ctx.fillStyle = tmpFillStyle
          ctx.strokeStyle = tmpStrokeStyle
          ctx.lineWidth = tmpLineWidth
        } else {
          // Draw box as legend symbol
          if (!isLineWidthZero) {
            ctx.strokeRect(x, y, boxWidth, fontSize)
          }
          ctx.fillRect(x, y, boxWidth, fontSize)
        }

        ctx.restore()
      }
      var fillText = function (x, y, legendItem, textWidth) {
        var halfFontSize = fontSize / 2
        var xLeft = boxWidth + halfFontSize + x
        var yMiddle = y + halfFontSize

        if (legendItem.hidden) {
          // Strikethrough the text if hidden
          ctx.fillStyle = Chart.helpers.color(fontColor).lighten(1).rgbString()
        }
        ctx.fillText(legendItem.text, xLeft, yMiddle)

        // reset fillStyle back
        ctx.fillStyle = fontColor
      }

      // Horizontal
      var isHorizontal = me.isHorizontal()
      if (isHorizontal) {
        cursor = {
          x: me.left + (legendWidth - lineWidths[0]) / 2,
          y: me.top + labelOpts.padding,
          line: 0,
        }
      } else {
        cursor = {
          x: me.left + labelOpts.padding,
          y: me.top + labelOpts.padding,
          line: 0,
        }
      }

      var itemHeight = fontSize + labelOpts.padding
      Chart.helpers.each(me.legendItems, function (legendItem, i) {
        var textWidth = ctx.measureText(legendItem.text).width
        var width = boxWidth + fontSize / 2 + textWidth
        var x = cursor.x
        var y = cursor.y

        if (isHorizontal) {
          if (x + width >= legendWidth) {
            y = cursor.y += itemHeight
            cursor.line++
            x = cursor.x = me.left + (legendWidth - lineWidths[cursor.line]) / 2
          }
        } else if (y + itemHeight > me.bottom) {
          x = cursor.x = x + me.columnWidths[cursor.line] + labelOpts.padding
          y = cursor.y = me.top + labelOpts.padding
          cursor.line++
        }

        drawLegendBox(x, y, legendItem)

        hitboxes[i].left = x
        hitboxes[i].top = y

        // Fill the actual label
        fillText(x, y, legendItem, textWidth)

        if (isHorizontal) {
          cursor.x += width + labelOpts.padding
        } else {
          cursor.y += itemHeight
        }
      })
    }
  },
})

function createNewLegendAndAttach(chartInstance, legendOpts) {
  var legend = new Chart.NewLegend({
    ctx: chartInstance.chart.ctx,
    options: legendOpts,
    chart: chartInstance,
  })

  if (chartInstance.legend) {
    Chart.layoutService.removeBox(chartInstance, chartInstance.legend)
    delete chartInstance.newLegend
  }

  Chart.layoutService.configure(chartInstance, legend, legendOpts)
  Chart.layoutService.addBox(chartInstance, legend)
  chartInstance.newLegend = legend
}

// Register the legend plugin
Chart.plugins.register({
  beforeInit: function (chartInstance) {
    var legendOpts = chartInstance.options.legend

    if (legendOpts) {
      createNewLegendAndAttach(chartInstance, legendOpts)
    }
  },
  beforeUpdate: function (chartInstance) {
    var legendOpts = chartInstance.options.legend

    if (legendOpts) {
      legendOpts = Chart.helpers.configMerge(Chart.defaults.global.legend, legendOpts)

      if (chartInstance.newLegend) {
        chartInstance.newLegend.options = legendOpts
      } else {
        createNewLegendAndAttach(chartInstance, legendOpts)
      }
    } else {
      Chart.layoutService.removeBox(chartInstance, chartInstance.newLegend)
      delete chartInstance.newLegend
    }
  },
  afterEvent: function (chartInstance, e) {
    var legend = chartInstance.newLegend
    if (legend) {
      legend.handleEvent(e)
    }
  },
})

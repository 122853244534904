import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import {
  EventCreatePayload,
  Event,
  EventModifyPayload,
  EventFull,
  EventDryRunPayload,
  EventDryRunResponse,
} from "./eventTypes"

const event = {
  listAll(): Promise<EventFull[]> {
    return fetchAll({
      fetchFn: (offset, limit) =>
        requestFactory("get", "/events", {
          offset,
          limit,
          order_by: "name",
          order_dir: "ASC",
          show_hidden: 1,
          cascade_is_hidden: 1,
          load_full_structure: 1,
        }),
      key: "events",
    })
  },
  create(data: EventCreatePayload): Promise<{ event: EventFull }> {
    return requestFactory("post", "/events?load_full_structure=1&cascade_is_hidden=1", data)
  },
  modify(id: Event["id"], data: EventModifyPayload): Promise<{ event: EventFull }> {
    return requestFactory("patch", `/events/${id}?load_full_structure=1&cascade_is_hidden=1`, data)
  },
  autoload: {
    dryRun(data: EventDryRunPayload): Promise<EventDryRunResponse> {
      return requestFactory("post", "/events/dry_run_load", data)
    },
    endpoints(): Promise<string[]> {
      return requestFactory("get", "/events/endpoints")
    },
  },
}

export default event

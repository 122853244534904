import { Controller, DeepPartial, FormProvider, useForm } from "react-hook-form"
import styles from "./FunnelChartForm.module.scss"
import { FunnelChartCreatePayload } from "resources/funnelChart/funnelChartTypes"
import SelectField from "components/UI/elements/SelectField"
import ColorRadioGroup from "components/UI/components/ColorRadioGroup"
import { OPTION_GROUP_COLORS } from "sharedConstants"
import { useFetchAttributesMap } from "resources/attribute/attributeQueries"
import { getCompoundAttributeSubAttribute } from "resources/attribute/compoundAttributeUtils"
import { required } from "helpers/validators.helper"
import Paper from "components/UI/elements/Paper"
import TextInput from "components/UI/elements/TextInput/TextInput"
import AttributesList from "../AttributesList/AttributesList"
import { uniq } from "ramda"
import { getFunctionOptionsByDataType } from "../funnelsUtils"
import { AttributeDataType } from "resources/attribute/attributeTypes"

export type FunnelChartFormValues = FunnelChartCreatePayload

type FunnelChartFormProps = {
  onSubmit: (data: FunnelChartFormValues) => Promise<any>
  initialValues: DeepPartial<FunnelChartFormValues>
  isEditable: boolean
}

export default function FunnelChartForm({
  initialValues,
  onSubmit,
  isEditable,
}: FunnelChartFormProps) {
  const methods = useForm<FunnelChartFormValues>({
    defaultValues: initialValues,
  })
  const {
    handleSubmit,
    register,
    reset,
    control,
    watch,
    formState: { errors },
  } = methods

  async function submit(data: FunnelChartFormValues) {
    if (!data.description) {
      data.description = undefined
    }
    await onSubmit(data)
    reset(data)
  }

  const steps = watch("data.steps")
  const { data: attributesMap = {} } = useFetchAttributesMap()
  const selectedDataTypes = steps.map(step => {
    if (!step.id || !step.data_dimension_id) {
      return undefined
    }
    const attribute = attributesMap[step.id]
    if (!attribute) {
      return undefined
    }
    const dimension = getCompoundAttributeSubAttribute(step.data_dimension_id, attribute.data_type)
    return dimension.data_type
  })
  const selectedDataType: AttributeDataType | undefined =
    uniq(selectedDataTypes).length === 1 ? selectedDataTypes[0] : undefined

  const functionOptions = getFunctionOptionsByDataType(selectedDataType)

  return (
    <form id="chartForm" onSubmit={handleSubmit(submit)} className={styles.form}>
      <Paper className={styles.headPaper}>
        <TextInput
          {...register("name", { validate: { required } })}
          label="Name"
          error={errors.name?.message}
          disabled={!isEditable}
        />
        <TextInput
          {...register("description")}
          label="Description (optional)"
          disabled={!isEditable}
        />
      </Paper>
      <Paper className={styles.attributePaper}>
        <div>
          <h3>Attributes</h3>
          <p className={styles.description}>
            Each attribute represents a step of the funnel. Select 2–10 compound attributes with a{" "}
            <code>date</code>/<code>datetime</code> dimension for the{" "}
            <strong>time dimension</strong> and a <code>string</code>, <code>int</code>, or{" "}
            <code>float</code> dimension for the <strong>data dimension</strong>. All data
            dimensions must be of the same type.
          </p>
        </div>
        <FormProvider {...methods}>
          <AttributesList isEditable={isEditable} />
        </FormProvider>
      </Paper>
      <Paper className={styles.paper}>
        <div>
          <h3>Aggregation function</h3>
          <p className={styles.description}>
            The available aggregation functions are <strong>Count</strong> and{" "}
            <strong>Unique counts</strong> for <code>string</code> data dimensions and{" "}
            <strong>Count</strong>, <strong>Average</strong>, and <strong>Sum</strong> for{" "}
            <code>int</code> and <code>float</code> data dimensions.
          </p>
        </div>
        <Controller
          control={control}
          rules={{
            validate: {
              required,
              validFunction(value) {
                if (selectedDataType === "string") {
                  return ["CARDINALITY", "COUNT"].includes(value)
                    ? undefined
                    : "Invalid function for string data dimension"
                }
                if (selectedDataType === "int" || selectedDataType === "float") {
                  return ["SUM", "AVERAGE", "COUNT"].includes(value)
                    ? undefined
                    : "Invalid function for int or float data dimension"
                }
              },
            },
          }}
          name="function"
          render={({ field }) => (
            <SelectField
              input={field}
              options={functionOptions}
              isSimpleValue
              error={errors.function?.message}
              disabled={!isEditable}
            />
          )}
        />
      </Paper>
      <Paper className={styles.paper}>
        <h3>Chart color</h3>
        <div className={styles.colorPickerWrapper}>
          <Controller
            control={control}
            name="frontend_settings.color"
            render={({ field }) => (
              <ColorRadioGroup
                input={field}
                meta={{ touched: true, error: "" }}
                colors={OPTION_GROUP_COLORS}
                disabled={!isEditable}
              />
            )}
          />
        </div>
      </Paper>
    </form>
  )
}

import create from "zustand"

import { RegularSegmentSelectionState } from "resources/segment/segment/segmentTypes"
import { uniq } from "ramda"

const defaultToggleState: Pick<
  RegularSegmentSelectionState,
  "showMy" | "showSharedWithMe" | "showForeign"
> = { showMy: true, showSharedWithMe: true, showForeign: true }

export const useStore = create<RegularSegmentSelectionState>(set => ({
  orderBy: "last_export",
  orderDir: "DESC",
  searchTerm: "",
  selectedTags: [],
  showMy: true,
  showSharedWithMe: true,
  showForeign: true,
  setSort: orderBy =>
    set(state => ({
      orderDir: state.orderBy === orderBy && state.orderDir === "ASC" ? "DESC" : "ASC",
      orderBy: orderBy,
    })),
  setSearchTerm: searchTerm => set({ searchTerm }),
  addTag: tag => set(state => ({ selectedTags: uniq(state.selectedTags.concat(tag)) })),
  removeTag: tag => set(state => ({ selectedTags: state.selectedTags.filter(t => t !== tag) })),
  setTag: tag => set(() => ({ selectedTags: [tag] })),
  toggleShowMy: () =>
    set(state => {
      if ((state.showMy && state.showSharedWithMe && state.showForeign) || !state.showMy)
        return {
          showMy: true,
          showSharedWithMe: false,
          showForeign: false,
        }
      else return defaultToggleState
    }),
  toggleShowSharedWithMe: () =>
    set(state => {
      if ((state.showMy && state.showSharedWithMe && state.showForeign) || !state.showSharedWithMe)
        return {
          showSharedWithMe: true,
          showMy: false,
          showForeign: false,
        }
      else return defaultToggleState
    }),
  toggleShowForeign: () =>
    set(state => {
      if ((state.showMy && state.showSharedWithMe && state.showForeign) || !state.showForeign)
        return {
          showForeign: true,
          showMy: false,
          showSharedWithMe: false,
        }
      else return defaultToggleState
    }),
}))

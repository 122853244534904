import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import { MIWorkspace } from "./workspaceTypes"

const workspace = {
  listAll(): Promise<MIWorkspace[]> {
    return fetchAll({
      fetchFn: (offset, limit) => requestFactory("get", "/mi/workspaces", { offset, limit }),
      key: "workspaces",
    })
  },
}

export default workspace

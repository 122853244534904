import React from "react"
import classNames from "classnames"

import ExpandedInsightConditionValue from "./ExpandedInsightConditionValue"
import { Color, DataType, ConditionValueType, InsightType } from "../types"
import { SEGMENT_ANALYTICS_FUNCTIONS } from "sharedConstants"

import styles from "./ExpandedInsightValue.module.scss"

type ExpandedInsightValueProps = {
  color: Color
  conditionValue: ConditionValueType
  dataType: DataType
  title: string
  type: InsightType
  value: string
  primary?: boolean
}

export default function ExpandedInsightValue({
  color,
  conditionValue,
  dataType,
  title,
  type,
  value,
  primary = true,
}: ExpandedInsightValueProps) {
  return (
    <div className={styles.valueWrapper}>
      <span
        className={classNames(
          styles.value,
          {
            [styles.primary]: primary,
            [styles.secondary]: !primary,
            [styles.small]: ["EARLIEST", "LATEST"].includes(type),
          },
          styles[color],
        )}
      >
        <b>{value}</b>
      </span>
      <div className={classNames(styles.description, { [styles.secondary]: !primary })}>
        <span>
          <b>
            {SEGMENT_ANALYTICS_FUNCTIONS[type].label}{" "}
            <ExpandedInsightConditionValue dataType={dataType} type={type} value={conditionValue} />
          </b>
        </span>
        <span>for {title}</span>
      </div>
    </div>
  )
}

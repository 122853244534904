import { useCallback } from "react"

const getContext = () => {
  const fragment: DocumentFragment = document.createDocumentFragment()
  const canvas: HTMLCanvasElement = document.createElement("canvas")

  fragment.appendChild(canvas)
  return canvas.getContext("2d") as CanvasRenderingContext2D
}

const getTextWidth = (currentText: string | string[], font: string) => {
  const context = getContext()
  context.font = font

  if (Array.isArray(currentText))
    return Math.max(...currentText.map(t => context.measureText(t).width))
  else {
    const metrics = context.measureText(currentText)
    return metrics.width
  }
}

const useCalcTextWidth = () =>
  useCallback(
    ({ text, font = "bold 11px arial" }: { text: string | string[]; font?: string }) =>
      getTextWidth(text, font),
    [],
  )

export default useCalcTextWidth

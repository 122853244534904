import requestFactory from "api/request"
import { Notification, NotificationListResponse } from "./notificationTypes"

const notification = {
  list(until_id?: Notification["id"], limit?: number): Promise<NotificationListResponse> {
    return requestFactory("get", "/notifications", { until_id, limit })
  },
  markAsRead(): Promise<void> {
    return requestFactory("patch", "/notifications")
  },
}

export default notification

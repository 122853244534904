import requestFactory from "api/request"
import {
  CustomerEventCountsResponse,
  CustomerEventListResponse,
  CustomerEventListRequestPayload,
  CustomerEventGroupListResponse,
  CustomerEventGroupListRequestPayload,
} from "./customerEventTypes"
import { Flag } from "types/util"

const customerEvent = {
  group: {
    list<T extends Flag = 0>({
      customer_entity_id,
      load_full_structure = 0 as T,
    }: CustomerEventGroupListRequestPayload): Promise<CustomerEventGroupListResponse<T>> {
      return requestFactory("get", `/customers/${customer_entity_id}/event_groups`, {
        load_full_structure,
      })
    },
  },
  list<T extends Flag = 0>({
    customer_entity_id,
    start_date,
    end_date,
    search_text,
    offset = 0,
    limit = 50,
    order_by = "id",
    order_dir = "DESC",
    load_full_structure = 0 as T,
    source_ids = [],
    event_ids = [],
    tag_ids = [],
  }: CustomerEventListRequestPayload): Promise<CustomerEventListResponse<T>> {
    return requestFactory("get", `/customers/${customer_entity_id}/events`, {
      start_date,
      end_date,
      search_text,
      offset,
      limit,
      order_by,
      order_dir,
      load_full_structure,
      source_ids,
      event_ids,
      tag_ids,
    })
  },
  counts(): Promise<CustomerEventCountsResponse> {
    return requestFactory("get", "/customers/events/counts")
  },
}

export default customerEvent

import React, { Fragment } from "react"
import classNames from "classnames"

import { isAndOrCondition } from "components/ConditionBuilder/utils"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { useFetchAttributeById } from "resources/attribute/attributeQueries"
import { AttributeFull } from "resources/attribute/attributeTypes"
import { getCompoundAttributeSubAttributes } from "resources/attribute/compoundAttributeUtils"
import {
  CompoundSegmentCondition,
  DimensionBranch,
  DimensionConditionObject,
  SegmentCondition,
  SimpleSegmentCondition,
} from "resources/segment/segment/segmentConditionsTypes"
import {
  isCompoundSegmentCondition,
  isDimensionBranch,
} from "resources/segment/segment/utilities/segmentConditionTypeChecks"
import { OPERATION_LABEL_MAPPER } from "resources/segment/segment/utilities/segmentOperationsConstants"
import { AndOrCondition, ConditionTree } from "types/conditionTree"

import styles from "./AttributeTable.module.scss"

const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"

const joinNodes = (nodes: (JSX.Element | string)[], joiner: JSX.Element | string = "") =>
  nodes.reduce((acc, curr) => (
    <>
      {acc}
      {joiner}
      {curr}
    </>
  )) as JSX.Element

type DimensionRowProps = {
  attribute: AttributeFull
  condition: DimensionConditionObject
}

const DimensionRow = ({
  attribute: { data_type },
  condition: {
    condition: { operation, sub_attribute_id, value },
  },
}: DimensionRowProps) => {
  const dimensions = getCompoundAttributeSubAttributes(data_type)
  const subAttribute = dimensions.find(dim => dim.id === sub_attribute_id)

  if (!subAttribute) return null

  let valueText = "-"
  if (!value) valueText = "–"
  else {
    const valueType = subAttribute.data_type
    if (Array.isArray(value)) {
      valueText = value.map(v => getUserFriendlyValueFormat(v, valueType)).join(", ")
    } else {
      valueText = getUserFriendlyValueFormat(value)
    }
  }

  return (
    <tr>
      <td className={styles.subAttributeName}>{subAttribute.name}</td>
      <td className={styles.subAttributeOperation}>
        {operation ? OPERATION_LABEL_MAPPER[operation] : ""}
      </td>
      <td className={styles.subAttributeValue}>{valueText}</td>
    </tr>
  )
}

type DimensionBranchRowProps = {
  attribute: AttributeFull
  branch: DimensionBranch
}

const DimensionBranchRow = ({
  attribute,
  branch: { operands, operation },
}: DimensionBranchRowProps) => (
  <>
    {joinNodes(
      operands.map(operand =>
        isDimensionBranch(operand) ? (
          <DimensionBranchRow attribute={attribute} branch={operand} />
        ) : (
          <DimensionRow attribute={attribute} condition={operand} />
        ),
      ),
      <tr>
        <td colSpan={3} className={classNames(styles.operation, styles.subFilterOperation)}>
          <div className={styles.operationContainer}>
            <hr className={styles.dashedLine} />
            <div className={styles.operationLabel}>{operation}</div>
          </div>
        </td>
      </tr>,
    )}
  </>
)

type CompoundConditionRowProps = {
  attribute: AttributeFull
  condition: CompoundSegmentCondition
  depth: number
}

const CompoundConditionRow = ({ attribute, condition, depth }: CompoundConditionRowProps) => (
  <>
    <tr>
      <td colSpan={3} className={styles.attributeName}>
        <span>{chars[depth % chars.length]}</span>
        {`${attribute.source.name}: ${attribute.name}`}
      </td>
    </tr>
    {isDimensionBranch(condition) ? (
      <DimensionBranchRow branch={condition} attribute={attribute} />
    ) : (
      <DimensionRow attribute={attribute} condition={condition} />
    )}
  </>
)

type SimpleConditionRowProps = {
  attribute: AttributeFull
  condition: SimpleSegmentCondition
  depth: number
}

const SimpleConditionRow = ({
  attribute,
  depth,
  condition: {
    condition: { operation, value },
  },
}: SimpleConditionRowProps) => {
  let valueText = "-"
  if (!value) valueText = "–"
  else {
    const valueType = attribute.data_type
    if (Array.isArray(value)) {
      valueText = value.map(v => getUserFriendlyValueFormat(v, valueType)).join(", ")
    } else {
      valueText = getUserFriendlyValueFormat(value)
    }
  }

  return (
    <tr>
      <td className={styles.attributeName}>
        <span>{chars[depth % chars.length]}</span>
        {`${attribute.source.name}: ${attribute.name}`}
      </td>
      <td className={styles.attributeOperation}>
        {operation ? OPERATION_LABEL_MAPPER[operation] : ""}
      </td>
      <td className={styles.attributeValue}>{valueText}</td>
    </tr>
  )
}

type ConditionRowProps = {
  condition: SegmentCondition
  depth: number
}

const ConditionRow = ({ condition, depth }: ConditionRowProps) => {
  const { data, isLoading } = useFetchAttributeById(condition.attribute_id)

  if (isLoading)
    return (
      <tr>
        <td colSpan={3}>
          <LoadingIndicator />
        </td>
      </tr>
    )

  if (!data) return null

  return isCompoundSegmentCondition(condition) ? (
    <CompoundConditionRow attribute={data} condition={condition} depth={depth} />
  ) : (
    <SimpleConditionRow attribute={data} condition={condition} depth={depth} />
  )
}

type BranchRowProps = {
  conditionTree: AndOrCondition<SegmentCondition>
  depth: number
}

const BranchRow = ({ conditionTree, depth }: BranchRowProps) => (
  <>
    {joinNodes(
      conditionTree.operands.map((condition, index) =>
        isAndOrCondition(condition) ? (
          <BranchRow conditionTree={condition} depth={depth + index} />
        ) : (
          <ConditionRow condition={condition} depth={depth + index} />
        ),
      ),
      <tr>
        <td colSpan={3} className={styles.operation}>
          <div className={styles.operationContainer}>
            <hr className={styles.dashedLine} />
            <div className={styles.operationLabel}>{conditionTree.operator.toUpperCase()}</div>
          </div>
        </td>
      </tr>,
    )}
  </>
)

type AttributeTableProps = { conditionTree: ConditionTree<SegmentCondition> }

export default function AttributeTable({ conditionTree }: AttributeTableProps) {
  if (!conditionTree) return null

  return (
    <table className={classNames("table", styles.attributeTable)}>
      <thead>
        <tr>
          <th className={styles.attributeName}>Name</th>
          <th className={styles.attributeOperation}>Condition</th>
          <th className={styles.attributeValue}>Value</th>
        </tr>
      </thead>
      <tbody>
        {isAndOrCondition(conditionTree) ? (
          <BranchRow conditionTree={conditionTree} depth={0} />
        ) : (
          <ConditionRow condition={conditionTree} depth={0} />
        )}
      </tbody>
    </table>
  )
}

import React from "react"
import styles from "./ColorInput.module.scss"
import classnames from "classnames"

type ColorInputProps = {
  name: string
  label: string
  value?: string
  onChange: (color: string) => void
  disabled?: boolean
}

export default function ColorInput({ name, value, onChange, label, disabled }: ColorInputProps) {
  return (
    <div className={styles.colorInputWrapper}>
      <label htmlFor={name}>
        <span>{label}</span>
        <div className={classnames(styles.colorInputBox, { [styles.disabled]: disabled })}>
          <div className={styles.colorInputDesignWrapper}>
            <input
              id={name}
              type="color"
              name={name}
              value={value}
              onChange={evt => {
                onChange(evt.target.value)
              }}
              className={styles.colorInput}
              disabled={disabled}
            />
          </div>
          <span className={styles.colorInputValue}>{value}</span>
        </div>
      </label>
    </div>
  )
}

import { Feature } from "resources/userRole/userRoleTypes"

const includesAll = (features: Feature[], required: Feature[]) =>
  required.every(item => features.includes(item))
const includesAny = (features: Feature[], required: Feature[]) =>
  required.some(item => features.includes(item))

export function hasAccessSelector(features: Feature[] = []) {
  const handler: ProxyHandler<any> = {
    get(target, key: keyof typeof target) {
      const value = target[key]

      if (typeof value === "object" && value !== null) {
        return new Proxy(value, handler)
      }
      return features.includes("*") || value
    },
  }

  const hasAccess = {
    administration: {
      general: includesAny(features, ["settings/users", "settings/global"]),
      users: features.includes("settings/users"),
      globalSettings: features.includes("settings/global"),
      usersActivity: features.includes("data/user_activity"),
    },
    customers: {
      search: features.includes("customers/search"),
      detail: features.includes("customers/detail"),
      all: includesAll(features, ["customers/search", "customers/detail"]),
    },
    segments: {
      create: features.includes("segments/create"),
      export:
        features.includes("segments/export") ||
        features.some(item => item.startsWith("segments/export/")),
      insights: features.includes("segments/insights"),
      listForeign: features.includes("foreign_segments/list"),
      editForeign: features.includes("foreign_segments/edit"),
      viewForeign: features.includes("foreign_segments/view"),
      all: includesAll(features, ["segments/create", "segments/export", "segments/insights"]),
      viewTags: features.includes("data/tags"),
      editTags: features.includes("settings/tags"),
      featured: {
        general: includesAny(features, [
          "featured_segments/list",
          "featured_segments/edit",
          "featured_segments/view",
        ]),
        list: features.includes("featured_segments/list"),
        view: features.includes("featured_segments/view"),
        edit: features.includes("featured_segments/edit"),
      },
    },
    data: {
      general: includesAny(features, [
        "data/attributes",
        "data/events",
        "data/sources_and_destinations",
      ]),
      dashboard: features.includes("data/dashboard"),
      insights: features.includes("data/insights"),
      attributes: features.includes("data/attributes"),
      events: features.includes("data/events"),
      sourcesAndDestinations: features.includes("data/sources_and_destinations"),
      licenceUsage: features.includes("data/licence_usage"),
      all: includesAll(features, [
        "data/dashboard",
        "data/insights",
        "data/attributes",
        "data/events",
        "data/sources_and_destinations",
        "data/licence_usage",
      ]),
    },
    setup: {
      general: includesAny(features, [
        "settings/insights",
        "settings/cdp_entities",
        "prebuilt_segments/edit",
        "reports/edit",
      ]),
      insights: features.includes("settings/insights"),
      implementation: features.includes("settings/cdp_entities"),
      smartSegments: features.includes("prebuilt_segments/edit"),
      reports: features.includes("reports/edit"),
      channels: features.includes("settings/channels"),
      lookalikeSettings: features.includes("settings/lookalike"),
      funnelGroups: features.includes("settings/funnel_groups"),
    },
    logs: features.includes("redirects/logs"),
    webBanners: {
      view: features.includes("web_banners/view"),
      edit: features.includes("web_banners/edit"),
    },
    reports: features.includes("reports/view"),
    mobilePushNotifications: {
      view: features.includes("push_notifications/view"),
      edit: features.includes("push_notifications/edit"),
    },
    emails: {
      view: features.includes("emails/view"),
      edit: features.includes("emails/edit"),
      editBlocklist: features.includes("emails/edit_blocklist"),
    },
    funnels: {
      view: features.includes("funnels/*/view"),
      edit: features.includes("funnels/*/edit"),
    },
  }

  return new Proxy<typeof hasAccess>(hasAccess, handler)
}

import React, { lazy, Suspense } from "react"
import Page from "components/UI/Page/Page"
import { getRoutePath } from "routes"
import { useHistory, useLocation } from "react-router-dom"
import { EmailTemplatePayload } from "resources/email/emailTemplate/emailTemplateTypes"
import { useCreateEmailTemplate } from "resources/email/emailTemplate/emailTemplateQueries"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
const EmailTemplateForm = lazy(() => import("../components/EmailTemplateForm/EmailTemplateForm"))

export default function EmailTemplateCreate() {
  const history = useHistory()
  const emailTemplate = useLocation<{ emailTemplate: EmailTemplatePayload } | undefined>().state
    ?.emailTemplate
  const createMutation = useCreateEmailTemplate()
  const createEmailTemplate = (data: EmailTemplatePayload) =>
    createMutation.mutateAsync(
      { data },
      {
        onSuccess({ email_template: { id } }) {
          history.push(getRoutePath("channels.emails.templates.detail", { id }))
        },
      },
    )

  return (
    <Page
      title="Create email template"
      backRouteFallback={getRoutePath("channels.emails.templates")}
    >
      <Suspense fallback={<LoadingIndicator />}>
        <EmailTemplateForm defaultValues={emailTemplate} isCreate onSubmit={createEmailTemplate} />
      </Suspense>
    </Page>
  )
}

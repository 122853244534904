/**
 * Beware of changing functions into (param) => (value) => string | undefined as
 * redux-form needs stable reference for validation fn,
 * otherwise it ends up in infinite loop
 */

import { AttributeFull } from "resources/attribute/attributeTypes"
import {
  isAttributeCompound,
  getCompoundAttributeSubAttribute,
} from "resources/attribute/compoundAttributeUtils"

export const isEmailValid = (email: string) => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) ? false : true
}

const isValidPythonVariable = (variable: string) => {
  return /^[a-z]{1}[a-z0-9_]*$/i.test(variable)
}

const isUrlValid = (url: string) => {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    url,
  )
}

export const isJSONString = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const isEmptyField = (value: unknown) =>
  value === undefined ||
  value === null ||
  (typeof value === "string" && value.trim() === "") ||
  (typeof value === "number" && isNaN(value))

// validation mesages
export const required = (value: unknown) =>
  isEmptyField(value) ? "Please fill in the field" : undefined
export const requiredArray = (value: unknown) =>
  Array.isArray(value) && value.length > 0 && value.every(item => item || item === 0)
    ? undefined
    : "Please fill in the field"
export const requiredFile = (value: FileList | null) =>
  value && value[0] ? undefined : "Please select a file"

export const minLength = (limit: number) => (value?: string) =>
  value === undefined || value.length >= limit ? undefined : `At least ${limit} characters required`
export const maxLength = (limit: number) => (value?: string) =>
  value === undefined || value.length <= limit ? undefined : `Maximum ${limit} characters allowed`

export const min = (limit: number) => (value: unknown) =>
  typeof value !== "number" || value >= limit ? undefined : `Must be >= ${limit}`
export const max = (limit: number) => (value: unknown) =>
  typeof value !== "number" || value <= limit ? undefined : `Must be <= ${limit}`

export const email = (value: string) =>
  value && !isEmailValid(value) ? "Invalid email address" : undefined
export const url = (value?: string | null) =>
  value && !isUrlValid(value) ? "Not a valid URL." : undefined
export const domElementId = (value: unknown) =>
  value && typeof value === "string" && /^[a-zA-Z0-9\-_]+$/i.test(value.trim())
    ? undefined
    : "Must contain only letters, numbers, '_' or '-'"
export const port = (value: number) =>
  /^\d+$/.test(value.toString()) ? undefined : "Please fill in a number"
export const pythonVariable = (value?: string | { value: string }) => {
  const valueToCheck =
    typeof value === "object" && value.hasOwnProperty("value") ? value.value : (value as string)
  return valueToCheck && !isValidPythonVariable(valueToCheck)
    ? "Must start with the letter and contain letter/digit characters or '_'"
    : undefined
}

export const maxFileSize = (size: number) => (value: FileList | null) => {
  if (value && value[0]) {
    const file = value[0]
    if (file.size > size) {
      return "Max size is 2MB"
    }
  }
  return undefined
}
export const jsonFile = (value: FileList | null) => {
  if (value && value[0] && value[0].type !== "application/json")
    return "Only JSON files are supported"

  return undefined
}
export const imageFile = (value: FileList | undefined) => {
  if (value && value[0]) {
    const image = value[0]
    if (image.size > 500000) {
      return "Max size is 500kB"
    } else if (
      ![
        "image/apng",
        "image/avif",
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
        "image/webp",
      ].includes(image.type)
    ) {
      return "Unsupported image type, allowed: APNG, AVIF, GIF, JPG, PNG, SVG, WEBP"
    }
  }
  return undefined
}

export const onlyValidAttributesUsed = (
  value: string,
  attributesMapById: Record<string, AttributeFull>,
) => {
  const matches = value.match(/\{\{([^}]+)\}\}/gm)?.map(match => match.replaceAll(/\{*\}*/g, ""))
  if (Array.isArray(matches)) {
    let errors: string[] = []
    matches.forEach(attributeId => {
      if (attributeId.includes(".")) {
        const [compoundAttributeId, compoundAttributeDimension, ...rest] = attributeId.split(".")
        if (rest.length !== 0) {
          errors.push(`'${attributeId}' record is malformed`)
        } else if (!attributesMapById[compoundAttributeId]) {
          errors.push(`attribute '${attributeId}' doesn't exist`)
        } else if (!isAttributeCompound(attributesMapById[compoundAttributeId].data_type)) {
          errors.push(`'${attributeId}' is not a compound attribute`)
        } else if (
          !getCompoundAttributeSubAttribute(
            compoundAttributeDimension,
            attributesMapById[compoundAttributeId].data_type,
          )
        ) {
          errors.push(`'${attributeId}' is referring to a nonexistent dimension`)
        } else if (!attributesMapById[compoundAttributeId].is_unique) {
          errors.push("Multiple-value attributes are not supported.")
        }
      } else {
        if (!attributesMapById[attributeId]) {
          errors.push(`attribute '${attributeId}' doesn't exist`)
        } else if (!attributesMapById[attributeId].is_unique) {
          errors.push("Multiple-value attributes are not supported.")
        } else if (isAttributeCompound(attributesMapById[attributeId].data_type)) {
          errors.push(`${attributeId} is a compound attribute`)
        }
      }
    })
    if (errors.length) {
      return errors.join(", ")
    }
  }
  return undefined
}

export const isNumber = (value: unknown) =>
  typeof value === "number" || (typeof value === "string" && /^\d+$/.test(value))
export const onlyNumber = (value: unknown) =>
  isNumber(value) ? undefined : "Must contain only numbers"

const isSnakeCase = (value: string) => /^[a-z]{1}[a-z_]*[a-z]{1}$/i.test(value)
export const snakeCase = (value: string) =>
  value && !isSnakeCase(value)
    ? "Must start with the letter and contain letter characters or '_'"
    : undefined

export const isUuidValid = (uuid: string) =>
  /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(uuid)

export const hasTrailingWhitespaces = (value: string) =>
  value.length > 0 && /^[ \t]+|[ \t]+$/.test(value)

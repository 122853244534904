import React, { useCallback, useContext } from "react"
import styles from "./SegmentCondition.module.scss"
import {
  ERROR,
  SegmentCondition,
  SegmentConditionError,
  SimpleSegmentCondition,
} from "resources/segment/segment/segmentConditionsTypes"
import { DraggableSyntheticListeners } from "@dnd-kit/core"
import { ConditionSymbol } from "types/conditionTree"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import { getSymbolStyle } from "components/ConditionBuilder/treeSymbols"
import IconButton from "components/UI/elements/IconButton/IconButton"
import Button from "components/UI/elements/Button/Button"
import { assocPath as set } from "ramda"
import {
  getCompoundAttributeSubAttributes,
  isAttributeCompound,
} from "resources/attribute/compoundAttributeUtils"
import ConditionOperationDropdown from "pages/Segments/components/SegmentDetail/components/SegmentCondition/ConditionOperationDropdown/ConditionOperationDropdown"
import { OPERATION_CONTEXT_TYPE } from "resources/segment/segment/utilities/segmentOperationsConstants"
import {
  areValuesCompatible,
  getDataTypeDefaultContext,
  getDataTypeDisabledOperations,
  getDimensionValueError,
} from "./utils"
import ValueContainer from "./ValueContainer/ValueContainer"
import { fetchCustomerAttributeValues } from "helpers/attributeValue.helper"
import { ConditionNumbersContext } from "../../conditionNumbersContext"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Tippy from "@tippyjs/react"
import CompoundOperationPicker from "./CompoundOperationPicker/CompoundOperationPicker"
import DimensionsTree from "./DimensionTree/DimensionTree"
import DelayedTooltip from "components/UI/elements/DelayedTooltip/DelayedTooltip"
import { isCompoundSegmentCondition } from "resources/segment/segment/utilities/segmentConditionTypeChecks"
import { useFetchAttributeById } from "resources/attribute/attributeQueries"
import AttributePicker from "components/AttributePicker/AttributePicker"

type SegmentConditionProps = {
  condition: SegmentCondition
  onChange: (condition: SegmentCondition) => void
  removeSelf?: () => void
  duplicateSelf?: () => void
  hoverSelf?: (hovered: boolean) => void
  dragListeners?: DraggableSyntheticListeners
  symbol: ConditionSymbol
  isEditable: boolean
  error?: SegmentConditionError | null
  greyedOut?: boolean
  highlighted?: boolean
}

export const getNewCondition = (): SegmentCondition => ({
  attribute_id: null,
  negation: false,
  condition: { operation: null },
})

export default function SegmentConditionComponent({
  condition,
  onChange,
  removeSelf,
  duplicateSelf,
  hoverSelf,
  dragListeners,
  symbol,
  isEditable,
  error,
  greyedOut = false,
  highlighted = false,
}: SegmentConditionProps) {
  const { attribute_id, negation } = condition

  const { data: attribute } = useFetchAttributeById(attribute_id)

  const defaultContext = attribute && getDataTypeDefaultContext(attribute.data_type)
  const disabledOperations = attribute && getDataTypeDisabledOperations(attribute.data_type)

  const isCompoundAttribute = isAttributeCompound(attribute?.data_type)
  const isHiddenAttribute = attribute?.is_hidden === 1
  const isDeletedAttribute = attribute_id && attribute === null

  const toggleNegation = useCallback(
    () => onChange(set(["negation"], !condition.negation, condition)),
    [condition, onChange],
  )

  const setAttributeId = useCallback(
    newAttributeId => {
      onChange({ attribute_id: newAttributeId, negation, condition: { operation: null } })
    },
    [negation, onChange],
  )

  const setSimpleConditionOperation = useCallback(
    newOperation => {
      const { operation: oldOperation, value } = (condition as SimpleSegmentCondition).condition
      onChange(
        set(
          ["condition"],
          {
            operation: newOperation,
            value: areValuesCompatible(oldOperation, newOperation) ? value : null,
          },
          condition,
        ),
      )
    },
    [condition, onChange],
  )

  const setSimpleConditionValue = useCallback(
    newValue => {
      onChange(set(["condition", "value"], newValue, condition))
    },
    [condition, onChange],
  )

  const setFirstDimension = useCallback(
    (sub_attribute_id: string) => {
      onChange(set(["condition"], { sub_attribute_id, operation: null }, condition))
    },
    [condition, onChange],
  )

  // SEGMENTED NUMBERS

  const { totalCustomers, conditionNumbers, isInvalidNumbers } = useContext(ConditionNumbersContext)

  const segmentedCount: number | undefined = conditionNumbers[symbol.index]
  const segmentedPercentage =
    segmentedCount && totalCustomers && Math.round(100 * (segmentedCount / totalCustomers))
  const isLoadingNumbers = segmentedCount === undefined || totalCustomers === null

  const segmentedPercentageDisplayString = `(${
    segmentedPercentage === 0 && segmentedCount !== 0
      ? "<1"
      : segmentedPercentage === 100 && segmentedCount < totalCustomers!
      ? ">99"
      : segmentedPercentage
  } %)`

  const negationButton = (
    <Button
      color={condition.negation ? "black" : "grey"}
      disabled={!isEditable}
      size="xs"
      variant={condition.negation ? "solid" : "outlined"}
      onClick={toggleNegation}
    >
      not
    </Button>
  )

  return (
    <div
      className={classNames(styles.container, {
        [styles.error]: error,
        [styles.negation]: negation,
        [styles.highlighted]: highlighted,
        [styles.greyedOut]: greyedOut,
      })}
    >
      <div className={styles.inputsWrapper}>
        {(isHiddenAttribute || isDeletedAttribute) && (
          <div className={styles.hiddenAttributeWarning}>
            <FontAwesomeIcon
              icon={["fas", "exclamation-triangle"]}
              className={styles.warningIcon}
            />
            The attribute used in this condition{" "}
            {isHiddenAttribute
              ? "is hidden."
              : "has been deleted. You will not be able to export the segment."}
          </div>
        )}
        <div className={styles.main}>
          <div className={styles.verticalAlignmentWrapper}>
            {dragListeners && isEditable ? (
              <DelayedTooltip content="Move condition">
                <div className={styles.dragHandle} {...dragListeners}>
                  <FontAwesomeIcon icon={["fas", "grip-vertical"]} />
                </div>
              </DelayedTooltip>
            ) : (
              <div className={classNames(styles.dragHandle, styles.disabled)}>
                <FontAwesomeIcon icon={["fas", "grip-vertical"]} />
              </div>
            )}
          </div>
          <div className={styles.verticalAlignmentWrapper}>
            {/* TODO: fix tooltip, then remove the ternary and always display tooltip */}
            {isEditable ? (
              <DelayedTooltip
                content={condition.negation ? "Include these customers" : "Exclude these customers"}
              >
                {negationButton}
              </DelayedTooltip>
            ) : (
              negationButton
            )}
          </div>

          <div className={styles.verticalAlignmentWrapper}>
            <div className={styles.symbol} style={getSymbolStyle(symbol.color)}>
              {symbol.text}
            </div>
          </div>

          <div className={styles.inputs}>
            <div className={styles.topRow}>
              <AttributePicker
                className={styles.attributePicker}
                value={attribute_id}
                readOnly={!isEditable}
                onChange={setAttributeId}
                error={error?.attribute_id}
              />

              {!isCompoundSegmentCondition(condition) && !isCompoundAttribute && (
                <ConditionOperationDropdown
                  value={condition.condition?.operation ?? null}
                  handleValueChange={setSimpleConditionOperation}
                  isDisabled={!isEditable || !attribute_id}
                  allowedContexts={[defaultContext, OPERATION_CONTEXT_TYPE.OTHER]}
                  defaultContext={defaultContext}
                  disabledOperations={disabledOperations}
                  errorMarkup={Boolean(attribute_id && error?.operation)}
                  isVisible={true} // TODO:
                />
              )}

              {!isCompoundSegmentCondition(condition) && isCompoundAttribute && (
                <CompoundOperationPicker
                  operation={condition.condition.operation}
                  onChangeOperation={setSimpleConditionOperation}
                  onSelectDimension={setFirstDimension}
                  dimensions={getCompoundAttributeSubAttributes(attribute!.data_type)}
                  isEditable={isEditable}
                  error={attribute_id ? error?.operation : undefined}
                />
              )}
            </div>

            {isCompoundSegmentCondition(condition) && (
              <DimensionsTree
                condition={condition}
                onChange={onChange}
                isEditable={isEditable}
                dimensions={getCompoundAttributeSubAttributes(attribute?.data_type)}
                attributeId={attribute_id}
                dimensionsError={error?.dimensions}
              />
            )}

            {!isCompoundSegmentCondition(condition) &&
              !isCompoundAttribute &&
              condition.condition?.operation && (
                <ValueContainer
                  onChange={setSimpleConditionValue}
                  condition={condition.condition}
                  errors={
                    error?.value
                      ? [error.value]
                      : error?.min_value || error?.max_value
                      ? [error.min_value, error.max_value]
                      : null
                  }
                  isVisible={true} // TODO:
                  isEditable={isEditable}
                  stringSelectOptionsFetch={() => fetchCustomerAttributeValues(attribute_id)}
                  dataType={attribute?.data_type}
                />
              )}
          </div>
        </div>

        <div className={styles.bottomRow}>
          <div className={styles.segmentedWrapper}>
            <div>SEGMENTED:</div>

            {isInvalidNumbers && (
              <div className={styles.segmentedCount}>
                <Tippy content="Save segment conditions to see segmentation numbers.">
                  <span>N/A</span>
                </Tippy>
              </div>
            )}

            {isLoadingNumbers && !isInvalidNumbers && <LoadingIndicator size="xs" fixedWidth />}

            {!isLoadingNumbers && !isInvalidNumbers && (
              <>
                <div className={styles.segmentedCount}>{segmentedCount.toLocaleString()}</div>
                <div className={styles.segmentedPercentage}>{segmentedPercentageDisplayString}</div>
              </>
            )}
          </div>
          {error && (
            <div className={styles.errorMessage}>
              {error.value === ERROR.MAX_LENGTH ||
              getDimensionValueError(error?.dimensions) === ERROR.MAX_LENGTH
                ? "Incorrect condition definition. Cannot have more than 100 values."
                : "Incomplete condition definition."}
            </div>
          )}
        </div>
      </div>

      {isEditable && (
        <div className={styles.buttonsWrapper}>
          {removeSelf && (
            <IconButton
              onClick={removeSelf}
              icon="trash-alt"
              color="red"
              tooltip="Delete"
              variant="outlined"
              size="xs"
            />
          )}
          {duplicateSelf && hoverSelf && (
            <IconButton
              onClick={duplicateSelf}
              icon="copy"
              color="grey"
              tooltip="Duplicate"
              variant="outlined"
              size="xs"
              onMouseEnter={() => hoverSelf(true)}
              onMouseLeave={() => hoverSelf(false)}
            />
          )}
        </div>
      )}
    </div>
  )
}

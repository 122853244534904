import React, { lazy, Suspense } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tippy from "@tippyjs/react"
import { format, isPast, isToday, parseISO } from "date-fns"
import { Controller, useForm, useWatch } from "react-hook-form"

import Button from "components/UI/elements/Button/Button"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import Modal from "components/UI/elements/Modal"
import TextInput from "components/UI/elements/TextInput/TextInput"
import { isNumber, onlyNumber, required } from "helpers/validators.helper"

import styles from "./StaticScheduleForm.module.scss"
const DatePicker = lazy(() => import("react-datepicker"))

export type StaticScheduleFormFields = {
  date: Date
  hours: string
  minutes: string
}

type StaticScheduleFormProps = {
  isDeleting: boolean
  isEditable: boolean
  isSaving: boolean
  open: boolean
  onClose: () => void
  onDelete: () => void
  onSubmit: (values: StaticScheduleFormFields) => void
  schedule?: string
}

export default function StaticSchedulerForm({
  isDeleting,
  isEditable,
  isSaving,
  open,
  schedule,
  onClose,
  onDelete,
  onSubmit,
}: StaticScheduleFormProps) {
  const parsed = schedule ? parseISO(schedule) : undefined
  let initialMinutes: string | undefined = undefined
  let initialDate: Date | undefined = undefined
  if (parsed) {
    initialDate = new Date(
      Date.UTC(parsed.getUTCFullYear(), parsed.getUTCMonth(), parsed.getUTCDate()),
    )

    const strMinutes = parsed.getUTCMinutes().toString()
    if (strMinutes === "0") initialMinutes = "00"
    else initialMinutes = strMinutes
  }

  const { control, getValues, handleSubmit } = useForm<StaticScheduleFormFields>({
    defaultValues: {
      date: initialDate,
      hours: parsed?.getUTCHours().toString(),
      minutes: initialMinutes,
    },
  })

  const date = useWatch({ control, name: "date" })
  const hours = useWatch({ control, name: "hours" })
  const minutes = useWatch({ control, name: "minutes" })

  let serverTime = "not set"
  let localTime = "not set"
  if (date && hours && minutes && isNumber(hours) && isNumber(minutes)) {
    const dateCopy = new Date(date.valueOf())
    dateCopy.setUTCHours(parseInt(hours))
    dateCopy.setUTCMinutes(parseInt(minutes))

    const utcDate = new Date(
      dateCopy.getUTCFullYear(),
      dateCopy.getUTCMonth(),
      dateCopy.getUTCDate(),
      dateCopy.getUTCHours(),
      dateCopy.getUTCMinutes(),
      dateCopy.getUTCSeconds(),
    )

    serverTime = format(utcDate, "H:mm")
    localTime = format(dateCopy, "H:mm")
  }

  return (
    <Modal open={open} title="Scheduler" handleClose={onClose} className={styles.modal}>
      <form>
        <div className={styles.container}>
          <div className={styles.dateTime}>
            <Controller
              control={control}
              name="date"
              defaultValue={new Date()}
              rules={{
                validate: {
                  required,
                  past: value => {
                    if (!value || isToday(value)) return

                    return isPast(value) ? "Date cannot be in the past" : undefined
                  },
                },
              }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <div className="static-scheduler-datepicker">
                  <Suspense fallback={<LoadingIndicator />}>
                    <DatePicker
                      disabledKeyboardNavigation
                      inline
                      scrollableYearDropdown
                      showMonthDropdown
                      showYearDropdown
                      disabled={!isEditable}
                      minDate={new Date()}
                      selected={value ? new Date(value) : undefined}
                      timeInputLabel="Time:"
                      onChange={onChange}
                      calendarClassName="calendar-dropdown"
                    />
                    {error && <p className={styles.errorMessage}>{error.message}</p>}
                  </Suspense>
                </div>
              )}
            />
            <div className={styles.time}>
              <span className={styles.label}>Time</span>
              <Controller
                control={control}
                name="hours"
                rules={{
                  validate: {
                    required,
                    onlyNumber,
                    min: value => (parseInt(value) >= 0 ? undefined : "Must be >= 0"),
                    max: value => (parseInt(value) <= 23 ? undefined : "Must be <= 23"),
                    past: value => {
                      const { date } = getValues()
                      if (!value || !date || !isToday(date)) return

                      return parseInt(value) < new Date().getUTCHours()
                        ? "Time cannot be in the past"
                        : undefined
                    },
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    {...field}
                    disabled={!isEditable}
                    autoComplete="off"
                    maxLength={2}
                    placeholder="0"
                    error={error?.message}
                    className={styles.hours}
                  />
                )}
              />
              <span className={styles.doubleDot}>:</span>
              <Controller
                control={control}
                name="minutes"
                rules={{
                  validate: {
                    required,
                    onlyNumber,
                    min: value => (parseInt(value) >= 0 ? undefined : "Must be >= 0"),
                    max: value => (parseInt(value) <= 59 ? undefined : "Must be <= 59"),
                    past: value => {
                      const { date, hours } = getValues()
                      const today = new Date()
                      if (
                        !value ||
                        !date ||
                        !hours ||
                        !isToday(date) ||
                        today.getUTCHours() !== parseInt(hours)
                      )
                        return

                      return parseInt(value) <= today.getUTCMinutes()
                        ? "Time cannot be in the past"
                        : undefined
                    },
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextInput
                    {...field}
                    disabled={!isEditable}
                    autoComplete="off"
                    maxLength={2}
                    placeholder="00"
                    error={error?.message}
                    className={styles.minutes}
                  />
                )}
              />
              <div className={styles.utcInfo}>
                <Tippy
                  content={
                    <>
                      <p>
                        <span>Server time (UTC):</span> <span>{serverTime}</span>
                      </p>
                      <p>
                        <span>Local time:</span> <span>{localTime}</span>
                      </p>
                    </>
                  }
                >
                  <FontAwesomeIcon icon={["fas", "clock"]} />
                </Tippy>
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <Button
              color="red"
              loading={isDeleting}
              size="md"
              variant="outlined"
              onClick={_ => onDelete()}
            >
              Delete
            </Button>
            <Button
              loading={isSaving}
              size="md"
              // cannot use form onSubmit due to the nested form
              onClick={() => {
                handleSubmit(onSubmit)()
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

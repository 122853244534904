import { PWBPosition, PWBAnimation } from "resources/webBanner/popupWebBanner/popupWBTypes"
import { WebBannersPreviewProps } from "./BannerPreview"

export const getMarginsStyles = (
  previewType: "large" | "minimized",
  margins: WebBannersPreviewProps["margins"],
  position?: PWBPosition,
) => {
  const styles: {
    marginTop?: string
    marginRight?: string
    marginBottom?: string
    marginLeft?: string
  } = {}
  const defaultBgPadding = 20
  if (position) {
    if (previewType === "large") {
      if (margins.y !== undefined) {
        if (["top_left", "top", "top_right"].includes(position)) {
          styles.marginTop = `${margins.y - defaultBgPadding}px`
        }
        if (["bottom_left", "bottom", "bottom_right"].includes(position)) {
          styles.marginBottom = `${margins.y - defaultBgPadding}px`
        }
      }
      if (margins.x !== undefined) {
        if (["top_left", "left", "bottom_left"].includes(position)) {
          styles.marginLeft = `${margins.x - defaultBgPadding}px`
        }
        if (["top_right", "right", "bottom_right"].includes(position)) {
          styles.marginRight = `${margins.x - defaultBgPadding}px`
        }
      }
    } else if (previewType === "minimized") {
      if (margins.minimizedY !== undefined) {
        if (["top_left", "top", "top_right"].includes(position)) {
          styles.marginTop = `${margins.minimizedY - defaultBgPadding}px`
        }
        if (["bottom_left", "bottom", "bottom_right"].includes(position)) {
          styles.marginBottom = `${margins.minimizedY - defaultBgPadding}px`
        }
      }
      if (margins.minimizedX !== undefined) {
        if (["top_left", "left", "bottom_left"].includes(position)) {
          styles.marginLeft = `${margins.minimizedX - defaultBgPadding}px`
        }
        if (["top_right", "right", "bottom_right"].includes(position)) {
          styles.marginRight = `${margins.minimizedX - defaultBgPadding}px`
        }
      }
    }
  }
  return styles
}

export type AnimationStyles = {
  opacity?: string
  transition?: string
  top?: string
  right?: string
  bottom?: string
  left?: string
}
export const getAnimationStyles = (
  step: "initial" | "final",
  animation?: PWBAnimation,
  position?: PWBPosition,
  width?: number,
  height?: number,
  margins?: {
    x?: number
    y?: number
  },
) => {
  const styles: AnimationStyles = {}
  if (animation === "fade_in") {
    if (step === "initial") {
      styles.opacity = "0"
      styles.transition = ""
    } else if (step === "final") {
      styles.opacity = "1"
      styles.transition = "opacity 0.3s ease-in"
    }
  } else if (animation === "slide_in" && position && width && height) {
    const xOffset = margins?.x !== undefined && Number.isInteger(margins.x) ? margins.x + 20 : 20
    const yOffset = margins?.y !== undefined && Number.isInteger(margins.y) ? margins.y + 20 : 20
    if (step === "initial") {
      if (position === "top") {
        styles.top = `${-height - 20 - yOffset}px`
      }
      if (position === "top_right" || position === "right" || position === "bottom_right") {
        styles.right = `${-width - 20 - xOffset}px`
      }
      if (position === "bottom") {
        styles.bottom = `${-height - 20 - yOffset}px`
      }
      if (position === "bottom_left" || position === "left" || position === "top_left") {
        styles.left = `${-width - 20 - xOffset}px`
      }
    } else if (step === "final") {
      if (position === "top") {
        styles.top = "0px"
      }
      if (position === "top_right" || position === "right" || position === "bottom_right") {
        styles.right = "0px"
      }
      if (position === "bottom") {
        styles.bottom = "0px"
      }
      if (position === "bottom_left" || position === "left" || position === "top_left") {
        styles.left = "0px"
      }
      styles.transition =
        "left 0.5s ease-in, right 0.5s ease-in, top 0.5s ease-in, bottom 0.5s ease-in"
    }
  }
  return styles
}

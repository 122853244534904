import { EmbeddedWebBanner } from "./embeddedWBTypes"

export const getImgSrcFromHtml = (webBanner?: EmbeddedWebBanner) => {
  if (!webBanner || webBanner.settings.content.type === "html") {
    return ""
  }

  const regex = /<img.*?src='(.*?)'/

  const matches = regex.exec(webBanner.settings.content.html)
  if (matches && matches.length > 0) {
    return matches[1]
  }
  return ""
}

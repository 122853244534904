import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import classNames from "classnames"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { OPERATION_LABEL_MAPPER } from "resources/segment/segment/utilities/segmentOperationsConstants"
import { ERROR, SegmentConditionOperation } from "resources/segment/segment/segmentConditionsTypes"
import styles from "./CompoundOperationPicker.module.scss"

type CompoundOperationPickerProps = {
  operation: SegmentConditionOperation | null
  onChangeOperation: (operation: SegmentConditionOperation) => void
  onSelectDimension: (subattributeId: string) => void
  dimensions: { id: string; name: string }[]
  isEditable: boolean
  error?: ERROR | null
}

export default function CompoundOperationPicker({
  operation,
  onChangeOperation,
  onSelectDimension,
  dimensions,
  isEditable,
  error,
}: CompoundOperationPickerProps) {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)

  const closeDropdown = useCallback(() => {
    setIsOpen(false)
    document.removeEventListener("click", clickHandlerRef.current, false)
  }, [])

  const handleOutsideClick = useCallback(
    e => {
      if (dropdownRef.current !== null) {
        if (!dropdownRef.current.contains(e.target)) {
          closeDropdown()
        }
      }
    },
    [closeDropdown],
  )

  const clickHandlerRef = useRef(handleOutsideClick)

  const openDropdown = useCallback(() => {
    setIsOpen(true)
    clickHandlerRef.current = handleOutsideClick
    setTimeout(() => document.addEventListener("click", clickHandlerRef.current, false), 0)
  }, [handleOutsideClick])

  useEffect(() => {
    return () => {
      document.removeEventListener("click", clickHandlerRef.current, false)
    }
  }, [])

  const toggleDropdown = useCallback(() => {
    isOpen ? closeDropdown() : openDropdown()
  }, [closeDropdown, isOpen, openDropdown])

  const changeOperation = useCallback(
    (operation: SegmentConditionOperation) => {
      onChangeOperation(operation)
      closeDropdown()
    },
    [closeDropdown, onChangeOperation],
  )

  return (
    <div className={styles.container}>
      <button
        className={classNames(styles.button, {
          [styles.error]: error,
        })}
        onClick={toggleDropdown}
        disabled={!isEditable}
      >
        <div className={styles.buttonContent}>
          <div className={styles.text}>
            {operation ? (
              OPERATION_LABEL_MAPPER[operation]
            ) : (
              <span className={styles.placeholder}>Select</span>
            )}
          </div>
          <div className={styles.caretWrapper}>
            {isOpen ? (
              <FontAwesomeIcon className={styles.icon} icon={["fas", "caret-up"]} />
            ) : (
              <FontAwesomeIcon className={styles.icon} icon={["fas", "caret-down"]} />
            )}
          </div>
        </div>
      </button>
      {isOpen && (
        <div className={styles.dropdown} ref={dropdownRef}>
          <div className={styles.dropdownRow} onClick={() => changeOperation("is_set")}>
            {OPERATION_LABEL_MAPPER["is_set"]}
          </div>
          <div className={styles.dropdownRow} onClick={() => changeOperation("is_not_set")}>
            {OPERATION_LABEL_MAPPER["is_not_set"]}
          </div>
          <div className={styles.divider} />
          <div className={styles.titleRow}>Add dimension:</div>
          {dimensions.map(dimension => (
            <div
              key={dimension.id}
              className={styles.dimensionDropdownRow}
              onClick={() => onSelectDimension(dimension.id)}
            >
              {dimension.name}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

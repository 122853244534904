import React from "react"

import Button from "components/UI/elements/Button/Button"
import Modal from "components/UI/elements/Modal"

import styles from "./WebBannerDisableDefaultTrackingModal.module.scss"

type WebBannerDisableDefaultTrackingModalProps = {
  open: boolean
  handleClose: () => void
  handleConfirm: () => void
}

export default function WebBannerDisableDefaultTrackingModal({
  open,
  handleClose,
  handleConfirm,
}: WebBannerDisableDefaultTrackingModalProps) {
  return (
    <Modal open={open} title="Disable default impression tracking" handleClose={handleClose}>
      <p className={styles.paragraph}>
        By confirming you will disable tracking of web banner impressions which is set by default.
        You need to add tracking in the code manually or no impressions will be tracked at all. Are
        you sure you want to proceed?
      </p>
      <div className={styles.buttons}>
        <Button color="grey" variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          color="green"
          onClick={() => {
            handleConfirm()
            handleClose()
          }}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  )
}

import {
  QueryKey,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query"
import { showToast } from "app/toast"
import { api } from "api"
import fetchAll from "helpers/fetchAll.helper"
import { isNil, prop, sort, update } from "ramda"
import { useCallback } from "react"
import {
  CreateMobilePushNotificationPayload,
  MobilePushNotification,
  MobilePushNotificationsListDeletedResponse,
  MobilePushNotificationsListResponse,
  MobilePushSelectionState,
  ModifyMobilePushNotificationPayload,
} from "./mobilePushNotificationTypes"
import { ascend, descend } from "utilities/comparators"
import { sortByDateProp } from "resources/utils"
import { getRoutePath } from "routes"
import { TrashItem } from "types/trash"

type MobilePushSelectionOptions = Pick<
  MobilePushSelectionState,
  "orderBy" | "orderDir" | "searchTerm"
>

export const MOBILE_PUSH_NOTIFICATION = "mobilePushNotification" as const
export const MOBILE_PUSH_NOTIFICATION_ALL_QK: QueryKey = [MOBILE_PUSH_NOTIFICATION, "all"]
export const MOBILE_PUSH_NOTIFICATION_TRASH_QK: QueryKey = [MOBILE_PUSH_NOTIFICATION, "trash"]

function useMobilePushNotificationsQuery<T = MobilePushNotificationsListResponse>(
  config?: UseQueryOptions<MobilePushNotificationsListResponse, unknown, T, QueryKey>,
) {
  return useQuery(MOBILE_PUSH_NOTIFICATION_ALL_QK, api.mobilePushNotification.list, config)
}

export function useFetchAllMobilePushNotifications(
  config?: UseQueryOptions<
    MobilePushNotificationsListResponse,
    unknown,
    MobilePushNotificationsListResponse,
    QueryKey
  >,
  options?: MobilePushSelectionOptions,
) {
  return useMobilePushNotificationsQuery({
    ...config,
    select: data => {
      if (!options) return data
      const { push_notifications } = data

      const filteredNotifications = options.searchTerm
        ? push_notifications.filter(({ name }) =>
            name.toLowerCase().includes(options.searchTerm.toLowerCase()),
          )
        : push_notifications

      let sortedNotifications: Array<MobilePushNotification> = []
      if (options.orderBy === "last_activation")
        sortedNotifications = sortByDateProp(
          filteredNotifications,
          options.orderDir,
          "last_activation",
        )
      else {
        const comparator = options.orderDir === "ASC" ? ascend : descend
        sortedNotifications = sort(comparator(prop(options.orderBy)), filteredNotifications)
      }

      return { ...data, push_notifications: sortedNotifications }
    },
  })
}

export function useFetchAllDeletedMobilePushNotifications() {
  return useQuery([MOBILE_PUSH_NOTIFICATION, "deleted", "all"], () =>
    fetchAll({
      fetchFn: (offset, limit) => api.mobilePushNotification.listDeleted({ offset, limit }),
      key: "trashed_push_notifications",
    }),
  )
}

export function useFetchMobilePushNotificationById(id?: MobilePushNotification["id"] | null) {
  const select = useCallback(
    ({ push_notifications }: MobilePushNotificationsListResponse) =>
      push_notifications.find(notification => notification.id === id) ?? null,
    [id],
  )

  return useMobilePushNotificationsQuery({
    enabled: !isNil(id),
    select,
  })
}

export function useCreateMobilePushNotification() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: CreateMobilePushNotificationPayload }) =>
      api.mobilePushNotification.create(data),
    {
      onSuccess: ({ push_notification }) => {
        queryClient.setQueryData<MobilePushNotificationsListResponse>(
          MOBILE_PUSH_NOTIFICATION_ALL_QK,
          data => {
            if (!data) {
              return
            }

            const push_notifications = data.push_notifications.concat(push_notification)

            return { ...data, push_notifications }
          },
        )
        showToast("Mobile push created.")
      },
    },
  )
}

export function useModifyMobilePushNotification() {
  const queryClient = useQueryClient()

  return useMutation(
    ({
      id,
      data,
    }: {
      id: MobilePushNotification["id"]
      data: ModifyMobilePushNotificationPayload
    }) => api.mobilePushNotification.modify(id, data),
    {
      onSuccess: ({ push_notification }) => {
        queryClient.setQueryData<MobilePushNotificationsListResponse>(
          MOBILE_PUSH_NOTIFICATION_ALL_QK,
          data => {
            if (!data) {
              return
            }

            const index = data.push_notifications.findIndex(({ id }) => id === push_notification.id)

            const push_notifications =
              index === undefined
                ? data.push_notifications.concat(push_notification)
                : update(index, push_notification, data.push_notifications)

            return { ...data, push_notifications }
          },
        )
      },
    },
  )
}

export function useDeleteMobilePushNotification() {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id }: { id: MobilePushNotification["id"] }) => api.mobilePushNotification.delete(id),
    {
      onSuccess: (_, { id }) => {
        queryClient.setQueryData<MobilePushNotificationsListResponse>(
          MOBILE_PUSH_NOTIFICATION_ALL_QK,
          data => {
            if (!data) {
              return
            }

            const push_notifications = data.push_notifications.filter(item => item.id !== id)

            return { ...data, push_notifications }
          },
        )

        queryClient.removeQueries([MOBILE_PUSH_NOTIFICATION, id])
        showToast("Mobile push deleted.")
      },
    },
  )
}

export const useFetchMobilePushNotificationTrashItems = (searchTerm: string) => {
  const { data, ...rest } = useInfiniteQuery<
    MobilePushNotificationsListDeletedResponse,
    string,
    Omit<MobilePushNotificationsListDeletedResponse, "trashed_push_notifications"> & {
      trashed_push_notifications: Array<TrashItem>
    },
    QueryKey
  >(
    [...MOBILE_PUSH_NOTIFICATION_TRASH_QK, searchTerm],
    ({ pageParam }) =>
      api.mobilePushNotification.listDeleted({
        offset: pageParam,
        limit: 20,
        searched_text: searchTerm,
      }),
    {
      getNextPageParam: last => {
        if (
          last.selection_settings.limit === null ||
          last.selection_settings.offset === null ||
          last.trashed_push_notifications.length < last.selection_settings.limit
        )
          return

        return last.selection_settings.offset + last.selection_settings.limit
      },
      select: data => {
        return {
          ...data,
          pages: data.pages.map(p => ({
            ...p,
            trashed_push_notifications: p.trashed_push_notifications.map(
              ({ id, name, modified, modified_by }) => {
                const trashItem: TrashItem = {
                  id,
                  name,
                  deleted_at: modified,
                  deleted_by: modified_by,
                  type: "push_notifications",
                }

                return trashItem
              },
            ),
          })),
        }
      },
    },
  )

  return { ...rest, data: data ? data.pages.flatMap(m => m.trashed_push_notifications) : [] }
}

export const useRestoreMobilePushNotification = () => {
  const queryClient = useQueryClient()

  return useMutation(
    ({ id }: { id: MobilePushNotification["id"] }) => api.mobilePushNotification.restoreDeleted(id),
    {
      onSuccess: ({ push_notification }) => {
        queryClient.invalidateQueries(MOBILE_PUSH_NOTIFICATION_TRASH_QK)
        queryClient.setQueryData<MobilePushNotificationsListResponse>(
          MOBILE_PUSH_NOTIFICATION_ALL_QK,
          data => {
            if (!data) return

            const pushNotifications = data.push_notifications.concat(push_notification)
            return { ...data, push_notifications: pushNotifications }
          },
        )

        showToast(
          "Mobile push notification restored.",
          undefined,
          getRoutePath("channels.mobile-push.detail", { id: push_notification.id }),
        )
      },
    },
  )
}

import React from "react"
import ReactDOM from "react-dom"

// ui components
import ConfirmModal from "components/UI/components/ConfirmModal"

// constants
import { MODAL } from "sharedConstants"

const getUserConfirmation = (message, callback) => {
  const modal = document.createElement("div")
  document.body.appendChild(modal)

  const withCleanup = answer => {
    ReactDOM.unmountComponentAtNode(modal)
    document.body.removeChild(modal)
    callback(answer)
  }

  ReactDOM.render(
    <ConfirmModal
      open={true}
      type={MODAL.TYPE.CANCEL}
      handleClose={() => withCleanup(false)}
      handleConfirm={() => withCleanup(true)}
      title="Are you sure?"
      text={message}
    />,
    modal,
  )
}

export default getUserConfirmation

import React from "react"

import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { abbreviateNumber } from "helpers/number.helper"
import { DataType, InsightType, ConditionValueType } from "../types"
import DelayedTooltip from "components/UI/elements/DelayedTooltip/DelayedTooltip"
import { shortenText } from "helpers/string.helper"

type ExpandedInsightConditionValueProps = {
  dataType: DataType
  type: InsightType
  value: ConditionValueType
}

export default function ExpandedInsightConditionValue({
  dataType,
  type,
  value,
}: ExpandedInsightConditionValueProps) {
  if (["LOWER_THAN", "GREATER_THAN", "COUNT", "CONTAINS"].includes(type) && !Array.isArray(value)) {
    if (["int", "float"].includes(dataType)) return <span>{abbreviateNumber(value)}</span>
    else if (["date", "datetime"].includes(dataType)) {
      const dateValue = getUserFriendlyValueFormat(value, dataType)
      return dateValue !== "Invalid date" ? <span>{dateValue}</span> : <span>{value}</span>
    } else if (dataType === "string" && typeof value === "string") {
      if (value.length > 35) {
        return (
          <span>
            <DelayedTooltip content={value}>
              <span>{shortenText(value, 35)}</span>
            </DelayedTooltip>
          </span>
        )
      } else return <span>{value}</span>
    }
  }

  if (type === "BETWEEN" && Array.isArray(value) && value.length === 2) {
    const [from, to] = value

    if (["int", "float"].includes(dataType))
      return (
        <span>
          {abbreviateNumber(from)} and {abbreviateNumber(to)}
        </span>
      )

    if (["date", "datetime"].includes(dataType)) {
      const fromDate = getUserFriendlyValueFormat(from, dataType)
      const toDate = getUserFriendlyValueFormat(to, dataType)

      return (
        <span>
          {fromDate !== "Invalid date" ? fromDate : from} and{" "}
          {toDate !== "Invalid date" ? toDate : to}
        </span>
      )
    }
  }

  return null
}

import requestFactory from "api/request"
import fetchAll from "helpers/fetchAll.helper"
import {
  SegmentExportDestination,
  SegmentExportDestinationListDeletedParams,
  SegmentExportDestinationListDeletedResponse,
  SegmentExportDestinationModifyPayload,
} from "./exportDestinationTypes"

const exportDestination = {
  listAll(): Promise<SegmentExportDestination[]> {
    return fetchAll({
      fetchFn: (offset, limit) =>
        requestFactory("get", "/segments/export/destinations", {
          offset,
          limit,
          order_by: "id",
          order_dir: "ASC",
          load_full_structure: 0,
        }),
      key: "segment_export_destinations",
    })
  },
  modify(
    destinationId: SegmentExportDestination["id"],
    data: SegmentExportDestinationModifyPayload,
  ): Promise<{ segment_export_destination: SegmentExportDestination }> {
    return requestFactory("patch", `/segments/export/destinations/${destinationId}`, data)
  },
  delete(destinationId: SegmentExportDestination["id"]): Promise<void> {
    return requestFactory("delete", `/segments/export/destinations/${destinationId}`)
  },
  listDeleted({
    offset = 0,
    limit = 20,
    searched_text = "",
  }: SegmentExportDestinationListDeletedParams = {}): Promise<SegmentExportDestinationListDeletedResponse> {
    return requestFactory("get", "/segments/export/destinations/trash", {
      offset,
      limit,
      searched_text,
    })
  },
  restoreDeleted(
    id: SegmentExportDestination["id"],
  ): Promise<{ segment_export_destination: SegmentExportDestination }> {
    return requestFactory("patch", `/segments/export/destinations/trash/${id}`)
  },
}

export default exportDestination

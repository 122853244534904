import { path as get } from "ramda"
import { ConditionPath, ConditionSymbol, ConditionTree } from "../../types/conditionTree"
import { isAndOrCondition } from "./utils"

const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
const colors: ConditionSymbol["color"][] = [
  [66, 133, 244],
  [34, 182, 195],
  [210, 121, 121],
  [23, 130, 59],
]

export function getSymbolStyle(color: ConditionSymbol["color"]) {
  if (!color) return {} // TODO: solve?

  const backgroundColor = `rgba(${color.join(",")},0.1)`
  const borderColor = `rgba(${color.join(",")},1)`

  return { backgroundColor, borderColor, color: borderColor }
}

function mapToSymbols<T>(conditionTree: ConditionTree<T>): ConditionTree<ConditionSymbol> {
  let index = -1

  function mapToSymbolsInner(
    depth: number,
    conditionTree: ConditionTree<T>,
  ): ConditionTree<ConditionSymbol> {
    if (isAndOrCondition(conditionTree)) {
      return {
        operator: conditionTree.operator,
        operands: conditionTree.operands.map(operand => mapToSymbolsInner(depth + 1, operand)),
      }
    }

    index += 1
    return {
      text: chars[index % chars.length],
      color: colors[Math.max(0, depth - 1) % colors.length],
      negation: !!(conditionTree as any).negation,
      index,
    }
  }

  return mapToSymbolsInner(0, conditionTree)
}

let prevConditionTree: ConditionTree<any>
let symbolTree: ConditionTree<ConditionSymbol>

export function getSymbolTree<T>(conditionTree: ConditionTree<T>): ConditionTree<ConditionSymbol> {
  // Memoized by condition tree
  if (conditionTree !== prevConditionTree) {
    prevConditionTree = conditionTree
    symbolTree = mapToSymbols(conditionTree)
  }

  return symbolTree
}

export function getSymbol<T>(
  itemPath: ConditionPath,
  conditionTree: ConditionTree<T>,
): ConditionSymbol {
  return get<ConditionSymbol>(itemPath, getSymbolTree(conditionTree))!
}

import { QueryKey, useQuery } from "@tanstack/react-query"
import { api } from "api"
import { IdentityGraphResponse } from "./identityGraphTypes"
import { AxiosError } from "axios"

export const useFetchIdentityGraph = (customer_entity_id: string) =>
  useQuery<IdentityGraphResponse, AxiosError, IdentityGraphResponse, QueryKey>(
    ["customer", customer_entity_id, "identityGraph"] as QueryKey,
    () => api.identityGraph.retrieve(customer_entity_id),
    {
      refetchOnWindowFocus: false,
      retry: 0,
    },
  )

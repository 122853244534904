import { getUserFriendlyValueFormat } from "helpers/attributeValue.helper"
import { abbreviateNumber } from "helpers/number.helper"
import { DataType, InsightType, LabelType } from "./types"

const roundToTwoDecimals = (num: number) => Math.round(num * 100) / 100

export const formatLabel = (value: LabelType, dataType: DataType): LabelType => {
  if (["date", "datetime"].includes(dataType)) {
    const dateValue = getUserFriendlyValueFormat(value, dataType)
    return dateValue !== "Invalid date" ? dateValue : value
  } else if (["int", "float"].includes(dataType) && typeof value === "number")
    return roundToTwoDecimals(value)
  else return value
}

export const formatValue = ({
  dataType,
  type,
  value,
}: {
  dataType: DataType
  type: InsightType
  value: number
}) => {
  if (["date", "datetime"].includes(dataType) && ["EARLIEST", "LATEST"].includes(type)) {
    const dateValue = getUserFriendlyValueFormat(value, dataType)
    return dateValue !== "Invalid date" ? dateValue : value
  }

  return abbreviateNumber(value)
}

export const toStrPercentage = (percentage: number) =>
  percentage > 0 && percentage < 1
    ? "<1%"
    : percentage > 99 && percentage < 100
    ? ">99%"
    : `${Math.round(percentage)}%`

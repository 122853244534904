import styles from "./ChatModal.module.scss"
import { CSSTransition } from "react-transition-group"
import { useCallback, useEffect, useRef, useState } from "react"
import meiroLogoSrc from "./meiroLogo.png"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { useForm } from "react-hook-form"
import TextInput from "components/UI/elements/TextInput/TextInput"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useFetchCurrentUser } from "resources/user/currentUserQueries"
import Avatar from "components/UI/elements/Avatar"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useFetchOpenAiResponse } from "resources/openai/openaiQueries"
import SimpleBar from "simplebar-react"
import classNames from "classnames"
import { required } from "helpers/validators.helper"

type ChatModalProps = {
  isOpen: boolean
  onClose: () => void
}

type ChatModalFormValues = {
  query: string
}

export default function ChatModal({ isOpen, onClose }: ChatModalProps) {
  const scrollableNode = useRef<HTMLDivElement>(null)
  const { data: currentUser } = useFetchCurrentUser()
  const { register, handleSubmit } = useForm<ChatModalFormValues>({
    defaultValues: {
      query: "",
    },
  })

  const [latestQuery, setLatestQuery] = useState("")
  const [scrollPos, setScrollPos] = useState<"top" | "middle" | "bottom" | null>(null)

  const {
    data: chatGptData,
    isFetching: isFetchingChatGptResponse,
    error: chatGptError,
    refetch: refetchChatGptAnswer,
  } = useFetchOpenAiResponse(latestQuery, {
    enabled: Boolean(latestQuery),
    cacheTime: 0,
    retry: false,
    refetchOnWindowFocus: false,
  })

  const onSubmit = (data: ChatModalFormValues) => {
    if (!isFetchingChatGptResponse) {
      if (latestQuery === data.query) {
        refetchChatGptAnswer()
      }
      setLatestQuery(data.query)
    }
  }

  const onKeyUp = useCallback(
    (evt: KeyboardEvent) => {
      if (["Escape", "Esc"].includes(evt.code)) {
        evt.preventDefault()
        onClose()
      }
    },
    [onClose],
  )

  useEffect(() => {
    if (isOpen) {
      window.addEventListener("keyup", onKeyUp)
    } else {
      window.removeEventListener("keyup", onKeyUp)
    }
  }, [isOpen, onKeyUp])

  const calcAndSetScrollPos = useCallback((el: HTMLDivElement) => {
    if (el.scrollHeight === el.clientHeight) {
      setScrollPos(null)
      return
    }

    if (el.scrollTop === 0) {
      setScrollPos("top")
      return
    }

    if (Math.ceil(el.scrollHeight - el.scrollTop) === el.clientHeight) {
      setScrollPos("bottom")
      return
    }

    setScrollPos("middle")
  }, [])

  const onSimpleBarScroll = useCallback(
    (evt: Event) => {
      if (evt.currentTarget) calcAndSetScrollPos(evt.currentTarget as HTMLDivElement)
    },
    [calcAndSetScrollPos],
  )

  useEffect(() => {
    if (isOpen && scrollableNode.current) {
      calcAndSetScrollPos(scrollableNode.current)
      scrollableNode.current.addEventListener("scroll", onSimpleBarScroll)
    } else if (scrollableNode.current) {
      scrollableNode.current.removeEventListener("scroll", onSimpleBarScroll)
    }
  }, [isOpen, calcAndSetScrollPos, onSimpleBarScroll, chatGptData])

  if (!currentUser) return null

  return (
    <CSSTransition in={isOpen} timeout={200} classNames="fade" unmountOnExit>
      <div className={styles.chatModal}>
        <div className={styles.header}>
          <img src={meiroLogoSrc} alt="" className={styles.meiroLogo} />
          <h3>Ask Meiro AI Assistant</h3>
          <IconButton
            icon="times"
            color="grey"
            variant="transparent"
            className={styles.closeButton}
            onClick={onClose}
          />
        </div>
        <p className={styles.subHeader}>
          Meiro AI Assistant is an AI-powered tool that helps you create compelling content with
          ease.
        </p>
        <SimpleBar
          className={classNames(styles.content, {
            [styles.scrolledToTop]: scrollPos === "top",
            [styles.scrolledToMiddle]: scrollPos === "middle",
            [styles.scrolledToBottom]: scrollPos === "bottom",
          })}
          scrollableNodeProps={{ ref: scrollableNode }}
        >
          {latestQuery && (
            <div className={styles.queryBox}>
              <Avatar className={styles.avatar} email={currentUser.email} name={currentUser.name} />
              <p>{latestQuery}</p>
            </div>
          )}
          {!isFetchingChatGptResponse && (chatGptData || chatGptError) && (
            <div className={styles.responseBox}>
              <div className={styles.aiAvatar}>
                <FontAwesomeIcon icon="brain-circuit" />
              </div>
              {chatGptData && <p>{chatGptData.answer}</p>}
              {chatGptError && <p className={styles.error}>{chatGptError.error}</p>}
            </div>
          )}
        </SimpleBar>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className={styles.form}>
          <div className={styles.formFieldWrapper}>
            <TextInput
              {...register("query", { validate: required })}
              className={styles.formField}
              placeholder="Type your query"
            />
            {isFetchingChatGptResponse ? (
              <LoadingIndicator className={styles.loadingIndicator} size="sm" fixedWidth />
            ) : (
              <IconButton
                icon="repeat"
                color="grey"
                variant="transparent"
                type="submit"
                className={styles.submitButton}
              />
            )}
          </div>
        </form>
      </div>
    </CSSTransition>
  )
}

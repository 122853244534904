import { useEffect, useRef, useState } from "react"

export function useNavScrollbar() {
  const [navWrapperHeight, setNavWrapperHeight] = useState(0)
  const [navHeight, setNavHeight] = useState(0)
  const [navScroll, setNavScroll] = useState(0)
  const [isMouseOver, setIsMouseOver] = useState(false)
  const mouseEnter = () => setIsMouseOver(true)
  const mouseLeave = () => setIsMouseOver(false)

  const navWrapperRef = useRef<HTMLDivElement>(null)
  const navRef = useRef<HTMLElement>(null)

  const ERROR_MARGIN = 1
  const showScrollbar = navHeight > navWrapperHeight && isMouseOver
  const scrollbarHeight = Math.min(navWrapperHeight ** 2 / navHeight || 0, navWrapperHeight)
  const scrollbarOffset = (navWrapperHeight * navScroll) / navHeight || 0
  const showTopOverlay = navScroll > 0
  const showBottomOverlay = navHeight - navWrapperHeight - navScroll > ERROR_MARGIN
  // I had to make the scrollbar `position: fixed` to fix an edge-case bug
  const navWrapperRect = navWrapperRef.current?.getBoundingClientRect()
  const scrollbarTop = scrollbarOffset + (navWrapperRect?.top ?? 0)
  const navRightEdge = navWrapperRect?.right ?? 0

  useEffect(() => {
    const ref = navWrapperRef.current
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        setNavWrapperHeight(entry.contentRect.height)
      }
    })
    if (ref) {
      observer.observe(ref)
    }
    return () => {
      if (ref) {
        observer.unobserve(ref)
      }
    }
  }, [navWrapperRef])

  useEffect(() => {
    const ref = navRef.current
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        setNavHeight(entry.contentRect.height)
      }
    })
    if (ref) {
      observer.observe(ref)
    }
    return () => {
      if (ref) {
        observer.unobserve(ref)
      }
    }
  }, [navRef])

  useEffect(() => {
    const ref = navWrapperRef.current
    const listener = () => {
      if (ref) {
        setNavScroll(ref.scrollTop)
      }
    }
    ref?.addEventListener("scroll", listener)
    return () => ref?.removeEventListener("scroll", listener)
  }, [navWrapperRef])

  useEffect(() => {
    const ref = navWrapperRef.current
    const listener = () => {
      if (ref) {
        setNavScroll(ref.scrollTop)
      }
    }
    window.addEventListener("resize", listener)
    return () => window.removeEventListener("resize", listener)
  }, [])

  return {
    mouseEnter,
    mouseLeave,
    showScrollbar,
    showTopOverlay,
    showBottomOverlay,
    scrollbarHeight,
    scrollbarTop,
    navRightEdge,
    navRef,
    navWrapperRef,
  }
}

import { Template } from "components/UI/components/TemplateGallery/TemplateGalleryItem/TemplateGalleryItem"
import abandonedBasket from "./abandonedBasket/abandonedBasket"
import countdown from "./countdown/countdown"
import formWithPromoCode from "./formWithPromoCode/formWithPromoCode"
import productReplenishment from "./productReplenishment/productReplenishment"
import recommendedProduct from "./recommendedProduct/recommendedProduct"
import shoppingIntention from "./shoppingIntention/shoppingIntention"

const templates: Template[] = [
  abandonedBasket,
  recommendedProduct,
  shoppingIntention,
  productReplenishment,
  countdown,
  formWithPromoCode,
]

export default templates

import ConfirmModal from "components/UI/components/ConfirmModal"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import { useState } from "react"
import { useHistory, useParams } from "react-router"
import { getRoutePath } from "routes"
import { Report } from "resources/report/reportTypes"
import ReportForm from "../components/ReportForm/ReportForm"
import Page from "components/UI/Page/Page"
import {
  useDeleteReport,
  useFetchReportById,
  useModifyReport,
} from "resources/report/reportQueries"

export default function () {
  const history = useHistory()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { id } = useParams<{ id: Report["id"] }>()
  const { data: report } = useFetchReportById(id)
  const modifyMutation = useModifyReport()
  const deleteMutation = useDeleteReport()

  const initialValues = report
    ? { name: report.name, dashboard_id: report.dashboard_id, disabled: report.disabled }
    : undefined

  if (!report) {
    return (
      <Page title="Edit report">
        <LoadingIndicator />
      </Page>
    )
  }

  return (
    <>
      <ReportForm
        initialValues={initialValues}
        onSubmit={data =>
          modifyMutation.mutateAsync(
            { id, data },
            {
              onSuccess() {
                history.push(getRoutePath("administration.reports"))
              },
            },
          )
        }
        onDelete={() => {
          setDeleteModalOpen(true)
        }}
      />
      <ConfirmModal
        title="Are you sure?"
        open={deleteModalOpen}
        type="delete"
        text={`Do you really want to delete report?`}
        handleClose={() => {
          setDeleteModalOpen(false)
        }}
        handleConfirm={() =>
          deleteMutation.mutate(
            { id },
            {
              onSuccess() {
                history.push(getRoutePath("administration.reports"))
              },
            },
          )
        }
        isLoading={deleteMutation.isLoading}
      />
    </>
  )
}

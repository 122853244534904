import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import EmailTemplateCreate from "./EmailTemplateCreate/EmailTemplateCreate"
import EmailTemplateDetail from "./EmailTemplateDetail/EmailTemplateDetail"
import EmailTemplatesList from "./EmailTemplatesList/EmailTemplatesList"

export default function EmailTemplates() {
  return (
    <Switch>
      <Route
        path={getRoutePath("channels.emails.templates")}
        exact
        component={EmailTemplatesList}
      />
      <Route
        path={getRoutePath("channels.emails.templates.create")}
        component={EmailTemplateCreate}
      />
      <Route
        path={getRoutePath("channels.emails.templates.detail")}
        component={EmailTemplateDetail}
      />
    </Switch>
  )
}

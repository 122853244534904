import { groupBy, partition } from "ramda"
import { ConditionTree } from "types/conditionTree"
import {
  SegmentConditionOperation,
  SimpleConditionObject,
  DimensionConditionObject,
  DimensionTree,
  MultiValueDimensionTree,
  SegmentCondition,
  MultiValueSegmentCondition,
} from "../segmentConditionsTypes"
import {
  isDimensionConditionObject,
  isSimpleSegmentCondition,
  isMultiValueSimpleSegmentCondition,
  isMultiValueSubattributeBranch,
  isMultiValueDimensionBranch,
} from "./segmentConditionTypeChecks"

const operationsToMergeMapping: Partial<
  Record<SegmentConditionOperation, SegmentConditionOperation>
> = {
  equals: "in",
  contains: "contains_any_of",
  number_equals: "number_in",
}

function mergeMultiValueOperands<T extends SimpleConditionObject | DimensionConditionObject>(
  conditionObjects: T[],
): T[] {
  const hasOperationToMerge = ({ condition: { operation } }: T) =>
    operation !== null && Object.keys(operationsToMergeMapping).includes(operation)

  if (!conditionObjects.some(hasOperationToMerge)) {
    return conditionObjects
  }

  const [toMerge, otherConditions] = partition(hasOperationToMerge, conditionObjects)

  const mergedConditions = Object.entries(groupBy(obj => obj.condition.operation!, toMerge)).map(
    ([operation, objects]) => {
      const mergedCondition = {
        condition: {
          operation: operationsToMergeMapping[operation as SegmentConditionOperation]!,
          value: objects.map(({ condition: { value } }) => value),
        },
      }

      if (isDimensionConditionObject(objects[0])) {
        ;(mergedCondition as DimensionConditionObject).condition.sub_attribute_id =
          objects[0].condition.sub_attribute_id
      }

      return mergedCondition as T
    },
  )

  return [...mergedConditions, ...otherConditions]
}

function migrateMultiValueDimensionTree(
  tree: DimensionTree | MultiValueDimensionTree,
): DimensionTree {
  if (isMultiValueSubattributeBranch(tree)) {
    const newOperands = mergeMultiValueOperands(tree.operands)

    if (newOperands.length === 1) {
      return newOperands[0]
    }

    return {
      operation: tree.operation,
      operands: newOperands,
    }
  }

  if (isMultiValueDimensionBranch(tree)) {
    return {
      operation: tree.operation,
      operands: tree.operands.map(migrateMultiValueDimensionTree),
    }
  }

  // Simple dimension condition object
  return tree
}

export function migrateMultiValueCondition(
  condition: SegmentCondition | MultiValueSegmentCondition,
): ConditionTree<SegmentCondition> {
  if (isSimpleSegmentCondition(condition)) {
    return condition
  }

  if (isMultiValueSimpleSegmentCondition(condition)) {
    const newOperands = mergeMultiValueOperands(condition.operands)

    if (newOperands.length === 1) {
      return {
        attribute_id: condition.attribute_id,
        negation: condition.negation,
        ...newOperands[0],
      }
    }

    return {
      operator: condition.negation ? "and" : "or",
      operands: newOperands.map(conditionObject => ({
        attribute_id: condition.attribute_id,
        negation: condition.negation,
        ...conditionObject,
      })),
    }
  }

  // Is compound attribute condition
  return {
    attribute_id: condition.attribute_id,
    negation: condition.negation,
    ...migrateMultiValueDimensionTree(condition),
  }
}

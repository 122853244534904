import requestFactory from "api/request"
import { CustomerDestinations } from "./customerDestinationTypes"

const customerDestination = {
  list({
    id,
    limit,
    offset,
  }: {
    id: string
    limit: CustomerDestinations["selection_settings"]["limit"]
    offset: CustomerDestinations["selection_settings"]["offset"]
  }): Promise<CustomerDestinations> {
    return requestFactory("get", `/customers/${id}/destinations`, { limit, offset })
  },
}

export default customerDestination

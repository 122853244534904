import React, { Fragment } from "react"

import { getSymbolTree } from "components/ConditionBuilder/treeSymbols"
import { isAndOrCondition } from "components/ConditionBuilder/utils"
import { SegmentCondition } from "resources/segment/segment/segmentConditionsTypes"
import { AndOrCondition, ConditionPath, ConditionSymbol, ConditionTree } from "types/conditionTree"

import styles from "./AttributeOverview.module.scss"

type LeafRendererProps = { symbolObject: ConditionSymbol }

const LeafRenderer = ({ symbolObject: { negation, text } }: LeafRendererProps) => (
  <span>
    {negation && <span className={styles.not}>NOT</span>}
    {text}
  </span>
)

type BranchRendererProps = {
  branch: AndOrCondition<ConditionSymbol>
  path: ConditionPath
}

const BranchRenderer = ({ path, branch: { operands, operator } }: BranchRendererProps) => {
  const itemPaths = operands.map<ConditionPath>((_, i) => [...path, "operands", i])

  return (
    <>
      {operands.map((operand, index) => (
        <Fragment key={index}>
          {isAndOrCondition(operand) ? (
            <BranchRenderer branch={operand} path={itemPaths[index]} />
          ) : (
            <LeafRenderer symbolObject={operand} />
          )}
          {index < operands.length - 1 && <div className={styles.operator}>{operator}</div>}{" "}
        </Fragment>
      ))}
    </>
  )
}

type AttributeOverviewProps = {
  conditionTree: ConditionTree<SegmentCondition>
}

export default function AttributeOverview({ conditionTree }: AttributeOverviewProps) {
  if (!conditionTree) return null

  const symbolTree = getSymbolTree(conditionTree)

  return (
    <div className={styles.root}>
      <p className={styles.description}>
        {isAndOrCondition(symbolTree) ? (
          <BranchRenderer branch={symbolTree} path={[]} />
        ) : (
          <LeafRenderer symbolObject={symbolTree} />
        )}
      </p>
    </div>
  )
}

import React from "react"

import ExpandedInsightChartBar, {
  BAR_MAX_WIDTH,
} from "../ExpandedInsightChartBar/ExpandedInsightChartBar"
import { Audience, Color } from "../../types"

import styles from "./ExpandedInsightChartMultiBar.module.scss"

type ExpandedInsightChartMultiBarProps = {
  audience: Audience
  color?: Color
  showLine?: boolean
  single?: boolean
}

export default function ExpandedInsightChartMultiBar({
  audience,
  color,
  showLine = false,
  single = false,
}: ExpandedInsightChartMultiBarProps) {
  const backBarPercentage = (audience.known / audience.total) * 100
  const backBarWidth = (audience.known / audience.total) * BAR_MAX_WIDTH

  const frontBarPercentage = (audience.segmented / audience.total) * 100

  return (
    <div className={styles.multiBar}>
      <ExpandedInsightChartBar
        backBar
        withinMultiBar
        showLine={showLine}
        single={single}
        color={color}
        percentage={backBarPercentage}
      />
      <ExpandedInsightChartBar
        withinMultiBar
        backBarWidth={backBarWidth}
        single={single}
        color={color}
        percentage={frontBarPercentage}
      />
    </div>
  )
}

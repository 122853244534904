import requestFactory from "api/request"
import exportEndpoints from "./export/mobilePushNotificationExportEndpoints"
import {
  CreateMobilePushNotificationPayload,
  MobilePushNotification,
  MobilePushNotificationsListDeletedParams,
  MobilePushNotificationsListDeletedResponse,
  MobilePushNotificationsListResponse,
  ModifyMobilePushNotificationPayload,
} from "./mobilePushNotificationTypes"

const mobilePushNotification = {
  list(): Promise<MobilePushNotificationsListResponse> {
    return requestFactory("get", "/push_notifications")
  },
  retrieve(
    id: MobilePushNotification["id"],
  ): Promise<{ push_notification: MobilePushNotification }> {
    return requestFactory("get", `/push_notifications/${id}`)
  },
  create(
    data: CreateMobilePushNotificationPayload,
  ): Promise<{ push_notification: MobilePushNotification }> {
    return requestFactory("post", "/push_notifications", data)
  },
  modify(
    id: MobilePushNotification["id"],
    data: ModifyMobilePushNotificationPayload,
  ): Promise<{ push_notification: MobilePushNotification }> {
    return requestFactory("patch", `push_notifications/${id}`, data)
  },
  delete(id: MobilePushNotification["id"]): Promise<void> {
    return requestFactory("delete", `/push_notifications/${id}`)
  },
  listDeleted({
    offset = 0,
    limit = 20,
    searched_text = "",
  }: MobilePushNotificationsListDeletedParams = {}): Promise<MobilePushNotificationsListDeletedResponse> {
    return requestFactory("get", "/push_notifications/trash", { offset, limit, searched_text })
  },
  restoreDeleted(
    id: MobilePushNotification["id"],
  ): Promise<{ push_notification: MobilePushNotification }> {
    return requestFactory("patch", `/push_notifications/trash/${id}`)
  },
  export: exportEndpoints,
}

export default mobilePushNotification

import { mapObjIndexed } from "ramda"

const features = {
  EMAILS: { prodValue: "[[FEATURE_EMAILS]]", devValue: true },
  MOBILE_PUSH_MULTI_APPS: { prodValue: "[[FEATURE_MOBILE_PUSH_MULTI_APPS]]", devValue: true },
  FUNNELS: { prodValue: "[[FEATURE_FUNNELS]]", devValue: true },
}

export const featureFlags = mapObjIndexed(({ prodValue, devValue }) => {
  if (process.env.NODE_ENV === "production") {
    if (typeof prodValue === "string") {
      // Placeholder string hasn't been replaced
      return false
    }
    return prodValue as boolean
  }
  return devValue
}, features)

import React from "react"
import { Route, Switch } from "react-router-dom"
import { getRoutePath } from "routes"
import ChannelsList from "./ChannelsList/ChannelsList"
import EmailsChannel from "./EmailsChannel/EmailsChannel"
import PushNotificationsChannel from "./PushNotificationsChannel/PushNotificationsChannel"

export default function ChannelsSetup() {
  return (
    <Switch>
      <Route path={getRoutePath("administration.channels")} exact component={ChannelsList} />
      <Route
        path={getRoutePath("administration.channels.push-notifications")}
        component={PushNotificationsChannel}
      />
      <Route path={getRoutePath("administration.channels.emails")} component={EmailsChannel} />
    </Switch>
  )
}

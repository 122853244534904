import React from "react"
import styles from "./AttributeField.module.scss"
import { Controller, useFormContext } from "react-hook-form"
import { FunnelChartFormValues } from "../FunnelChartForm/FunnelChartForm"
import { DraggableSyntheticListeners } from "@dnd-kit/core"
import { required } from "helpers/validators.helper"
import AttributePicker from "components/AttributePicker/AttributePicker"
import SelectField from "components/UI/elements/SelectField"
import {
  getCompoundAttributeSubAttribute,
  getCompoundAttributeSubAttributes,
} from "resources/attribute/compoundAttributeUtils"
import { SelectOption } from "types/util"
import { AttributeDataType } from "resources/attribute/attributeTypes"
import { useFetchAttributeById, useFetchAttributesMap } from "resources/attribute/attributeQueries"
import DelayedTooltip from "components/UI/elements/DelayedTooltip/DelayedTooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import TextInput from "components/UI/elements/TextInput/TextInput"
import IconButton from "components/UI/elements/IconButton/IconButton"
import { whereEq } from "ramda"
import { isIncorrectFunctionForDataType } from "../funnelsUtils"

type AttributeFieldProps = {
  index: number
  dragListeners?: DraggableSyntheticListeners
  removeSelf?: () => void
  isEditable: boolean
}

export default function AttributeField({
  index,
  dragListeners,
  removeSelf,
  isEditable,
}: AttributeFieldProps) {
  const {
    control,
    formState: { errors },
    getValues,
    watch,
    register,
    setValue,
  } = useFormContext<FunnelChartFormValues>()
  const selectedAttributeId = watch(`data.steps.${index}.id`)
  const { data: selectedAttribute } = useFetchAttributeById(selectedAttributeId ?? null)
  const dimensionOptions: Array<SelectOption<string> & { data_type: AttributeDataType }> =
    getCompoundAttributeSubAttributes(selectedAttribute?.data_type).map(
      ({ id, name, data_type }) => ({ value: id, label: name, data_type }),
    )
  const timeDimensionOptions = dimensionOptions.filter(({ data_type }) =>
    ["date", "datetime"].includes(data_type),
  )
  const dataDimensionOptions = dimensionOptions.filter(({ data_type }) =>
    ["string", "int", "float"].includes(data_type),
  )

  const { data: attributesMap = {} } = useFetchAttributesMap()

  return (
    <div className={styles.attribute}>
      <DelayedTooltip content="Move attribute">
        {isEditable && (
          <div className={styles.dragHandle} {...dragListeners}>
            <FontAwesomeIcon icon={["fas", "grip-vertical"]} />
          </div>
        )}
      </DelayedTooltip>
      <div className={styles.main}>
        <Controller
          control={control}
          name={`data.steps.${index}.id`}
          rules={{ validate: { required } }}
          render={({ field }) => (
            <AttributePicker
              label="Attribute"
              value={field.value}
              onChange={id => {
                field.onChange(id)
                setValue(`data.steps.${index}.timestamp_dimension_id`, "")
                setValue(`data.steps.${index}.data_dimension_id`, "")
              }}
              size="lg"
              allowedTypes={["compound"]}
              error={errors.data?.steps?.[index]?.id?.message}
              readOnly={!isEditable}
            />
          )}
        />

        <div className={styles.dimensions}>
          <Controller
            control={control}
            name={`data.steps.${index}.timestamp_dimension_id`}
            rules={{ validate: { required } }}
            render={({ field }) => (
              <SelectField
                isSimpleValue
                label="Time dimension"
                input={field}
                options={timeDimensionOptions}
                noOptionsMessage="This attribute has no datetime dimensions."
                disabled={!selectedAttributeId || !isEditable}
                error={errors.data?.steps?.[index]?.timestamp_dimension_id?.message}
              />
            )}
          />
          <Controller
            control={control}
            name={`data.steps.${index}.data_dimension_id`}
            rules={{
              validate: {
                required,
                allTypesSame(value, formValues) {
                  if (index === 0) {
                    return undefined
                  }

                  const currentAttributeId = formValues.data.steps[index].id
                  const currentAttribute = attributesMap[currentAttributeId]
                  if (!currentAttribute) {
                    return undefined
                  }
                  const currentDimension = getCompoundAttributeSubAttribute(
                    value,
                    currentAttribute.data_type,
                  )
                  if (!currentDimension) {
                    return undefined
                  }

                  const firstAttributeId = formValues.data.steps[0].id
                  const firstAttribute = attributesMap[firstAttributeId]
                  if (!firstAttribute) {
                  }
                  const firstDimension = getCompoundAttributeSubAttribute(
                    formValues.data.steps[0].data_dimension_id,
                    firstAttribute.data_type,
                  )
                  if (!firstDimension) {
                    return undefined
                  }

                  return currentDimension.data_type === firstDimension.data_type
                    ? undefined
                    : "All data dimensions must be of the same type"
                },
              },
            }}
            render={({ field }) => (
              <SelectField
                isSimpleValue
                label="Data dimension"
                input={{
                  value: field.value,
                  onChange: (value: string) => {
                    field.onChange(value)
                    setValue(
                      `data.steps.${index}.data_dimension_title`,
                      `${selectedAttribute!.name}: ${
                        dimensionOptions.find(whereEq({ value }))?.label
                      }`,
                    )

                    const dataType = dataDimensionOptions.find(whereEq({ value }))?.data_type
                    const functionValue = getValues("function")
                    if (!functionValue || !dataType) return

                    if (isIncorrectFunctionForDataType(functionValue, dataType))
                      setValue("function", "", { shouldDirty: true })
                  },
                }}
                options={dataDimensionOptions}
                noOptionsMessage="This attribute has no string, int, or float dimensions."
                disabled={!selectedAttributeId || !isEditable}
                error={errors.data?.steps?.[index]?.data_dimension_id?.message}
              />
            )}
          />
          <div className={styles.connectionLine} />
        </div>
      </div>
      <TextInput
        className={styles.labelInput}
        {...register(`data.steps.${index}.data_dimension_title`, { validate: { required } })}
        label="Label"
        error={errors.data?.steps?.[index]?.data_dimension_title?.message}
      />
      {isEditable && removeSelf && (
        <IconButton
          size="xs"
          color="red"
          onClick={removeSelf}
          icon="trash-alt"
          tooltip="Delete"
          variant="outlined"
          className={styles.removeButton}
        />
      )}
    </div>
  )
}

import { createContext } from "react"

export const ConditionNumbersContext = createContext<{
  totalCustomers: number | null
  conditionNumbers: number[]
  isInvalidNumbers: boolean
}>({
  totalCustomers: 0,
  conditionNumbers: [],
  isInvalidNumbers: true,
})

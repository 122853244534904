import { useHistory } from "react-router"
import { getRoutePath } from "routes"
import ReportForm from "../components/ReportForm/ReportForm"
import { useCreateReport } from "resources/report/reportQueries"

export default function () {
  const history = useHistory()
  const createMutation = useCreateReport()

  return (
    <ReportForm
      onSubmit={data =>
        createMutation.mutateAsync(
          { data },
          {
            onSuccess() {
              history.push(getRoutePath("administration.reports"))
            },
          },
        )
      }
    />
  )
}

import ConfirmModal from "components/UI/components/ConfirmModal"
import LoadingIndicator from "components/UI/elements/LoadingIndicator/LoadingIndicator"
import React, { useState } from "react"
import { useHistory, useParams } from "react-router"
import { getRoutePath } from "routes"

import {
  useDeletePopupWB,
  useFetchPopupWBById,
  useModifyPopupWB,
} from "resources/webBanner/popupWebBanner/popupWBQueries"
import { formDataToModifyPayload } from "../utilities/formDataToModifyPayload"
import { popupWBToFormValues } from "../utilities/popupWBToFormValues"
import { PopupWebBanner } from "resources/webBanner/popupWebBanner/popupWBTypes"
import { PopupWebBannersFormData } from "../popupWBComponentTypes"
import PopupWebBannersForm from "../components/PopupWebBannersForm/PopupWebBannersForm"
import Page from "components/UI/Page/Page"
import ChatGPT from "components/ChatGPT/ChatGPT"

export default function PopupWebBannersDetail() {
  const history = useHistory()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { id } = useParams<{ id: PopupWebBanner["id"] }>()

  const { data: webBanner, isLoading, isError } = useFetchPopupWBById(id)
  const deleteMutation = useDeletePopupWB()
  const modifyMutation = useModifyPopupWB()

  const deleteBanner = () =>
    deleteMutation.mutate(
      { id },
      {
        onSuccess: () => {
          history.push(getRoutePath("channels.popup-banners"))
        },
      },
    )

  if (isError) {
    return null
  }

  return (
    <Page
      title={"Edit pop-up banner"}
      backRouteFallback={getRoutePath("channels.popup-banners")}
      headerContent={<ChatGPT />}
    >
      {isLoading && <LoadingIndicator />}
      {!isLoading && (
        <PopupWebBannersForm
          key={id}
          submitting={modifyMutation.isLoading}
          deleting={deleteMutation.isLoading}
          initialValues={popupWBToFormValues(webBanner)}
          onDelete={() => setDeleteModalOpen(true)}
          onSubmit={(formData: PopupWebBannersFormData) =>
            modifyMutation.mutateAsync({ id, data: formDataToModifyPayload(formData, webBanner!) })
          }
        />
      )}
      <ConfirmModal
        title="Are you sure?"
        open={deleteModalOpen}
        type="delete"
        text={`Do you really want to delete web banner?`}
        handleClose={() => {
          setDeleteModalOpen(false)
        }}
        handleConfirm={deleteBanner}
        isLoading={deleteMutation.isLoading}
      />
    </Page>
  )
}
